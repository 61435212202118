import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {CompanyConf} from '../../../interfaces/company-conf';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth/auth.service';
import {DefaultService} from '../../../services/default.service';
import {DomSanitizer} from '@angular/platform-browser';
import {CssAttributes} from '../../../interfaces/CssAttributes';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit{
  constructor(private defaultService: DefaultService, private sanitizer: DomSanitizer, private authService: AuthService, private router: Router) { }

  hide = true;
  hide2 = true;
  companyConf: CompanyConf;
  notSame = false;
  passwordForm = new FormGroup({
    password: new FormControl('', [Validators.required, this.notSamePassword(), this.validLetters()]),
    password_confirmation: new FormControl('', [Validators.required, this.samePassword(), this.validLetters()]),
  });


  ngOnInit(): void
  {
    this.getCompanyInfo();
  }

  getCompanyInfo() {
    this.defaultService.getCompanyConfig().subscribe(
      (data: CompanyConf) => {
        data.logo = this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + data.logo);
        this.companyConf = data;
      });
  }

  setPasswordFn() {
    this.notSame = false;
    if (this.passwordForm.value.password !== this.passwordForm.value.password_confirmation) {
      this.notSame = true;
    } else {
      this.authService.renewPassword(this.router.url.replace('/nuevaClave/', ''), this.passwordForm.value.password).subscribe(
        () => {
          this.router.navigateByUrl('/iniciarSesion', { state: { newPassword: true } });
        }
      );
    }
  }

  // ----------------------------Validation functions ------------------------------
  samePassword(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (this.passwordForm != null) {
        if (control.value === this.passwordForm.value.password) {
          return null;
        }
      }
      return {samePasswordIncorrect: {value: control.value}};
    };
  }

  notSamePassword(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (this.passwordForm != null) {
        if (control.value === this.passwordForm.value.password_confirmation) {
          return {notSamePasswordIncorrect: {value: control.value}};
        }
      }
      return null;
    };
  }

  validLetters(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (this.passwordForm != null) {
        if (!(control.value.includes('\''))) {
          return null;
        }
      }
      return {validLettersIncorrect: {value: control.value}};
    };
  }

}
