import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {DefaultService} from '../../../services/default.service';
import { DomSanitizer } from '@angular/platform-browser';
import {AuthService} from '../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {AuthStorageService} from '../../../services/auth/auth-storage.service';
import {CompanyConf} from '../../../interfaces/company-conf';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CssAttributes} from '../../../interfaces/CssAttributes';
import {LocalStorageService} from '../../../services/local-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  @ViewChild('NEW_PASSWORD') templateNewPassword: TemplateRef<any>;
  cssAttributes: CssAttributes;
  isLoginFailed = false;
  lockUser = false;
  showEmailDiv = false;
  companyConf: CompanyConf;
  state;
  hide = true;

  userForm = new FormGroup({
    user: new FormControl('', [Validators.required]), // , this.userValidator()
    password: new FormControl('', [Validators.required, this.validLetters()]),
  });
  emailForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  firstClick = true;


  constructor(private defaultService: DefaultService, private sanitizer: DomSanitizer, private authService: AuthService,
              private router: Router, private authStorageService: AuthStorageService, private modalService: NgbModal,
              public localStorageService: LocalStorageService) {
    if (this.router.getCurrentNavigation() != null) {
      this.state = this.router.getCurrentNavigation().extras.state;
    }
  }

  ngOnInit() {
    this.getCompanyInfo();
  }


  ngAfterViewInit(): void {
    if (this.state) {
      if (this.state.newPassword) {
        this.modalService.open(this.templateNewPassword, {backdrop: 'static', centered: true});
      }
    }
  }

  getCompanyInfo() {
    this.defaultService.getBasicCompanyConfig().subscribe(
      (data: CompanyConf) => {
        this.companyConf = data;
      });
  }

  onSubmit() {
    this.lockUser = false;
    this.isLoginFailed = false;
    this.authService.login(this.userForm.value.user, this.userForm.value.password).subscribe(
      data => {
        this.authStorageService.login(data);
        this.isLoginFailed = false;
        this.localStorageService.navigateToLogin();
      },
      err => {
        if (err.error.error === 'Usuario incorrecto') {
          this.isLoginFailed = true;
        }
        if (err.error.error === 'Cliente bloqueado.') {
          this.isLoginFailed = true;
          this.lockUser = true;
        }
      }
    );
  }

  openModal(modal) {
    if (!this.firstClick) {
      this.modalService.open(modal, {backdropClass: 'light-grey-backdrop', centered: true});
    } else {
      this.firstClick = false;
    }
  }

  sendEmailPassword(modal, contentBad) {
    this.authService.sendPasswordEmail(this.emailForm.controls['email'].value).subscribe(
      () => {
        this.showEmailDiv = true;
        window.scroll(0, 0);
        modal.close();
      },
      () => {
        this.modalService.open(contentBad, {backdropClass: 'light-grey-backdrop', centered: true});
      }
    );
    modal.close();
  }

  // ----------------------------Validation functions ------------------------------
  validLetters(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (this.userForm != null) {
        if (!(control.value.includes('\''))) {
          return null;
        }
      }
      return {'validLettersIncorrect': {value: control.value}};
    };
  }
}
