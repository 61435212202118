import {ChangeDetectorRef, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Article} from '../../../interfaces/article';
import {DefaultService} from '../../../services/default.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {CompanyConf} from '../../../interfaces/company-conf';

@Component({
  selector: 'app-carousel-article',
  templateUrl: './carousel-article.component.html',
  styleUrls: ['./carousel-article.component.scss']
})
export class CarouselArticleComponent {

  constructor(private defaultService: DefaultService, private route: ActivatedRoute, private _cdr: ChangeDetectorRef,
              private sanitizer: DomSanitizer, private router: Router) { }
  @Input() articles: Array<Article>;
  @Input() article: Article;
  @Input() companyConf: CompanyConf;
  @Input() paymentMethod: string;
  contentHeight: number = 200;


  @ViewChild('CONTENT') elementView: ElementRef;

  ngAfterViewInit() {
    this.contentHeight = this.elementView.nativeElement.offsetHeight;
    this._cdr.detectChanges();
  }

  getNewArticle(article: Article) {
    this.redirectTo('/compra/' + article.name.split(' ').join('_').split('/').join('')  + '/' + article.group + '/' + article.family + '/' + article.id, article);
  }

  redirectTo(uri: string, article: Article) {
    this.router.navigateByUrl('/', {state: { data: article }, skipLocationChange: true}).then(() =>
      this.router.navigate([uri]));
      //  [state]="{ data: article}"
  }

}
