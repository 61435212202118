import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup, ValidatorFn, Validators, AbstractControl, FormControl} from '@angular/forms';
import {DefaultService} from '../../../services/default.service';
import {DomSanitizer} from '@angular/platform-browser';
import {City} from '../../../interfaces/city';
import {AuthService} from '../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {CompanyConf} from '../../../interfaces/company-conf';
import {Country} from "../../../interfaces/country";
import {Province} from "../../../interfaces/province";
import {LocalStorageService} from '../../../services/local-storage.service';
import {Credentials} from '../../../interfaces/user-info';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  @Input() registerFromOrder: boolean;
  @Output() newRegister = new EventEmitter<Credentials>();
  countries: Array<Country>;
  provinces: Array<Province>;
  cities: Array<City>;

  companyConf: CompanyConf;
  msgInfoDays;
  msgInfoHour;
  errorPhone;
  errorMail;
  days = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábados', 'Domingos'];
  lettersDays = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];
  userForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    surnames: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    province: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required, this.notDefaultOption()]),
    direction: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{9}$')]),
    phoneNumber2: new FormControl(null, [Validators.pattern('^[0-9]{9}$')]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(4),
                                                        Validators.maxLength(10), this.validLetters()]),
    password_confirmation: new FormControl('', [Validators.required, this.samePassword()]),
    observations: new FormControl(null),
    birthday: new FormControl(''),
  });
  companyType;
  hide = true;
  hide2 = true;

  constructor(private defaultService: DefaultService, private sanitizer: DomSanitizer, private authService: AuthService,
              private router: Router, public localStorageService: LocalStorageService) { }

  ngOnInit() {
    this.getCompanyInfo();
    this.userForm.controls['country'].setValue('ES', {onlySelf: true});
    this.getAllCountries();
    this.companyType = this.localStorageService.getCompanyType();
  }

  getCompanyInfo() {
    this.defaultService.getBasicCompanyConfig().subscribe(
      (data: CompanyConf) => {
        this.companyConf = data;
      });
  }

  getAllCountries() {
    this.defaultService.getAllDirections().subscribe(
      (data: Array<Country>) => {
        this.countries = data;
        this.getProvinces();
      }
    );
  }

  getProvinces() {
    const country = this.countries.find(e => e.id === this.userForm.controls['country'].value);
    if (country != null){
      this.provinces = country.provinces;
    }

    this.getCities();
  }

  getCities() {
    this.userForm.controls['city'].setValue('default', {onlySelf: true});
    const province = this.provinces.find(e => e.id === this.userForm.controls['province'].value);
    if (province != null){
      this.cities = province.cities;
    }
    this.msgInfoDays = null;
  }

  setMsg() {
    const id = this.userForm.controls['city'].value;
    this.msgInfoDays = null;
    if (id !== 'default') {
      const city = this.cities.filter(x => x.id === id);

      if (city.length === 1) {
        if (city[0].deliveryDays != '') {
          const selectDays = city[0].deliveryDays.split(',');
          let daysText = '';
          for (let i = 0; i < selectDays.length; i++) {
            const pos = this.lettersDays.findIndex(element => element === selectDays[i]);
            daysText += this.days[pos] + ', ';
          }

          if (daysText != undefined) {
            this.msgInfoDays = 'Días de entrega:<b> ' + daysText.slice(0, -2) +
              '</b><br/>Horario de entrega: <b>' + city[0].deliverySchedule + '</b><br/><br/>';
          }
        }
      } else {
        this.msgInfoDays = '';
        city.forEach( e => {
          if (e.deliveryDays != '') {
            const selectDays = e.deliveryDays.split(',');
            let daysText = '';
            for (let i = 0; i < selectDays.length; i++) {
              const pos = this.lettersDays.findIndex(element => element === selectDays[i]);
              daysText += this.days[pos] + ', ';
            }
            this.msgInfoDays += 'Días de entrega:<b> ' + daysText.slice(0, -2) +
              '</b><br/>Horario de entrega: <b>' + e.deliverySchedule + '</b><br/><br/>';
            this.msgInfoHour = null;
          }
        });
      }
    }
  }

  registerClient() {
    this.errorPhone = null;
    this.errorMail = null;
    let birthday = null;
    if (this.userForm.controls['birthday'].value !== '') {
      birthday = this.convertDate(this.userForm.controls['birthday'].value);
    }
    if (this.userForm.controls['phoneNumber2'].value === '') {
      this.userForm.controls['phoneNumber2'].setValue(null, {onlySelf: true});
    }
    if (this.userForm.controls['email'].value === '') {
      this.userForm.controls['email'].setValue(null, {onlySelf: true});
    }
    this.authService.registerClient(this.userForm.controls['name'].value + ' ' + this.userForm.controls['surnames'].value,
      this.userForm.controls['direction'].value, this.userForm.controls['country'].value, this.userForm.controls['province'].value,
      this.userForm.controls['city'].value, this.userForm.controls['phoneNumber'].value, this.userForm.controls['phoneNumber2'].value,
      birthday, this.userForm.controls['email'].value, this.userForm.controls['password'].value,
      this.userForm.controls['observations'].value).subscribe(
    () => {
        if (!this.registerFromOrder) {
          this.router.navigateByUrl('/iniciarSesion');
        } else {
          this.newRegister.emit(new Credentials(this.userForm.controls['phoneNumber'].value, this.userForm.controls['password'].value));
        }
      },
      err => {
        try {
          if (err.error.error.find(e => e === 'El télefono ya ha sido registrado anteriormente.') != null) {
            // this.isLoginFailed = true;
            this.errorPhone = 'El télefono ya ha sido registrado anteriormente.';
          } else if (err.error.error.find(e => e === 'El correo electrónico ya ha sido registrado anteriormente.') != null) {
            this.errorMail = 'El correo electrónico ya ha sido registrado anteriormente.';
          }
        } catch {
          // Controlar error
        }

      }
    );
  }

  convertDate(str) {
    const date = new Date(str),
      month = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), month, day].join('-') + 'T00:00:00.000';
  }

  // ----------------------------Validation functions ------------------------------
  samePassword(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (this.userForm != null) {
        if (control.value === this.userForm.value.password) {
          return null;
        }
      }
      return {'samePasswordIncorrect': {value: control.value}};
    };
  }

  validLetters(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (this.userForm != null) {
        if (!(control.value.includes('\''))) {
          return null;
        }
      }
      return {'validLettersIncorrect': {value: control.value}};
    };
  }

  notDefaultOption(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (this.userForm != null) {
        if (control.value !== 'default') {
          return null;
        }
      }
      return {'defaultOption': {value: control.value}};
    };
  }
}
