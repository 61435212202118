import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {endpoints} from '../../../environments/endpoints';
import {AppConfig} from "../../app.config";

@Injectable({
  providedIn: 'root'
})
export class TpvService {

  environmentUrl;
  constructor(private httpClient: HttpClient, private config: AppConfig) {
    this.environmentUrl = this.config.getConfig('host');
  }

  validTpv(id) {
    return this.httpClient.get(this.environmentUrl + endpoints.ValidTpv + '/' + id);
  }
}
