import {Injectable} from '@angular/core';
import {PriceRoute} from "../../interfaces/price-route";

const userStorage = 'TOKEN';
const cp = 'CP';
const routePrice = 'ROUTE_PRICE';
const routePrice_cp = 'ROUTE_PRICE_CP';
const languageStorage = 'LAN';
@Injectable({
  providedIn: 'root'
})
export class AuthStorageService {

  login(credentials) {
    localStorage.setItem( userStorage, JSON.stringify(credentials));
  }

  setToken(token) {
    if (!(localStorage.getItem(userStorage) === '' || localStorage.getItem(userStorage) == null
      || JSON.parse(localStorage.getItem(userStorage)) == null)) {
      let credentials = JSON.parse(localStorage.getItem(userStorage));
      credentials.client = token;
      localStorage.setItem( userStorage, JSON.stringify(credentials));
    }
  }

  logOut() {
    localStorage.removeItem(userStorage);
  }

  setLanguage(language: string) {
    localStorage.setItem(languageStorage, language);
  }

  getLanguage() {
    if (localStorage.getItem(languageStorage) === '' || localStorage.getItem(languageStorage) == null) {
      return 'ES';
    } else {
      return  localStorage.getItem(languageStorage);
    }
  }

  getUser() {
    if (localStorage.getItem(userStorage) === '' || localStorage.getItem(userStorage) == null
      || JSON.parse(localStorage.getItem(userStorage)) == null) {
      return null;
    } else {
      return  JSON.parse(localStorage.getItem(userStorage)).client;
    }
  }

  getTokenType() {
    if (localStorage.getItem(userStorage) === '' || localStorage.getItem(userStorage) == null
      || JSON.parse(localStorage.getItem(userStorage)) == null) {
      return null;
    } else {
      return  JSON.parse(localStorage.getItem(userStorage)).tokenType;
    }
  }

  getDiscount() {
    if (localStorage.getItem(userStorage) === '' || localStorage.getItem(userStorage) == null) {
      return 0;
    } else {
      return  JSON.parse(localStorage.getItem(userStorage)).discount;
    }
  }

  setDiscount(discount: number) {
    if (localStorage.getItem(userStorage) === '' || localStorage.getItem(userStorage) == null) {
      return null;
    } else {
      let storage  = JSON.parse(localStorage.getItem(userStorage));
      storage.discount = discount;
      localStorage.setItem( userStorage, JSON.stringify(storage));
    }
  }

  getPendientePago() {
    if (localStorage.getItem(userStorage) === '' || localStorage.getItem(userStorage) == null) {
      return 0;
    } else {
      return  JSON.parse(localStorage.getItem(userStorage)).pendientePago;
    }
  }

  setPendientePago(pendientePago: number) {
    if (localStorage.getItem(userStorage) === '' || localStorage.getItem(userStorage) == null) {
      return null;
    } else {
      let storage  = JSON.parse(localStorage.getItem(userStorage));
      storage.pendientePago = pendientePago;
      localStorage.setItem( userStorage, JSON.stringify(storage));
    }
  }

  isUser() {
    return !(localStorage.getItem(userStorage) === '' || localStorage.getItem(userStorage) == null);
  }

  setCp(value) {
    localStorage.setItem(cp, value);
  }

  setRoutePrices(value: PriceRoute) {
    localStorage.setItem(routePrice, JSON.stringify(value));
  }

  getRoutePrices(): PriceRoute {
    if (localStorage.getItem(routePrice) === '' || localStorage.getItem(routePrice) == null
      || JSON.parse(localStorage.getItem(routePrice)) == null) {
      return null;
    } else {
      return  JSON.parse(localStorage.getItem(routePrice));
    }
  }

  getCp() {
    if (localStorage.getItem(cp) === '' || localStorage.getItem(cp) == null) {
      return null;
    } else {
      return  localStorage.getItem(cp);
    }
  }

  copyInfoRoute () {
    localStorage.setItem(routePrice_cp, localStorage.getItem(routePrice));
    localStorage.setItem(routePrice, JSON.stringify(new PriceRoute(0, 0, 0, 0)));
  }

  setInfoRoute () {
    localStorage.setItem(routePrice, localStorage.getItem(routePrice_cp));
    localStorage.removeItem(routePrice_cp);
  }

  storePickup() {
    return !(localStorage.getItem(routePrice_cp) === '' || localStorage.getItem(routePrice_cp) == null);
  }
}
