import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AuthStorageService} from '../../../services/auth/auth-storage.service';
import {ShoppingCarService} from '../../../services/shopping-car.service';
import {CarArticle} from '../../../interfaces/car-article';
import {DomSanitizer} from '@angular/platform-browser';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {UserInfo} from '../../../interfaces/user-info';
import {ArticleCookie} from '../../../interfaces/article';
import {DefaultService} from '../../../services/default.service';
import {CompanyConf} from '../../../interfaces/company-conf';
import {PriceRoute} from '../../../interfaces/price-route';
import {Country} from '../../../interfaces/country';
import {LocalStorageService} from '../../../services/local-storage.service';
import {FunctionsService} from '../../../services/functions.service';
import {cloneDeep} from 'lodash';
import {SuperGroup} from '../../../interfaces/group';

@Component({
  selector: 'app-see-articles',
  templateUrl: './see-articles.component.html',
  styleUrls: ['./see-articles.component.scss']
})
export class SeeArticlesComponent implements OnInit, OnChanges {

  state;
  constructor(private authStorageService: AuthStorageService, private shoppingCarService: ShoppingCarService,
              private sanitizer: DomSanitizer, private modalService: NgbModal, private router: Router,
              private defaultService: DefaultService, private localStorage: LocalStorageService,
              private functionsService: FunctionsService) {}

  processOrderNav: boolean;
  userInfo: UserInfo;
  minPrice: number;
  companyConf: CompanyConf;
  priceRoute: PriceRoute = {  deliveryKg: 0, deliveryPrice: 0, fixedPrice: 0, minKg: 0};
  countries: Array<Country>;
  selectKgs;
  selectUnits;
  indicateLumps: boolean;
  usuMod: string;
  defaultRoute: string;
  newUser: boolean;
  homeDelivery: boolean;
  superGroups: Array<SuperGroup>;
  selectedOrderSuperGroup: SuperGroup;
  totalTransport = 0;
  showOrder = false;
  someArticles = true;
  anyArticleNull = true;


  newPostalCode: Function;
  numberArticles: Function;
  getUser: Function;
  addLocalStorageArticles: Function;
  setHomeDelivery: Function;

  observationsForm = new FormGroup({
    observations: new FormControl(null, [Validators.maxLength(50)]),
  });
  articles: Array<CarArticle> = [];
  user: boolean;
  numberArticlesPage = 20;
  pageArticles = 0;
  totalPrice: string;
  totalIvaPrice: number;
  missingPrice: number;
  iva;
  userDiscount = 0;
  pendientePago = 0;
  showReturn = true;

  validPriceRoute = true;
  selectedSuperGroup = null;

  ngOnInit(): void {
    if (this.selectedOrderSuperGroup != null) {
      this.selectedSuperGroup = this.selectedOrderSuperGroup.id;
    }
    this.initValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.scrollTop();
    if (changes.processOrderNav?.currentValue != null) {
      this.showOrder = changes.processOrderNav?.currentValue == true;
      this.processOrderNav = !this.processOrderNav;
    }

    if (changes.priceRoute?.currentValue != null && this.companyConf.storePickup) {
      this.calculateIvaPrices();
      this.calculateIvaPrices();
    }
  }

  reloadData() {
    this.newPostalCode();
    this.totalIvaPrice = this.totalIvaPrice - this.totalTransport;
    this.totalTransport = 0;
    this.showOrder =  false;
    if (this.authStorageService.getRoutePrices() != undefined) {
      this.priceRoute = this.authStorageService.getRoutePrices();
    }
    this.initValues();
  }

  initValues() {
    if (history.state != null){
      if (history.state.processOrder) {
        this.scrollTop();
        this.showOrder = true;
      }
    }

    this.user = this.authStorageService.getUser() != null;
    if (this.user) {
      this.userDiscount = this.authStorageService.getDiscount();
      this.pendientePago = this.authStorageService.getPendientePago();
      this.getAllArticles();
    } else {
      const arts = this.localStorage.getLocalStorageArticles();
      let articlesCookies: Array<CarArticle> = null;
      if (arts != null && Array.isArray(arts)) {
        articlesCookies = arts;
      } else if (arts != null) {
        articlesCookies = [arts];
      }

      if (articlesCookies != null) {
        let articlesStr = '';
        articlesCookies.forEach( e => {articlesStr += "'" + e.id + "'" + ','});
        articlesStr = articlesStr.substr(0, articlesStr.length - 1);
        if (articlesStr != '') {
          this.shoppingCarService.getPendingArticles(articlesStr, null).subscribe(
            (data: Array<CarArticle>) => {
              let articles: Array<CarArticle> = [];
              articlesCookies.forEach(article => {
                let art = cloneDeep(data.find(el => el.id === article.id));
                if (art != null) {
                  art.cutName = article.cutName;
                  art.cutType = article.cutType;
                  art.quantity = article.quantity;
                  art.observations = article.observations;
                  art.format = article.format;
                  art.line = article.line;
                  art.lumps = article.lumps;
                  art.totalPrice = this.functionsService.roundFn(this.companyConf, article.quantity * art.price, 2);
                  art.totalPriceIva = this.functionsService.roundFn(this.companyConf, article.quantity * art.priceIva, 2);

                  if (art.img != null) {
                      art.img = 'data:image/webp;base64,' +
                        (this.sanitizer.bypassSecurityTrustResourceUrl(<string>art.img) as any).changingThisBreaksApplicationSecurity;
                  }

                  if (!art.dropDownGauge) {
                    art = this.setSelectMaxQuantity(art);
                    art = this.setLimitsLumpsQuantity(art);
                  }

                  if (art.minimum > 0 && art.maximum > 0 && this.indicateLumps) {
                    art.maxLumps = Math.trunc(art.quantity /art.minimum);

                    if (art.quantity % art.maximum !== 0) {
                      art.minLumps = Math.trunc(art.quantity / art.maximum) + 1;
                    } else {
                      art.minLumps = art.quantity / art.maximum;
                    }
                  }

                  articles.push(art);
                }
              });

              articles = articles.sort((a,b)=> a.line - b.line);
              this.articles = articles;
              if (this.articles.length === 0) {
                this.someArticles = false;
              }

              this.calculateIvaPrices();
              this.calculatePrices();
            }
          );
        } else {
          this.someArticles = false;
        }
      } else {
        this.someArticles = false;
      }
    }
  }

  setSelectMaxQuantity(article: CarArticle) {
    if (this.selectKgs != null && article.format === '1') {
      if (this.selectKgs.length > 0) {
        if (article.quantity > this.selectKgs[this.selectKgs.length - 1]) {
          article.quantity = this.selectKgs[this.selectKgs.length - 1];
          this.setQuantity(article);
        }
      }
    } else if (this.selectUnits != null && article.format !== '1') {
      if (this.selectUnits.length > 0) {
        if (article.quantity > this.selectUnits[this.selectUnits.length - 1]) {
          article.quantity = this.selectUnits[this.selectUnits.length - 1];
          this.setQuantity(article);
        }
      }
    }
    return article;
  }

  setLimitsLumpsQuantity(article: CarArticle) {
    const min = article.minimum;
    const max = article.maximum;
    if (article.format === '1') {
      if (min > 0 && max > 0 && this.indicateLumps) {
        article.maxLumps = Math.trunc(article.quantity / min);

        if (article.quantity % max !== 0) {
          article.minLumps = Math.trunc(article.quantity / max) + 1;
        } else {
          article.minLumps = article.quantity / max;
        }
      }
    }

    return article;
  }

  getAllArticles() {
    this.shoppingCarService.getPendingArticles(null, this.selectedSuperGroup).subscribe(
      (data: Array<CarArticle>) => {
        let articles: Array<CarArticle> = data;

        for (let i = 0; i < data.length; i++) {
          if (articles[i].dropDownGauge) {
            articles[i] = this.setSelectMaxQuantity(articles[i]);
          } else {
            articles[i] = this.setLimitsLumpsQuantity(articles[i]);
          }

          if (articles[i].minimum > 0 && articles[i].maximum > 0 && this.indicateLumps) {
            articles[i].maxLumps = Math.trunc(articles[i].quantity / articles[i].minimum);

            if (articles[i].quantity % articles[i].maximum !== 0) {
              articles[i].minLumps = Math.trunc(articles[i].quantity / articles[i].maximum) + 1;
            } else {
              articles[i].minLumps = articles[i].quantity / articles[i].maximum;
            }
          }
        }

        this.articles = articles;
        if (this.articles.length === 0) {
          this.someArticles = false;
        }
        this.calculateIvaPrices();
        this.calculatePrices();
      }
    );
  }

  removeArticle(articleId, line, cutType) {
    this.articles = this.articles.filter( e => !(e.id === articleId &&
        e.line === line && e.cutType === cutType));
    this.calculateIvaPrices();
    this.calculatePrices();
    if (this.user) {
      this.shoppingCarService.deleteArticle(articleId, line, cutType).subscribe();
    } else {
      this.localStorage.removeArticle(articleId, line, cutType);
    }

    // Envío el número de articulos al componente principal
    this.numberArticles(this.articles.length);

    if (this.articles.length === 0) {
      this.someArticles = false;
    }
  }

  pageChanged(event) {
    this.pageArticles = event;
    window.scroll(0, 0);
  }

  calculateIvaPrices() {
    let totalPrice = 0;
    let sumKg = 0;
    for (const article of this.articles) {
      totalPrice += article.totalPriceIva;
      if (article.format === '1') {
        sumKg += article.quantity;
      }
    }
    this.totalTransport = 0;
    this.totalIvaPrice = parseFloat((((totalPrice + Number.EPSILON) * 100) / 100).toFixed(2));
    if (this.userInfo != undefined) {
      if (this.userInfo.pendientePago < 0) {
        this.totalIvaPrice += this.userInfo.pendientePago;
      }
    }

    if (this.userInfo == null) {
      this.missingPrice = this.getDifferencePrice(this.minPrice, this.totalIvaPrice);
    }
  }

  calculatePrices() {
    let totalPrice = 0;
    for (const article of this.articles) {
      totalPrice += article.totalPrice;
    }
    this.totalPrice = (((totalPrice + Number.EPSILON) * 100) / 100).toFixed(2);
    this.iva = ((((this.totalIvaPrice - totalPrice) + Number.EPSILON) * 100) / 100).toFixed(2);
  }

  openObservation(event, articleId, line, content) {
    event.preventDefault();
    event.stopPropagation();
    const pos = this.articles.find(element => element.id === articleId && element.line == line);
    this.observationsForm.controls['observations'].setValue(pos.observations);

    this.modalService.open(content, {backdropClass: 'light-grey-backdrop', centered: true});
  }

  setArticleObservations(modal, article, line) {
    const pos = this.articles.findIndex(element => element.line === line);
    if (this.observationsForm.controls['observations'].value == null ||
        this.observationsForm.controls['observations'].value === '') {
      this.articles[pos].observations = null;
    } else {
      this.articles[pos].observations = this.observationsForm.controls['observations'].value.replace('\'', '`');
    }

    if (this.user) {
      this.shoppingCarService.setObservations(article, line, this.observationsForm.controls['observations'].value.replace('\'', '`')).subscribe();
    } else {
      this.localStorage.setObservations(article, line, this.observationsForm.controls['observations'].value.replace('\'', '`'));
    }

    modal.close();
  }
  
  getDifferencePrice(max: number, min: number): number {
    return parseFloat(((((max - min) + Number.EPSILON) * 100) / 100).toFixed(2));
  }

  stopPropagation(e) {
    e.stopPropagation();
    e.preventDefault();
    return false;
  }

  setQuantity(article: CarArticle) { // CAMBIAR
    if (this.companyConf.onlyUnits && article.format === '1') {
      if (article.lumps != null && article.lumps > 0) {
        if (article.approxWeight != null && article.approxWeight > 0) {
          article.quantity = article.lumps * article.approxWeight;
        } else {
          article.quantity = article.lumps;
        }
      } else {
        article.quantity = null;
      }
    }

    if (article.quantity != null && article.quantity > 0) {
      let quantity = article.quantity;
      if (article.showPriceQuantityInfo) {
        if (article.quantityGauge == null) {
          this.anyArticleNull = false;
          return;
        }
        quantity = article.quantityGauge;
      }

      let isValid = quantity.toString().replace(/^[0-9]+[.]?[0-9]?$/g, '');

      if (article.format.toString() !== '1' || article.showPriceQuantityInfo) {
        isValid = quantity.toString().replace(/^[0-9]+$/g, '');
        if (isValid !== '') {
          this.anyArticleNull = false;
          return;
        }
      } else if (this.companyConf.onlyUnits) {
        isValid = article.lumps.toString().replace(/^[0-9]+$/g, '');

        if (isValid !== '') {
          this.anyArticleNull = false;
          return;
        }
      }  else if (article.format.toString() === '1') {
        if (isValid !== '') {
          this.anyArticleNull = false;
          return;
        }

        const min = article.minimum;
        const max = article.maximum;
        if (min > 0 && max > 0 && !(quantity >= min)) {
          this.anyArticleNull = false;
          return;
        }

        if (min > 0 && max > 0 && this.indicateLumps) {
          article.maxLumps = Math.trunc(quantity / min);

          if (quantity % max !== 0) {
            article.minLumps = Math.trunc(quantity / max) + 1;
          } else {
            article.minLumps = quantity / max;
          }
          article.lumps = article.minLumps;
        }
      }

      if (article.showPriceQuantityInfo) {
        article.quantity = (article.quantityGauge * article.approxWeight);
      }

      article.totalPriceIva = this.functionsService.roundFn(this.companyConf,article.quantity * article.priceIva, 2);
      article.totalPrice = article.quantity * article.price;
      if (!this.user) {
        this.localStorage.removeArticle(article.id, article.line, article.cutType);
      }

      const arg: ArticleCookie = new ArticleCookie(article.id, article.line, article.name, article.format,
        null, article.price, article.nameClient, article.group,
        article.family, article.quantity, article.lumps,
        article.totalPriceIva, article.observations, article.cutType, article.cutName);

      if (!this.localStorage.addLocalStorageArticle(arg)) {
        this.shoppingCarService.setQuantity(article.id, article.line, quantity, article.lumps, article.format).subscribe();
      }

      this.calculateIvaPrices();
      this.calculatePrices();
      this.anyArticleNull = true;
    } else {
      this.anyArticleNull = false;
    }
  }

  openStock(event, content) {
    event.preventDefault();
    event.stopPropagation();
    this.modalService.open(content, {backdropClass: 'light-grey-backdrop', centered: true});
  }

  openModal(content) {
    this.modalService.open(content, {backdropClass: 'light-grey-backdrop', centered: true});
  }

  formatNameFn(id): string {
    const format = this.companyConf.companiesKgFormats.find(e => e.id === id.toString());
    let name = ' Unidades';
    if (format != null) {
      name = ' ' + format.value;
    }
    return name;
  }

  finishOrder() {
    this.showReturn = false;
    this.numberArticles(0);
    window.scroll(0, 0);
  }

  addValue(article) {
    if (article.lumps < article.maxLumps) {
      article.lumps++;

      if (!this.localStorage.setLumps(article.id, article.line, article.lumps)) {
        this.shoppingCarService.setLumps(article.id, article.line, article.lumps).subscribe();
      }
    }
  }

  subtractValue(article) {
    if (article.lumps > article.minLumps && article.lumps > 1) {
      article.lumps--;

      if (!this.localStorage.setLumps(article.id, article.line, article.lumps)) {
        this.shoppingCarService.setLumps(article.id, article.line, article.lumps).subscribe();
      }
    }
  }

  scrollTop() {
    window.scroll(0, 0);
  }

  parseFloat(number) {
    return parseFloat(number);
  }

  setCutType(article: CarArticle, cutType) {
    if (article.cutType != null) {
      if (!this.localStorage.setCutType(article.id, article.line, cutType)) {
        this.shoppingCarService.setCutType(article.id, article.line, cutType).subscribe();
      }
      article.cutName = article.dropDownCuts.find(el => el.id === cutType).name;
    }
  }

  existSameArticle(id, cut, line) {
    return this.articles.find(el => el.cutType === cut && el.id === id && el.line != line) != null;
  }

  getUserDataFn() {
    this.getUser();
    this.addLocalStorageArticles();
  }
}
