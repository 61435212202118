import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import {DefaultService} from './@core/services/default.service';
import {CompanyInit} from './@core/interfaces/company-init';
import {Router} from '@angular/router';
import {DOCUMENT, Location} from '@angular/common';
import {LocalStorageService} from './@core/services/local-storage.service';
import {AuthStorageService} from './@core/services/auth/auth-storage.service';
import {AuthService} from './@core/services/auth/auth.service';
import {CompanyConf, CompanyInfo} from './@core/interfaces/company-conf';
import {PrivacyPolicyComponent} from './@core/components/footer/privacy-policy/privacy-policy.component';
import {LegalNoticeComponent} from './@core/components/footer/legal-notice/legal-notice.component';
import {CookiesComponent} from './@core/components/footer/cookies/cookies.component';
import {CssAttributes} from './@core/interfaces/CssAttributes';
import {Meta, Title} from '@angular/platform-browser';
import {DefaultContainerComponent} from './container/default-container/default-container.component';
import {WebEstaticaService} from './@core/services/web-estatica/web-estatica.service';
import {Posicionamiento} from './@core/interfaces/posicionamiento';
import {ArticlesComponent} from './@core/components/index/articles/articles.component';
import {ArticleWindowComponent} from './@core/components/index/article-window/article-window.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit{
  constructor(private defaultService: DefaultService, private router: Router, private location: Location,
              private localStorageService: LocalStorageService, private authStorageService: AuthStorageService,
              private authService: AuthService, private titleService:Title, private webEstaticaService: WebEstaticaService,
              private metaService: Meta, @Inject(DOCUMENT) private _document: Document, private _renderer2: Renderer2) {}
  connection = true;
  name = '';
  loading = true;
  link: string;

  tokenExpires;
  interval: NodeJS.Timer | undefined;
  tokenDateTime: Date | undefined;

  datosLegalesEmpresa: CompanyInfo;
  datosBasicos: CompanyInfo;
  companyConf: CompanyConf;
  posicionamiento: Posicionamiento | undefined;
  fontUrl: string | undefined;
  mostrarFondo = false;

  ngOnInit(): void {
    this.refreshToken();
    this.interval = setInterval(() => {
      this.refreshToken();
    }, 300000); // 300000

    this.getCompanyData();

    this.defaultService.getSCSSAttributes().subscribe((data: CssAttributes) => {
      document.documentElement.style.setProperty('--primary-color', data.primary);
      document.documentElement.style.setProperty('--secondary-color', data.secondary);
      document.documentElement.style.setProperty('--danger-color', data.danger);
      document.documentElement.style.setProperty('--font-style', data.font);
      document.documentElement.style.setProperty('--text-color', data.textPrimary);
      document.documentElement.style.setProperty('--text-secondary', data.textSecondary);
      document.documentElement.style.setProperty('--background-color', data.colorFondo);
      this.fontUrl = data.fontUrl;
      this.mostrarFondo = data.mostrarFondo;
      document.documentElement.style.setProperty('--font', data.font);
    });

  }

  validateUser() {
    this.authService.validateToken().subscribe(
      (result: any) => {
        const date = result.value.split(" ")[0].split("/");
        const time = result.value.split(" ")[1].split(":");
        this.tokenDateTime = new Date(date[2], date[1]-1, date[0], time[0], time[1], time[2]);
      },
      () => {this.authStorageService.logOut();}
    );
  }

  refreshToken() {
    if (this.authStorageService.getUser() != null) {
      if (this.tokenDateTime == undefined) {
        this.validateUser();
      } else {
        const now = new Date();
        const oneHour = new Date(this.tokenDateTime.getTime() - (60*60000));
        if (now > oneHour && now < this.tokenDateTime) {
          this.authService.refreshToken().subscribe(
            (result: any) => {
              this.authStorageService.setToken(result.token);
              const date = result.datetime.split(" ")[0].split("/");
              const time = result.datetime.split(" ")[1].split(":");
              this.tokenDateTime = new Date(date[2], date[1]-1, date[0], time[0], time[1], time[2]);
            }
          )
        }
      }
    }
  }

  getCompanyData() {
    this.defaultService.validConnection().subscribe(
      (data: CompanyInit) => {
        this.name = data.name;
        this.localStorageService.setCompanyType(data.companyType);

        if (data.companyType === 'E' && this.authStorageService.getUser() == null) {
          this.link = '/iniciarSesion';
        }

        if (data.paginaEstatica) {
          this.link = '/inicio';
        } else if (data.initRoute === 'of' || data.initRoute === '') {
          this.link = '/compra/ofertas';
        } else if (data.initRoute === 'lista-de-articulos') {
          this.link = '/compra/lista-de-articulos';
        } else if (data.initRoute === 'repetir-pedido') {
          this.link = '/repetir-pedido';
        } else if (data.initRoute.substr(0, 1) === 'g') {
          this.link = '/compra/none/' + data.initRoute.substr(1, data.initRoute.length - 1);
        } else {
          this.link = '/compra/' + data.initRoute.substr(1, data.initRoute.length - 1) + '/*';
        }

        if (this.location.path() === '/' || this.location.path() === '/compra' || this.location.path() === '') {
          this.router.navigateByUrl(this.link).then();
        } else {
        }

        this.localStorageService.setDefaultLink(this.link);


        this.webEstaticaService.getPosicionamiento().subscribe(posicionamiento => {
          this.posicionamiento = posicionamiento;
          this.defaultService.getBasicCompanyConfig().subscribe((datosBasicos: CompanyInfo) => {
            this.datosBasicos = datosBasicos;
            this.titleService.setTitle(datosBasicos.webName);
            this.loading = false;
          });
        });
      },
      () => {
        this.loading = false;
        this.connection = false;
      });


  }

  onChildLoaded(component: PrivacyPolicyComponent | LegalNoticeComponent | CookiesComponent | DefaultContainerComponent) {
    this.limpiarPosicionamiento();
    if (!(component instanceof DefaultContainerComponent)) {
      this.agregarPosicionamiento();
    }

    if (component instanceof PrivacyPolicyComponent) {
      this.titleService.setTitle(this.datosBasicos.webName + ' | Política de Privacidad');
      if (this.datosLegalesEmpresa == null) {
        this.defaultService.getCompanyInfo().subscribe((data: CompanyInfo) => {
          this.datosLegalesEmpresa = data;
          component.datosLegalesEmpresa = this.datosLegalesEmpresa;
        });
      } else {
        component.datosLegalesEmpresa = this.datosLegalesEmpresa;
      }

      if (this.companyConf == null) {
        this.defaultService.getCompanyConfig().subscribe((data: CompanyConf) => {
          this.companyConf = data;
          component.companyConf = this.companyConf;
        });
      } else {
        component.companyConf = this.companyConf;
      }
    } else if (component instanceof LegalNoticeComponent){
      this.titleService.setTitle(this.datosBasicos.webName + ' | Aviso Legal');
      if (this.datosLegalesEmpresa == null) {
        this.defaultService.getCompanyInfo().subscribe((data: CompanyInfo) => {
          this.datosLegalesEmpresa = data;
          component.datosLegalesEmpresa = this.datosLegalesEmpresa;
          component.province = this.datosLegalesEmpresa.direction.split(',')[1];
        });
      } else {
        component.datosLegalesEmpresa = this.datosLegalesEmpresa;
        component.province = this.datosLegalesEmpresa.direction.split(',')[1];
      }
    } else if (component instanceof CookiesComponent){
      this.titleService.setTitle(this.datosBasicos.webName + ' | Cookies');
      if (this.datosLegalesEmpresa == null) {
        this.defaultService.getCompanyInfo().subscribe((data: CompanyInfo) => {
          this.datosLegalesEmpresa = data;
          component.datosLegalesEmpresa = this.datosLegalesEmpresa;
        });
      } else {
        component.datosLegalesEmpresa = this.datosLegalesEmpresa;
      }
    } else if (component instanceof DefaultContainerComponent){
      component.datosBasicos = this.datosBasicos;
      component.posicionamiento = this.posicionamiento;

      if (this.datosLegalesEmpresa == null) {
        this.defaultService.getCompanyInfo().subscribe((data: CompanyInfo) => {
          this.datosLegalesEmpresa = data;
          component.datosLegalesEmpresa = this.datosLegalesEmpresa;
        });
      } else {
        component.datosLegalesEmpresa = this.datosLegalesEmpresa;
      }

      if (this.companyConf == null) {
        this.defaultService.getCompanyConfig().subscribe((data: CompanyConf) => {
          this.companyConf = data;
          component.companyConf = this.companyConf;

          component.checkUser();
          component.inicializarInfo();
        });
      } else {
        component.companyConf = this.companyConf;

        component.checkUser();
        component.inicializarInfo();
      }
    }
  }

  limpiarPosicionamiento() {
    this.metaService.removeTag("name='og:title'");
    this.metaService.removeTag("name='description'");
    this.metaService.removeTag("name='og:site_name'");
    this.metaService.removeTag("name='og:url'");
    this.metaService.removeTag("name='name'");
    this.metaService.removeTag("name='author'");
    this.metaService.removeTag("name='keywords'");
    this.metaService.removeTag("name='robots'");
    this.metaService.removeTag("name='og:locale'");
    this.metaService.removeTag("name='og:type'");
    this.metaService.removeTag("name='og:image'");
    this.metaService.removeTag("name='product:price:currency'");
    this.metaService.removeTag("name='product:price:amount'");
    this.metaService.removeTag("name='product:availability'");
  }

  agregarPosicionamiento() {
    this.titleService.setTitle(this.companyConf.webName);
    if (this.posicionamiento != undefined) {
      this.metaService.addTag({ name: 'description', content: this.posicionamiento.Description });
      this.metaService.addTag({ name: 'name', content: this.posicionamiento.Name });
      this.metaService.addTag({ name: 'og:title', content: this.posicionamiento.Title });
      this.metaService.addTag({ name: 'og:locale', content: this.posicionamiento.Locale });
      this.metaService.addTag({ name: 'og:url', content: this.posicionamiento.Url });
      this.metaService.addTag({ name: 'og:site_name', content: this.posicionamiento.Name });
      this.metaService.addTag({ name: 'og:type', content: this.posicionamiento.Type });
      this.metaService.addTag({ name: 'og:image', content: this.posicionamiento.Image });
      this.metaService.addTag({ name: 'author', content: this.posicionamiento.Author });
      this.metaService.addTag({ name: 'keywords', content: this.posicionamiento.KeyWords });
      this.metaService.addTag({ name: 'robots', content: this.posicionamiento.Robots });

      let script = this._renderer2.createElement('script');
      script.type = `application/ld+json`;
      script.text = '{' +
        '"@context": "' + this.posicionamiento.Url + '",' +
        '"url": "' + this.posicionamiento.Url + '",' +
        '"@type": "' + this.posicionamiento.Type + '",' +
        '"name": "' + this.posicionamiento.Name + '",' +
        '"headline": "' + this.posicionamiento.Title + '",' +
        '"description": "' + this.posicionamiento.Description + '",' +
        '"image": "' + this.posicionamiento.Image + '",' +
        '"sameAs": [' +
        + this.posicionamiento.SameAs +
        '],' +
        '"contactPoint": [' +
        '"@type": "ContactPoint",' +
        '"contactType" : "Atención al cliente",' +
        '"email": "' + this.posicionamiento.Email + '",' +
        '"phone": "' + this.posicionamiento.Phone + '",' +
        '"url": "' + this.posicionamiento.Url + '#/atencion-al-cliente",' +
        '],' +
        '"publisher": "https://soft-control.net",' +
        '"author": {' +
        '"@type": "Organización",' +
        '"name": "Soft Control Informática"' +
        '}' +
        '}';

      this._renderer2.appendChild(this._document.body, script);
    }

    try {
      const canonical: any = document.querySelectorAll('link[rel=”canonical”]');
      canonical[0].parentElement.removeChild(canonical[0]);
    } catch {}

    let link: HTMLLinkElement = this._document.createElement('link');
    link.setAttribute('rel', 'canonical');
    this._document.head.appendChild(link);
    link.setAttribute('href', this._document.URL);
  }
}
