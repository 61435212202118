import { Injectable } from '@angular/core';
import {endpoints} from '../../../environments/endpoints';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from "../../app.config";

@Injectable({
  providedIn: 'root'
})
export class ExceptionsService {
  environmentUrl;
  constructor(private httpClient: HttpClient, private config: AppConfig) {
    this.environmentUrl = this.config.getConfig('host');
  }

  PermitRegisterCompanies() {
    return this.httpClient.get(this.environmentUrl + endpoints.PermitRegisterCompanies, {});
  }


  UseRoutes() {
    return this.httpClient.get(this.environmentUrl + endpoints.useRoutes, {});
  }

  TypeCompany() {
    return this.httpClient.get(this.environmentUrl + endpoints.typeCompany, {});
  }
}
