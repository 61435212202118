<div *ngIf="tpvParameters == undefined">
  <div *ngIf="newUser">{{loadUserInfo()}}</div>

  <!-- ______ NO REGISTER USER ____________ -->
  <div class="container" *ngIf="!userInfo && !showSuccessOrder">
    <div *ngIf="!continueAuth; else elseOrder">
      <div class="row mb-4" *ngIf="!showNewRegister; else showRegister">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <div class="card p-2">
            <div class="card-body">
              <h1 class="card-title mb-4 mt-1 max mb-0 text-dark">
                <b>Inicie sesión con sus credenciales</b>
              </h1>
              <form (ngSubmit)="onSubmitLogin()" [formGroup]="userForm" novalidate>
                <div *ngIf="isLoginFailed && !lockUser" class="error">Usuario o contraseña incorrectos</div>
                <div *ngIf="isLoginFailed && lockUser" class="error">En estos momentos usted no tiene acceso
                  a nuestra plataforma.</div>
                <div class="row">
                  <div class="col-sm-12">
                    <mat-form-field appearance="legacy" class="w-100">
                      <mat-label>
                        <div class="d-flex">
                          Usuario
                          <div class="ml-2 help text-info">
                            <i class="help fas fa-question-circle"></i>
                          </div>
                        </div>
                      </mat-label>
                      <input type="text" class="w-100" autocomplete="user"
                             formControlName="user" matInput>
                      <div *ngIf="userForm.controls.user.touched && userForm.controls.user.errors?.required"
                           class="error">Campo obligatorio
                      </div>
                      <div *ngIf="userForm.controls.user.touched && !userForm.controls.user.errors?.required
                        && (userForm.controls.user.errors?.userIncorrect)"
                           class="error">Formato incorrecto.
                      </div>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <mat-form-field appearance="legacy" class="w-100">
                      <mat-label>Contraseña</mat-label>
                      <input [type]="hide ? 'password' : 'text'" class="w-100" autocomplete="password"
                             formControlName="password" matInput>
                      <mat-icon matSuffix (click)="hide = !hide">
                        <span *ngIf="!hide; else elseHide"><i class="fas fa-eye-slash fa-xs"></i></span>
                        <ng-template #elseHide><i class="fas fa-eye fa-xs"></i></ng-template>
                      </mat-icon>
                      <div *ngIf="userForm.controls.password.touched && userForm.controls.password.errors?.required"
                           class="error">Campo obligatorio
                      </div>
                      <div *ngIf="userForm.controls.password.touched &&
                                      !userForm.controls.password.errors?.required
                                      && userForm.controls.password.errors?.validLettersIncorrect"
                           class="error">La contraseña no puede contener el carácter '
                      </div>
                    </mat-form-field>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary text-light btn-block"
                        [disabled]="!userForm.valid">Iniciar Sesión</button>
              </form>
            </div>
          </div>

          <div style="margin: 1.25em 10px 10px 10px;">
            <h5><span>Crear una nueva cuenta</span></h5>
            <a class="btn btn-outline-info btn-block mb-4"
               (click)="showNewRegister = true">
              REGISTRARME
            </a>
          </div>
          <div style="margin: 1.25em 10px 10px 10px;">
            <h5><span>Tramitar el pedido sin registrarse</span></h5>
            <a class="btn btn-outline-info btn-block mb-4" (click)="continueAuth = true">
              CONTINUAR SIN REGISTRARME
            </a>
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>

      <ng-template #showRegister>
        <div class="mb-4">
          <app-register [registerFromOrder]="true" (newRegister)="onSubmitRegister($event)"></app-register>
          <div class="row">
            <div class="col-md-7 mx-auto">
              <div style="margin: 1.25em 10px 10px 10px;">
                <h5><span>Iniciar sesión con mi usuario</span></h5>
                <a class="btn btn-outline-info btn-block mb-4"
                   (click)="showNewRegister = false;">
                  INICIAR SESIÓN
                </a>
              </div>
              <div style="margin: 1.25em 10px 10px 10px;">
                <h5><span>Tramitar el pedido sin registrarse</span></h5>
                <a class="btn btn-outline-info btn-block mb-4" (click)="continueAuth = true">
                  CONTINUAR SIN REGISTRARME
                </a>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <ng-template #elseOrder>
      <div class="row">
        <div class="col-12 mobil-summary">
          <div class="card">
            <div class="card-header bg-white text-black-50">
              <h2 class="order-title">Resumen del pedido</h2>
            </div>

            <div class="card-body">
              <span><b>Número de artículos</b> {{articles.length}}</span><br/>
              <span *ngIf="totalTransport > 0 && totalTransport != null">
              <b>Precio de transporte:</b>
                {{(totalTransport | currency: 'EUR')}}<br/>
              </span>
              <span>
              <b>Precio Total: <span [style]="'color: ' + companyConf.priceColor + ';'">
                {{(totalPrice | currency: 'EUR')}}
              </span></b></span><br/>
                <span *ngIf="totalTransport == 0">
                <div *ngIf="homeDelivery; else pickUpStore">
                  <b>Entrega: </b>Gratuita<br/>
                </div>
                <ng-template #pickUpStore>
                  RECOGIDA EN TIENDA
                </ng-template>
              </span><br/>
            </div>
          </div>


          <div class="card" *ngIf="articuloRegalo != undefined">
            <div class="card-header bg-white text-black-50" *ngIf="!contactValid || !directionValid">
              <h2 class="order-title">Preparar para regalo</h2>
            </div>

            <div class="card-body">
              <div class="d-flex w-100">
                <img *ngIf="articuloRegalo.articleImgUrl; else productImg" src="./assets/fotos/articulos/{{articuloRegalo.articleImgUrl.toLowerCase()}}" alt="Foto del artículo"
                     width="100px" height="auto"/>
                <ng-template #productImg>
                  <img *ngIf="articuloRegalo.productImgUrl" src="./assets/fotos/productos/{{articuloRegalo.productImgUrl.toLowerCase()}}" alt="Foto del artículo"
                       width="100px" height="auto"/>
                </ng-template>

                <div class="ml-2">
                  <div class="mb-2" style="font-size: 13px">
                    {{articuloRegalo.name}}
                  </div>
                  <div class="font-weight-bolder">
                    {{articuloRegalo.priceIva.toFixed(2).replace('.', ',')}} €
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-center w-100 mt-3">
                <button class="btn btn-primary text-light" *ngIf="!prepararRegalo; else existeRegalo"
                        (click)="prepararRegalo = true; addArticuloRegalo()">
                  Añadir al pedido
                </button>

                <ng-template #existeRegalo>
                  <div class="d-flex">
                    <i class="fa-solid fa-check fa-2x text-success"></i>
                    <div class="h-100 d-flex align-items-center ml-2">
                      <b>Preparar para regalo</b>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-7">
          <!-- DATOS PERSONALES -->
          <div class="card">
            <div class="card-header bg-white text-black-50 d-flex" *ngIf="contactValid">
              <div style="width: 90%">
                <h2 class="order-title">1. Datos de contacto</h2>
              </div>
              <div style="width: 10%" *ngIf="!userInfo">
                <button type="button" class="btn btn-outline-secondary"
                        (click)="contactValid = false"><i class="far fa-edit"></i></button>
              </div>
            </div>
            <div class="card-header bg-white" *ngIf="!contactValid">
              <h2 class="order-title">1. Datos de contacto</h2>
            </div>

            <div class="card-body" *ngIf="!contactValid">
              <p class="text-small">Bienvenido, necesitamos estos datos para que puedas efectuar el pedido.</p>
              <form (ngSubmit)="contactValid = true" [formGroup]="contactForm" novalidate>
                <div class="row">
                  <div class="col-sm-6">
                    <mat-form-field appearance="legacy" style="width: 90%; !important">
                      <mat-label>Nombre</mat-label>
                      <input type="text" class="w-100"
                             formControlName="name" matInput >
                      <div *ngIf="contactForm.controls.name.touched && contactForm.controls.name.errors?.required"
                           class="error w-100">Campo obligatorio.
                      </div>
                    </mat-form-field>
                  </div>

                  <div class="col-sm-6">
                    <mat-form-field appearance="legacy" style="width: 90%; !important">
                      <mat-label>Apellidos</mat-label>
                      <input type="text" class="w-100"
                             formControlName="surnames" matInput>
                      <div *ngIf="contactForm.controls.surnames.touched && contactForm.controls.surnames.errors?.required"
                           class="error">Campo obligatorio.
                      </div>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <mat-form-field appearance="legacy" style="width: 90%; !important">
                      <mat-label>Teléfono</mat-label>
                      <input type="text" class="w-100"
                             formControlName="phoneNumber" matInput>
                      <div *ngIf="contactForm.controls.phoneNumber.touched && contactForm.controls.phoneNumber.errors?.required"
                           class="error">Campo obligatorio.
                      </div>
                      <div *ngIf="contactForm.controls.phoneNumber.touched && contactForm.controls.phoneNumber.errors?.pattern"
                           class="error">Formato incorrecto.
                      </div>
                    </mat-form-field>
                  </div>

                  <div class="col-sm-6">
                    <mat-form-field appearance="legacy" style="width: 90%; !important;" class="mb-0 pb-0">
                      <mat-label>Correo electrónico</mat-label>
                      <input type="text" class="w-100 mb-0" formControlName="email" matInput>
                      <div *ngIf="contactForm.controls.email.touched && contactForm.controls.email.errors?.required"
                           class="error">Campo obligatorio.
                      </div>
                      <div *ngIf="contactForm.controls.email.touched && contactForm.controls.email.errors?.email"
                           class="error">Formato incorrecto.
                      </div>
                    </mat-form-field>
                  </div>
                </div>

                <div class="max d-flex justify-content-center">
                  <button type="submit" class="btn btn-primary text-light btn-block" *ngIf="contactForm.valid"
                          style="width: 200px">Continuar</button>
                  <button type="submit" class="btn btn-danger" *ngIf="!contactForm.valid"
                          [disabled]="!contactForm.valid" style="width: 200px">Faltan Datos</button>
                </div>
              </form>
            </div>

            <div class="card-body" *ngIf="contactValid">
              <span><b>Nombre:</b> {{contactForm.controls['name'].value + ' ' + contactForm.controls['surnames'].value}}</span><br/>
              <span><b>Teléfono Principal:</b> {{contactForm.controls['phoneNumber'].value}}</span><br/>
              <span><b>Correo electrónico:</b> {{contactForm.controls['email'].value}}</span>
            </div>
          </div>

          <!-- DIRECCIÓN -->
          <div class="card">
            <div class="card-header bg-white text-black-50 d-flex " *ngIf="!contactValid || directionValid">
              <div style="width: 90%">
                <h2 class="order-title">2. Datos de entrega</h2>
              </div>
              <div style="width: 10%" *ngIf="contactValid">
                <button type="button" class="btn btn-outline-secondary"
                        (click)="directionValid = false;
                               selectedStorePickupDate = null; selectPickUpDate = false;
                               selectPickUpDate = false;
                               selectDirection = true;"><i class="far fa-edit"></i>
                </button>
              </div>
            </div>
            <div class="card-header bg-white" *ngIf="contactValid && !directionValid">
              <h2 class="order-title">2. Datos de entrega</h2>
            </div>

            <div class="card-body w-100" *ngIf="contactValid && !directionValid">
              <div *ngIf="companyConf.puntosRecogida; else direcciones"  class="card-body">
                <div class="w-100" *ngIf="selectPickUpDate">
                  <div *ngIf="companyConf.rutasRecogida != undefined">
                    <div class="row" *ngIf="companyConf.rutasRecogida.length > 0">
                      <div class="col-12">
                        <mat-form-field class="w-100">
                          <mat-label>Punto de recogida</mat-label>
                          <mat-select class="w-100" [(ngModel)]="ruta" (ngModelChange)="cambiarHorarioRecogida()">
                            <mat-option *ngFor="let ruta of companyConf.rutasRecogida" [value]="ruta.id">{{ruta.nombre}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>


                  <div class="row" *ngIf="companyConf.storePickUpSchedule">
                    <div class="col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Hora de recogida</mat-label>
                        <mat-select class="w-100" [(ngModel)]="scheduleHour">
                          <mat-option *ngFor="let hour of companyConf.storePickUpSchedule" [value]="hour">{{hour}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div *ngIf="ruta != undefined">
                    <form  [formGroup]="deliveryDate" novalidate>
                      <div class="row">
                        <div class="col-sm-12">
                          <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Fecha de recogida</mat-label>
                            <input class="w-100" [matDatepicker]="myPicker"
                                   [matDatepickerFilter]="calendarioRecogidaRuta"
                                   (click)="myPicker.open()"
                                   (dateChange)="selectPickupCalendarDate($event)"
                                   formControlName="deliveryDate" matInput readonly required>
                            <mat-datepicker-toggle  matSuffix [for]="myPicker"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #myPicker></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>

              <ng-template #direcciones>
                <div *ngIf="companyConf.storePickup" class="row mb-3">
                  <div class="col-12 mb-2" *ngIf="companyConf.storePickUpDates.length > 0">
                    <button type="button" class="btn btn-outline-primary w-100"
                            (click)="storePickup = true; selectPickUpDateCalendar = true; selectPickUpDate = true; this.deliveryDate.controls['deliveryDate'].setValue(null); selectDirection = false; seleccionarRuta(); setHomeDeliveryFn(false);">
                      <i class="far fa-calendar-alt mr-1"></i>{{companyConf.storePickUpDatesText}}
                    </button>
                  </div>

                  <div class="col-12 mb-2">
                    <button type="button" class="btn btn-outline-primary w-100"
                            (click)="storePickup = true; selectPickUpDate = true; ruta = null; selectPickUpDateCalendar = false;this.deliveryDate.controls['deliveryDate'].setValue(null); selectDirection = false; setHomeDeliveryFn(false);">
                      <i class="fas fa-store-alt mr-1"></i>Recoger en tienda
                    </button>
                  </div>

                  <div class="col-12 mb-2" *ngIf="!companyConf.soloRecogidaTienda">
                    <button type="button" class="btn btn-outline-primary w-100"
                            (click)="storePickup = false; selectDirection = true; ruta = null; selectPickUpDate = false;this.deliveryDate.controls['deliveryDate'].setValue(null);  selectPickUpDateCalendar = false; setHomeDeliveryFn(true);">
                      <i class="fas fa-truck mr-1"></i>Envío a domicilio
                    </button>
                  </div>
                </div>

                <div class="w-100" *ngIf="selectPickUpDate">
                  <div *ngIf="!selectPickUpDateCalendar; else calendar">
                    <form  [formGroup]="deliveryDate" novalidate>
                      <div class="row">
                        <div class="col-sm-12">
                          <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Fecha de recogida</mat-label>
                            <input class="w-100" [matDatepicker]="myPicker"
                                   [matDatepickerFilter]="calendarioRecogidaEnTienda"
                                   (click)="myPicker.open()"
                                   (dateChange)="selectPickupCalendarDate($event)"
                                   formControlName="deliveryDate" matInput readonly required>
                            <mat-datepicker-toggle  matSuffix [for]="myPicker"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #myPicker></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                    </form>
                  </div>

                  <ng-template #calendar>
                    <form  [formGroup]="deliveryDate" novalidate>
                      <div class="row">
                        <div class="col-sm-12">
                          <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Fecha de recogida</mat-label>
                            <input class="w-100" [matDatepicker]="myPicker"
                                   [matDatepickerFilter]="daysStorePickupCalendar"
                                   (click)="myPicker.open()"
                                   (dateChange)="selectPickupCalendarDate($event)"
                                   formControlName="deliveryDate" matInput readonly required>
                            <mat-datepicker-toggle  matSuffix [for]="myPicker"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #myPicker></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                    </form>
                  </ng-template>

                  <div class="row" *ngIf="companyConf.storePickUpSchedule">
                    <div class="col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Hora de recogida</mat-label>
                        <mat-select class="w-100" [(ngModel)]="scheduleHour">
                          <mat-option *ngFor="let hour of companyConf.storePickUpSchedule" [value]="hour">{{hour}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div *ngIf="companyConf.rutasRecogida != undefined && selectPickUpDateCalendar">
                    <div class="row" *ngIf="companyConf.rutasRecogida.length > 0">
                      <div class="col-12">
                        <mat-form-field class="w-100">
                          <mat-label>Lugar de recogida</mat-label>
                          <mat-select class="w-100" [(ngModel)]="ruta">
                            <mat-option *ngFor="let ruta of companyConf.rutasRecogida" [value]="ruta.id">{{ruta.nombre}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

                <form (ngSubmit)="addDirection()" [formGroup]="directionForm" novalidate *ngIf="selectDirection">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <ng-select formControlName="country"
                                   placeholder="País*"
                                   (change)="getProvinces()">
                          <ng-option *ngFor="let country of countries" [value]="country.id">{{country.name}}</ng-option>
                        </ng-select>
                        <div *ngIf="directionForm.controls.country.touched && directionForm.controls.country.errors?.required"
                             class="error">Campo obligatorio.
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group">
                        <ng-select bindLabel="name"
                                   formControlName="province"
                                   placeholder="Provincia*"

                                   (change)="getCities()">
                          <ng-option *ngFor="let province of provinces" [value]="province.id">{{province.name}}</ng-option>
                        </ng-select>
                        <div *ngIf="directionForm.controls.province.touched && directionForm.controls.province.errors?.required"
                             class="error">Campo obligatorio.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row select-container">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <ng-select bindLabel="name"
                                   formControlName="city"
                                   placeholder="Población*"
                                   (change)="setMsg()">
                          <ng-option value="default">Seleccione una ciudad</ng-option>
                          <ng-option *ngFor="let city of cities" [value]="city.id">{{city.name}},
                            {{directionForm.controls.province.value}}{{city.id}}
                          </ng-option>
                        </ng-select>
                        <div *ngIf="directionForm.controls.city.touched &&
                            (directionForm.controls.city.errors?.required || directionForm.controls.city.errors?.defaultOption)"
                             class="error">Campo obligatorio.
                        </div>
                        <div *ngIf="msgInfoDays" class="text-info">
                          <div [innerHTML]="msgInfoDays"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12">
                      <mat-form-field appearance="legacy" class="w-100">
                        <mat-label>Dirección</mat-label>
                        <input type="text" class="w-100" placeholder="Ej: Calle Alcalá, 48, 1ºA"
                               formControlName="direction" matInput >
                        <div *ngIf="directionForm.controls.direction.touched && directionForm.controls.direction.errors?.required"
                             class="error">Campo obligatorio.
                        </div>
                      </mat-form-field>
                    </div>
                  </div>

                  <form  [formGroup]="deliveryDate" novalidate>
                    <div class="row">
                      <div class="col-12">
                        <mat-form-field class="w-100" appearance="fill">
                          <mat-label>Fecha de entrega</mat-label>
                          <input class="w-100" [matDatepicker]="myPicker"
                                 [matDatepickerFilter]="calendarioEntrega"
                                 (click)="myPicker.open()"
                                 (dateChange)="seleccionarFechaEntrega($event)"
                                 formControlName="deliveryDate" matInput readonly required>
                          <mat-datepicker-toggle  matSuffix [for]="myPicker"></mat-datepicker-toggle>
                          <mat-datepicker touchUi #myPicker></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                  </form>

                  <div class="max d-flex justify-content-center">
                    <button type="submit" class="btn btn-primary text-light btn-block" *ngIf="directionForm.valid && selectedStorePickupDate != undefined"
                            style="width: 200px">Continuar</button>
                    <button type="submit" class="btn btn-danger" *ngIf="!directionForm.valid || selectedStorePickupDate == undefined"
                            [disabled]="true" style="width: 200px">Faltan Datos</button>
                  </div>
                </form>
              </ng-template>
            </div>

            <div class="card-body" *ngIf="directionValid && companyConf.puntosRecogida">
              <div><b>Recogida el</b> {{selectedStorePickupDate.value + ' sobre las ' + scheduleHour}}</div>
              <div *ngIf="this.ruta != null"><b>Punto de recogida:</b> {{nombreRuta()}}</div>
            </div>
            <div class="card-body" *ngIf="storePickup && directionValid  && !companyConf.puntosRecogida">
              <div><b>Recogida en tienda:</b> {{selectedStorePickupDate.value + ' ' + scheduleHour}}</div>
              <div *ngIf="this.ruta != null"><b>Lugar de recogida:</b> {{nombreRuta()}}</div>
            </div>
            <div class="card-body" *ngIf="!storePickup && directionValid  && !companyConf.puntosRecogida">
              <span>
                <b>Código postal:</b> {{selectedDirection.province + selectedDirection.city}}
              </span><br/>
              <span><b>Dirección:</b> {{selectedDirection.direction}}</span><br/>
              <div><b>Fecha de envío:</b> {{selectedStorePickupDate.value}}</div>
            </div>
          </div>

          <!-- DATOS DEL PEDIDO -->
          <div class="card" >
            <div class="card-header bg-white text-black-50" *ngIf="!contactValid || !directionValid; else datosPedido">
              <h2 class="order-title">3. Datos del pedido</h2>
            </div>
            <ng-template #datosPedido>
              <div class="card-header bg-white">
                <h2 class="order-title">3. Datos del pedido</h2>
              </div>

              <div class="card-body">
                <form  [formGroup]="observations">
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field appearance="legacy" class="w-100">
                        <mat-label>Observaciones</mat-label>
                        <textarea type="text" class="w-100" placeholder="Ej: No estoy en casa por la mañana"
                                  cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                  formControlName="observations" matInput></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </form>
              </div>
            </ng-template>

          </div>

          <!-- PROCESO DE PAGO -->
          <div class="card">
            <div class="card-header bg-white text-black-50" *ngIf="!contactValid || !directionValid">
              <h2 class="order-title">4. Proceso de Pago</h2>
            </div>

            <div class="card-header bg-white" *ngIf="contactValid  && directionValid">
              <h2 class="order-title">4. Proceso de Pago</h2>
            </div>

            <div class="card-body" *ngIf="contactValid && directionValid" >

              <div *ngIf="!companyConf.pasarelaActiva; else pasarela" class="w-100">
                <div class="d-flex justify-content-center mb-2 w-100">
                  <button class="btn btn-primary text-light btn-delivery-data w-100" [disabled]="!deliveryDateValid"
                          (click)="makeOrder(AddingOrder)"><i class="fas fa-store-alt"></i> Pagar en tienda</button>
                </div>
              </div>

              <ng-template #pasarela>
                <div>
                  <mat-checkbox class="m-1" [(ngModel)]="aceptoPoliticas">
                      Acepto la <a routerLink="/política-devolucion-y-envio">política de devoluciones y envío</a>
                  </mat-checkbox>
                </div>
                <div class="w-100 d-flex justify-content-center mb-2" *ngIf="!userInfo && selectDirection">
                  <button class="btn btn-primary text-light btn-delivery-data" [disabled]="!aceptoPoliticas"
                          (click)="paymentOrder(AddingOrder)"><i class="far fa-credit-card mr-1"></i>Realizar Pago</button>
                </div>
                <div class="w-100" *ngIf="!userInfo && !selectDirection">
                  <div class="row">
                    <div class="col-12 d-flex justify-content-center  mb-2">
                      <button class="btn btn-primary text-light btn-delivery-data" [disabled]="!aceptoPoliticas"
                              (click)="paymentOrder(AddingOrder)"><i class="far fa-credit-card mr-1"></i>Realizar Pago</button>
                    </div>
                  </div>
                </div>
                <div *ngIf="userInfo">
                  <div class="w-100 d-flex justify-content-center mb-2" *ngIf="selectDirection">
                    <button class="btn btn-primary text-light btn-delivery-data" [disabled]="!aceptoPoliticas"
                            (click)="paymentOrder(AddingOrder)"><i class="far fa-credit-card mr-1"></i>Realizar Pago</button>
                  </div>
                  <div class="w-100 d-flex justify-content-center" *ngIf="!selectDirection">
                    <div class="row">
                      <div class="col-md-6 d-flex justify-content-center mb-2">
                        <button class="btn btn-primary text-light btn-delivery-data" [disabled]="!aceptoPoliticas"
                                (click)="paymentOrder(AddingOrder)">Realizar Pago</button>
                      </div>
                      <div class="col-md-6 d-flex justify-content-center mb-2">
                        <button class="btn btn-primary text-light btn-delivery-data" [disabled]="!aceptoPoliticas"
                                (click)="makeOrder(AddingOrder)"><i class="fas fa-store-alt"></i> Pagar en tienda</button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>

            </div>
          </div>
        </div>

        <div class="col-md-5 computer-summary">
          <div class="card">
            <div class="card-header bg-white text-black-50">
              <h2 class="order-title">Resumen del pedido</h2>
            </div>


            <div class="card-body">
              <span><b>Número de artículos</b> {{articles.length}}</span><br/>
              <span *ngIf="totalTransport > 0 && totalTransport != null">
                <b>Precio de transporte:</b> {{(totalTransport | currency: 'EUR')}}<br/>
              </span>
              <span>
                <b>Precio Total: <span [style]="'color: ' + companyConf.priceColor + ';'">
                {{(totalPrice | currency: 'EUR')}}
              </span></b>
              </span><br/>
                <span *ngIf="totalTransport == 0">
                <div *ngIf="homeDelivery; else pickUpStore">
                  <b>Entrega: </b>Gratuita<br/><br/>
                </div>
                <ng-template #pickUpStore>
                  RECOGIDA EN TIENDA
                </ng-template>
              </span><br/>
            </div>
          </div>

          <div class="card" *ngIf="articuloRegalo != undefined">
            <div class="card-header bg-white text-black-50" *ngIf="!contactValid || !directionValid">
              <h2 class="order-title">Preparar para regalo</h2>
            </div>

            <div class="card-body">
              <div class="d-flex w-100">
                <img *ngIf="articuloRegalo.articleImgUrl; else productImg" src="./assets/fotos/articulos/{{articuloRegalo.articleImgUrl.toLowerCase()}}" alt="Foto del artículo"
                     width="100px" height="auto"/>
                <ng-template #productImg>
                  <img *ngIf="articuloRegalo.productImgUrl" src="./assets/fotos/productos/{{articuloRegalo.productImgUrl.toLowerCase()}}" alt="Foto del artículo"
                       width="100px" height="auto"/>
                </ng-template>

                <div class="ml-2">
                  <div class="mb-2" style="font-size: 13px">
                    {{articuloRegalo.name}}
                  </div>
                  <div class="font-weight-bolder">
                    {{articuloRegalo.priceIva.toFixed(2).replace('.', ',')}} €
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-center w-100 mt-3">
                <button class="btn btn-primary text-light" *ngIf="!prepararRegalo; else existeRegalo"
                        (click)="prepararRegalo = true; addArticuloRegalo()">
                  Añadir al pedido
                </button>

                <ng-template #existeRegalo>
                  <div class="d-flex">
                    <i class="fa-solid fa-check fa-2x text-success"></i>
                    <div class="h-100 d-flex align-items-center ml-2">
                      <b>Preparar para regalo</b>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>

  <!-- ______ USER REGISTER ____________ -->
  <div class="container" *ngIf="userInfo && !showSuccessOrder">
    <div class="row">
      <!-- RESUMEN MOBIL -->
      <div class="col-md-5 mobil-summary">
        <div class="card">
          <div class="card-header bg-white text-black-50">
            <h2 class="order-title">Resumen del pedido</h2>
          </div>

          <div class="card-body">
            <span><b>Número de artículos</b> {{articles.length}}</span><br/>
            <span *ngIf="this.totalLumps"><b>Número de bultos:</b> {{totalLumps}}<br/></span>
            <span *ngIf="totalTransport > 0 && totalTransport != null">
            <b>Precio de transporte:</b> {{(totalTransport | currency: 'EUR')}}<br/>
          </span>
            <span>
            <b>Precio Total:
              <span [style]="'color: ' + companyConf.priceColor + ';'">
                {{(totalPrice | currency: 'EUR')}}
              </span></b><br/>
          </span>
            <span *ngIf="totalTransport == 0 && selectedStorePickupDate == null">
              <div *ngIf="homeDelivery; else pickUpStore">
                Entrega <span class="text-danger">GRATUITA</span><br/>
              </div>
              <ng-template #pickUpStore>
                RECOGIDA EN TIENDA
              </ng-template>
          </span>

            <div *ngIf="companyConf.seeArticlesSummary">
              <br/>
              <button class="btn btn-primary text-light w-100" (click)="showSummaryArticles = !showSummaryArticles">
                <span *ngIf="!showSummaryArticles">Ver</span><span *ngIf="showSummaryArticles">Ocultar</span> articulos pedidos
              </button>
              <div class="p-2" *ngIf="showSummaryArticles">
                <ul class="p-0" style="list-style-type: none !important;">
                  <li *ngFor="let article of articles">
                    {{article.name}}
                    <div class="p-2 w-100 border">
                      <div *ngIf="article.quantity != 0 && article.quantity != null">
                        {{article.quantity + ' ' + formatNameFn(article.format)}}
                      </div>
                      <span *ngIf="article.cutType != null && article.cutType != ''" class="ml-2">{{article.cutName}}</span>
                    </div>

                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="card" *ngIf="articuloRegalo != undefined">
          <div class="card-header bg-white text-black-50" *ngIf="!contactValid || !directionValid">
            <h2 class="order-title">Preparar para regalo</h2>
          </div>

          <div class="card-body">
            <div class="d-flex w-100">
              <img *ngIf="articuloRegalo.articleImgUrl; else productImg" src="./assets/fotos/articulos/{{articuloRegalo.articleImgUrl.toLowerCase()}}" alt="Foto del artículo"
                   width="100px" height="auto"/>
              <ng-template #productImg>
                <img *ngIf="articuloRegalo.productImgUrl" src="./assets/fotos/productos/{{articuloRegalo.productImgUrl.toLowerCase()}}" alt="Foto del artículo"
                     width="100px" height="auto"/>
              </ng-template>

              <div class="ml-2">
                <div class="mb-2" style="font-size: 13px">
                  {{articuloRegalo.name}}
                </div>
                <div class="font-weight-bolder">
                  {{articuloRegalo.priceIva.toFixed(2).replace('.', ',')}} €
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-center w-100 mt-3">
              <button class="btn btn-primary text-light" *ngIf="!prepararRegalo; else existeRegalo"
                      (click)="prepararRegalo = true; addArticuloRegalo()">
                Añadir al pedido
              </button>

              <ng-template #existeRegalo>
                <div class="d-flex">
                  <i class="fa-solid fa-check fa-2x text-success"></i>
                  <div class="h-100 d-flex align-items-center ml-2">
                    <b>Preparar para regalo</b>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-7">
        <!-- DATOS PERSONALES -->
        <div class="card">
          <div class="card-header bg-white text-black-50">
            <h2 class="order-title">1. Datos de contacto</h2>
          </div>

          <div class="card-body" *ngIf="!contactValid">
            <span><b>Nombre:</b> {{userInfo.name}}</span><br/>
            <span *ngIf="userInfo.phone"><b>Teléfono Principal:</b> {{userInfo.phone}}</span><br/>
            <span *ngIf="userInfo.phone2"><b>Teléfono Secundario:</b> {{userInfo.phone2}}<br/></span>
            <span  *ngIf="userInfo.email"><b>Correo electrónico:</b> {{userInfo.email}}</span>
          </div>
        </div>

        <!-- DIRECCIÓN -->
        <div class="card">
          <div class="card-header bg-white text-black-50 d-flex" *ngIf="contactUserInvalid || selectedStorePickupDate">
            <div style="width: 90%">
              <h2 class="order-title">2. Datos de entrega</h2>
            </div>
            <div style="width: 10%">
              <button type="button" class="btn btn-outline-secondary" (click)="editClientDirection()"><i class="far fa-edit"></i>
              </button>
            </div>
          </div>
          <div class="card-header bg-white" *ngIf="!contactUserInvalid && !selectedStorePickupDate">
            <h2 class="order-title">2. Datos de entrega</h2>
          </div>

          <div *ngIf="companyConf.puntosRecogida; else direcciones"  class="card-body">
            <div class="card-body" *ngIf="directionValid; else elseSeleccionarPuntoRecogida">
              <div><b>Recogida el</b> {{selectedStorePickupDate.value + ' sobre las ' + scheduleHour}}</div>
              <div *ngIf="this.ruta != null"><b>Punto de recogida:</b> {{nombreRuta()}}</div>
            </div>

            <ng-template #elseSeleccionarPuntoRecogida>
              <div class="w-100" *ngIf="selectPickUpDate">
                <div *ngIf="companyConf.rutasRecogida != undefined">
                  <div class="row" *ngIf="companyConf.rutasRecogida.length > 0">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Punto de recogida</mat-label>
                        <mat-select class="w-100" [(ngModel)]="ruta" (ngModelChange)="cambiarHorarioRecogida()">
                          <mat-option *ngFor="let ruta of companyConf.rutasRecogida" [value]="ruta.id">{{ruta.nombre}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="companyConf.storePickUpSchedule">
                  <div class="col-sm-12">
                    <mat-form-field class="w-100">
                      <mat-label>Hora de recogida</mat-label>
                      <mat-select class="w-100" [(ngModel)]="scheduleHour">
                        <mat-option *ngFor="let hour of companyConf.storePickUpSchedule" [value]="hour">{{hour}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div *ngIf="ruta != undefined">
                  <form  [formGroup]="deliveryDate" novalidate>
                    <div class="row">
                      <div class="col-sm-12">
                        <mat-form-field class="w-100" appearance="fill">
                          <mat-label>Fecha de recogida</mat-label>
                          <input class="w-100" [matDatepicker]="myPicker"
                                 [matDatepickerFilter]="calendarioRecogidaRuta"
                                 (click)="myPicker.open()"
                                 (dateChange)="selectPickupCalendarDate($event)"
                                 formControlName="deliveryDate" matInput readonly required>
                          <mat-datepicker-toggle  matSuffix [for]="myPicker"></mat-datepicker-toggle>
                          <mat-datepicker touchUi #myPicker></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </ng-template>
          </div>


          <ng-template #direcciones>
            <div class="card-body" *ngIf="storePickup && directionValid">
              <div><b>Recogida en tienda:</b> {{selectedStorePickupDate.value + ' ' + scheduleHour}}</div>
              <div *ngIf="this.ruta != null"><b>Lugar de recogida:</b> {{nombreRuta()}}</div>
            </div>

            <!-- DIRECCIÓN EMPRESAS O CLIENTES CON RUTAS-->
            <div *ngIf="!newDirection"
                 class="card-body w-100">
              <div class="w-100" *ngIf="showDirections || editDirection || selectedStorePickupDate == null">
                <div *ngIf="companyConf.storePickup" class="row mb-3">
                  <div class="col-12 mb-2" *ngIf="companyConf.storePickUpDates.length > 0">
                    <button type="button" class="btn btn-outline-primary w-100"
                            (click)="storePickup = true; selectPickUpDateCalendar = true; selectedStorePickupDate = null; selectPickUpDate = true; this.deliveryDate.controls['deliveryDate'].setValue(null); selectDirection = false; seleccionarRuta(); setHomeDeliveryFn(false);">
                      <i class="far fa-calendar-alt mr-1"></i>{{companyConf.storePickUpDatesText}}
                    </button>
                  </div>

                  <div class="col-12 mb-2">
                    <button type="button" class="btn btn-outline-primary w-100"
                            (click)="storePickup = true; selectPickUpDate = true; ruta = null;  selectedStorePickupDate = null; selectPickUpDateCalendar = false;this.deliveryDate.controls['deliveryDate'].setValue(null); selectDirection = false; setHomeDeliveryFn(false);">
                      <i class="fas fa-store-alt mr-1"></i>Recoger en tienda
                    </button>
                  </div>

                  <div class="col-12 mb-2" *ngIf="!companyConf.soloRecogidaTienda">
                    <button type="button" class="btn btn-outline-primary w-100"
                            (click)="storePickup = false; selectDirection = true; ruta = null; selectedStorePickupDate = null; selectPickUpDate = false;this.deliveryDate.controls['deliveryDate'].setValue(null);  selectPickUpDateCalendar = false; setHomeDeliveryFn(true);">
                      <i class="fas fa-truck mr-1"></i>Envío a domicilio
                    </button>
                  </div>
                </div>
              </div>

              <div class="w-100" *ngIf="selectPickUpDate">
                <div *ngIf="!selectPickUpDateCalendar; else calendar">
                  <form  [formGroup]="deliveryDate" novalidate>
                    <div class="row">
                      <div class="col-sm-12">
                        <mat-form-field class="w-100" appearance="fill">
                          <mat-label>Fecha de recogida</mat-label>
                          <input class="w-100" [matDatepicker]="myPicker"
                                 [matDatepickerFilter]="calendarioRecogidaEnTienda"
                                 (click)="myPicker.open()"
                                 (dateChange)="selectPickupCalendarDate($event)"
                                 formControlName="deliveryDate" matInput readonly required>
                          <mat-datepicker-toggle  matSuffix [for]="myPicker"></mat-datepicker-toggle>
                          <mat-datepicker touchUi #myPicker></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                  </form>
                </div>

                <ng-template #calendar>
                  <form  [formGroup]="deliveryDate" novalidate>
                    <div class="row">
                      <div class="col-sm-12">
                        <mat-form-field class="w-100" appearance="fill">
                          <mat-label>Fecha de recogida</mat-label>
                          <input class="w-100" [matDatepicker]="myPicker"
                                 [matDatepickerFilter]="daysStorePickupCalendar"
                                 (click)="myPicker.open()"
                                 (dateChange)="selectPickupCalendarDate($event)"
                                 formControlName="deliveryDate" matInput readonly required>
                          <mat-datepicker-toggle  matSuffix [for]="myPicker"></mat-datepicker-toggle>
                          <mat-datepicker touchUi #myPicker></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                  </form>
                </ng-template>

                <div class="row" *ngIf="companyConf.storePickUpSchedule">
                  <div class="col-sm-12">
                    <mat-form-field class="w-100">
                      <mat-label>Hora de recogida</mat-label>
                      <mat-select class="w-100" [(ngModel)]="scheduleHour">
                        <mat-option *ngFor="let hour of companyConf.storePickUpSchedule" [value]="hour">{{hour}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div *ngIf="companyConf.rutasRecogida != undefined && selectPickUpDateCalendar">
                  <div class="row" *ngIf="companyConf.rutasRecogida.length > 0">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Lugar de recogida</mat-label>
                        <mat-select class="w-100" [(ngModel)]="ruta">
                          <mat-option *ngFor="let ruta of companyConf.rutasRecogida" [value]="ruta.id">{{ruta.nombre}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>

              <!-- DIRECCIÓN SELECCIONADA -->
              <div class="container"  *ngIf="!showDirections && !selectPickUpDate && !storePickup">
                <div class="card border-info bg-direction" style="margin-top: 1.25em">
                  <div *ngIf="!selectedDirection">
                    <div class="container" *ngIf="userInfo.isInRoute == 1 ">
                      <span>{{userInfo.direction}}</span><br/>
                      <span>{{userInfo.countryName}}, {{userInfo.provinceName}}, {{userInfo.cityName}},
                        {{userInfo.province}}{{userInfo.city}}<br/></span>
                      <span *ngIf="userInfo.phone">{{userInfo.phone}}<br/></span>
                    </div>

                    <div class="container text-white no-direction" *ngIf="userInfo.isInRoute == 0">
                      <b>Dirección no disponible - </b>
                      <span>{{userInfo.direction}}</span><br/>
                      <span>{{userInfo.countryName}}, {{userInfo.provinceName}}, {{userInfo.cityName}},
                        {{userInfo.province}}{{userInfo.city}}</span>
                    </div>
                  </div>

                  <div class="container" *ngIf="selectedDirection">
                    <span>{{selectedDirection.direction}}</span><br/>
                    <span>{{selectedDirection.countryName}}, {{selectedDirection.provinceName}}, {{selectedDirection.cityName}},
                      {{selectedDirection.province}}{{selectedDirection.city}}</span><br/>
                    <span *ngIf="selectedDirection.phone">{{selectedDirection.phone}}<br/></span>
                  </div>
                </div>

                <form  [formGroup]="deliveryDate" novalidate>
                  <div class="row">
                    <div class="col-sm-12">
                      <mat-form-field class="w-100" appearance="fill" *ngIf="selectDirection">
                        <mat-label>Fecha de entrega</mat-label>
                        <input class="w-100" [matDatepicker]="myPicker"
                               [matDatepickerFilter]="calendarioEntregaUsuario"
                               (click)="myPicker.open()"
                               (dateChange)="seleccionarFechaEntrega($event)"
                               formControlName="deliveryDate" matInput readonly required>
                        <mat-datepicker-toggle  matSuffix [for]="myPicker"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #myPicker></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                </form>
              </div>

              <div class="d-flex justify-content-center w-100" *ngIf="!showDirections && selectDirection">
                <button class="btn btn-outline-info" style="width: 200px; margin-bottom: 1.25em"
                        (click)="selectDirectionFn()">Cambiar dirección</button>
              </div>

              <!-- DIRECCIÓN NO SELECCIONADA -->
              <div class="container" *ngIf="showDirections && selectDirection  && !storePickup">
                <div class="card border-info bg-no-direction" style="margin-top: 1.25em">
                  <div class="container" (click)="selectPostalDirection()" *ngIf="userInfo.isInRoute == 1">
                    <span>{{userInfo.direction}}</span><br/>
                    <span>{{userInfo.countryName}}, {{userInfo.provinceName}}, {{userInfo.cityName}},
                      {{userInfo.province}}{{userInfo.city}}</span>
                  </div>

                  <div class="container text-white no-direction" *ngIf="userInfo.isInRoute == 0">
                    <span>{{userInfo.direction}}</span><br/>
                    <span>{{userInfo.countryName}}, {{userInfo.provinceName}}, {{userInfo.cityName}},
                      {{userInfo.province}}{{userInfo.city}}</span>
                  </div>
                </div>

                <div *ngFor="let direction of directions" class="card border-info bg-no-direction" style="margin-top: 1.25em">
                  <div class="container" *ngIf="direction.isInRoute == 1" (click)="selectNewDirection(direction)">
                    <span>{{direction.direction}}</span><br/>
                    <span>{{direction.countryName}}, {{direction.provinceName}},
                      {{direction.cityName}}, {{direction.province}}{{direction.city}}</span><br/>
                    <span>{{direction.phone}}</span>
                  </div>

                  <div class="container text-white no-direction" *ngIf="direction.isInRoute == 0">
                    <b>DIRECCIÓN NO DISPONIBLE - </b><span>{{direction.direction}}</span><br/>
                    <span>{{direction.countryName}}, {{direction.provinceName}},
                      {{direction.cityName}}, {{direction.province}}{{direction.city}}</span><br/>
                    <span>{{direction.phone}}</span>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-center w-100" *ngIf="showDirections && selectDirection" >
                <button class="btn btn-dark" style="width: 13.75em; margin-right: 15px; margin-bottom: 1.25em"
                        (click)="showDirections = false">Cancelar</button>

                <button class="btn btn-outline-info" style="width: 13.75em; margin-bottom: 1.25em"
                        (click)="newDirection = true">Añadir dirección</button>
              </div>
            </div>

            <!-- DIRECCIÓN EMPRESAS O CLIENTES CON RUTAS -- ELEGIR NUEVA DIRECCIÓN-->
            <div class="card-body" *ngIf="newDirection">
              <form (ngSubmit)="addDirection()" [formGroup]="directionForm" novalidate>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <ng-select bindLabel="País"
                                 formControlName="country"
                                 placeholder="País*"

                                 (change)="getProvinces()">
                        <ng-option *ngFor="let country of countries" [value]="country.id">{{country.name}}</ng-option>
                      </ng-select>
                      <div *ngIf="directionForm.controls.country.touched && directionForm.controls.country.errors?.required"
                           class="error">Campo obligatorio.
                      </div>
                    </div>

                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <ng-select bindLabel="name"
                                 formControlName="province"
                                 placeholder="Provincia*"

                                 (change)="getCities()">
                        <ng-option *ngFor="let province of provinces" [value]="province.id">{{province.name}}</ng-option>
                      </ng-select>
                      <div *ngIf="directionForm.controls.province.touched && directionForm.controls.province.errors?.required"
                           class="error">Campo obligatorio.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row select-container">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <ng-select bindLabel="name"
                                 formControlName="city"
                                 placeholder="Población*"
                                 (change)="setMsg()">
                        <ng-option value="default">Seleccione una ciudad</ng-option>
                        <ng-option *ngFor="let city of cities" [value]="city.id">{{city.name}},
                          {{directionForm.controls.province.value}}{{city.id}}
                        </ng-option>
                      </ng-select>
                      <div *ngIf="directionForm.controls.city.touched &&
                            (directionForm.controls.city.errors?.required || directionForm.controls.city.errors?.defaultOption)"
                           class="error">Campo obligatorio.
                      </div>
                      <div *ngIf="msgInfoDays" class="text-info">
                        <div [innerHTML]="msgInfoDays"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <mat-form-field appearance="legacy" class="w-100">
                      <mat-label>Dirección</mat-label>
                      <input type="text" class="w-100" placeholder="Ej: Calle Alcalá, 48, 1ºA"
                             formControlName="direction" matInput>
                      <div *ngIf="directionForm.controls.direction.touched && directionForm.controls.direction.errors?.required"
                           class="error">Campo obligatorio.
                      </div>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <mat-form-field appearance="legacy" class="w-100">
                      <mat-label>Teléfono</mat-label>
                      <input type="text" class="w-100" formControlName="phoneNumber" matInput>
                      <div *ngIf="directionForm.controls.phoneNumber.touched
                        && directionForm.controls.phoneNumber.errors?.pattern"
                           class="error">Campo obligatorio.
                      </div>
                    </mat-form-field>
                  </div>
                </div>

                <div class="max d-flex justify-content-center">
                  <button type="submit" class="btn btn-dark mr-2"
                          (click)="newDirection = false;" style="width: 150px">Cancelar</button>
                  <button type="submit" class="btn btn-primary text-light btn-block" *ngIf="directionForm.valid"
                          style="width: 150px">Continuar</button>
                  <button type="submit" class="btn btn-danger" *ngIf="!directionForm.valid"
                          [disabled]="!directionForm.valid" style="width: 150px">Faltan Datos</button>
                </div>
              </form>
            </div>
          </ng-template>
        </div>

        <div class="card">
          <div class="card-header bg-white">
            <h2 class="order-title">
              <span>3. Proceso de Pago</span>
            </h2>
          </div>
          <div class="card-body" *ngIf="!showDirections && !editDirection && isInRoute == 1" >
            <div *ngIf="userInfo">
              <div>
                <mat-checkbox class="m-1" [(ngModel)]="aceptoPoliticas">
                  Acepto la <a routerLink="/política-devolucion-y-envio">política de devoluciones y envío</a>
                </mat-checkbox>
              </div>

              <div *ngIf="companyConf.pasarelaActiva">
                <div class="w-100 d-flex justify-content-center mb-2" *ngIf="!storePickup">
                  <button class="btn btn-primary text-light btn-delivery-data" [disabled]="!deliveryDateValid || !aceptoPoliticas"
                          (click)="paymentOrder(AddingOrder)"><i class="far fa-credit-card mr-1"></i>Realizar Pago</button>
                </div>
                <div class="row" *ngIf="storePickup">
                  <div class="col-12 mb-2">
                    <button class="btn btn-primary text-light w-100" [disabled]="!deliveryDateValid || !aceptoPoliticas"
                            (click)="paymentOrder(AddingOrder)"><i class="far fa-credit-card mr-1"></i>Realizar Pago</button>
                  </div>
                </div>
              </div>

              <div *ngIf="!companyConf.pasarelaActiva" class="w-100">
                <div class="d-flex justify-content-center mb-2 w-100">
                  <button class="btn btn-primary text-light btn-delivery-data" [disabled]="!deliveryDateValid"
                          (click)="makeOrder(AddingOrder)"><i class="fas fa-store-alt"></i> Pagar en tienda</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="col-md-5 computer-summary">
        <div class="card">
          <div class="card-header bg-white text-black-50">
            <h2 class="order-title">Resumen del pedido</h2>
          </div>

          <div class="card-body">
            <span><b>Número de artículos</b> {{articles.length}}</span><br/>
            <span *ngIf="this.totalLumps"><b>Número de bultos:</b> {{totalLumps}}<br/></span>
            <span *ngIf="totalTransport > 0 && totalTransport != null">
            <b>Precio de transporte:</b> {{(totalTransport | currency: 'EUR')}}<br/>
          </span>
            <b>Precio Total:
              <span [style]="'color: ' + companyConf.priceColor + ';'">
            {{(totalPrice | currency: 'EUR')}}
            </span>
            </b><br/>
            <span *ngIf="totalTransport == 0 && selectedStorePickupDate == null">
            <div *ngIf="homeDelivery; else pickUpStore">
              Entrega <span class="text-danger">GRATUITA</span><br/>
            </div>
            <ng-template #pickUpStore>
              RECOGIDA EN TIENDA
            </ng-template>
          </span>

            <div *ngIf="companyConf.seeArticlesSummary">
              <br/>
              <button class="btn btn-primary text-light w-100" (click)="showSummaryArticles = !showSummaryArticles">
                <span *ngIf="!showSummaryArticles">Ver</span><span *ngIf="showSummaryArticles">Ocultar</span> articulos pedidos
              </button>
              <div class="p-2" *ngIf="showSummaryArticles">
                <ul class="p-0" style="list-style-type: none !important;">
                  <li *ngFor="let article of articles">
                    {{article.name}}
                    <div class="p-2 w-100 border">
                      <div *ngIf="article.quantity != 0 && article.quantity != null">
                        {{article.quantity + ' ' + formatNameFn(article.format)}}
                      </div>
                      <span *ngIf="article.cutType != null && article.cutType != ''" class="ml-2">{{article.cutName}}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="card" *ngIf="articuloRegalo != undefined">
          <div class="card-header bg-white text-black-50" *ngIf="!contactValid || !directionValid">
            <h2 class="order-title">Preparar para regalo</h2>
          </div>

          <div class="card-body">
            <div class="d-flex w-100">
              <img *ngIf="articuloRegalo.articleImgUrl; else productImg" src="./assets/fotos/articulos/{{articuloRegalo.articleImgUrl.toLowerCase()}}" alt="Foto del artículo"
                   width="100px" height="auto"/>
              <ng-template #productImg>
                <img *ngIf="articuloRegalo.productImgUrl" src="./assets/fotos/productos/{{articuloRegalo.productImgUrl.toLowerCase()}}" alt="Foto del artículo"
                     width="100px" height="auto"/>
              </ng-template>

              <div class="ml-2">
                <div class="mb-2" style="font-size: 13px">
                  <b>
                    {{articuloRegalo.name}}
                  </b>
                </div>
                <div class="mb-2" style="font-size: 13px" *ngIf="articuloRegalo.ingredients">
                  {{articuloRegalo.ingredients}}
                </div>
                <div class="font-weight-bolder">
                  {{articuloRegalo.priceIva.toFixed(2).replace('.', ',')}} €
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-center w-100 mt-3">
              <button class="btn btn-primary text-light" *ngIf="!prepararRegalo; else existeRegalo"
                      (click)="prepararRegalo = true; addArticuloRegalo()">
                Añadir al pedido
              </button>

              <ng-template #existeRegalo>
                <div class="d-flex">
                  <i class="fa-solid fa-check fa-2x text-success"></i>
                  <div class="h-100 d-flex align-items-center ml-2">
                    <b>Preparar para regalo</b>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ______ SUCCESS ORDER - REGISTER USER____________ -->
<div class="row success-order " *ngIf="showSuccessOrder && userInfo">
  <div class="col-md-2"></div>
  <div class="col-md-8 d-flex justify-content-center">
    <div>
      <div class="text-primary max d-flex justify-content-center">
        <i class="fas fa-thumbs-up fa-5x"></i>
      </div>
      <div class="max d-flex justify-content-center text-center">
        <h1>Su pedido se ha procesado con éxito</h1>
      </div>
      <p>Gracias por realizar tu pedido, puedes ver tus pedidos en la página de
        <a routerLink="/miPerfil">Mi perfil</a>, pulsado sobre
        <a routerLink="/miPerfil/misPedidos">Mis Pedidos.</a></p>

      <div class="max d-flex justify-content-center text-center">
        <h3><b>Resumen del pedido</b></h3>
      </div>

      <div class="border">
        <div class="row">
          <div class="col-md-6">
            <span><i><b>Número de pedido:</b></i> {{orderResult.orderNumber}}<br/></span>
            <span><i><b>Número de artículos</b></i> {{articles.length}}<br/></span>
            <span *ngIf="this.totalLumps"><i><b>Número de bultos:</b></i> {{totalLumps}}<br/></span>
            <i><b>Precio Total:</b ></i> <span [style]="'color: ' + companyConf.priceColor + ';'">
              {{(totalPrice | currency: 'EUR')}}</span>
            <br/>
            <span *ngIf="totalTransport == 0 && selectedStorePickupDate == null">
              <div *ngIf="homeDelivery; else pickUpStore">
                Entrega <span class="text-danger">GRATUITA</span><br/>
              </div>
              <ng-template #pickUpStore>
                RECOGIDA EN TIENDA
              </ng-template>
            </span><br/>
          </div>


          <div class="col-md-6">
            <span><i><b>Fecha de entrega:</b></i> {{orderResult.routeDate}}<br/></span>
            <span *ngIf="companyConf.showClientSchedule && userInfo.schedule"><b>Horario: </b>{{userInfo.schedule}}</span>
            <span *ngIf="orderResult.routeHour != null && orderResult.routeHour !== ''"><i><b>Horario:</b></i> {{orderResult.routeHour}}<br/></span>
            <p *ngIf="companyConf.showContactDetails"><i><b>Dirección de entrega</b></i><br/>
              <span *ngIf="selectedDirection">
                {{selectedDirection.direction}}<br/>
                {{selectedDirection.provinceName}},  {{selectedDirection.cityName}}, {{selectedDirection.province}}{{selectedDirection.city}}
              </span>

              <span *ngIf="!selectedDirection">
                {{userInfo.direction}}<br/>
                {{userInfo.provinceName}},  {{userInfo.cityName}}, {{userInfo.province}}{{userInfo.city}}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-2"></div>
</div>

<!-- ______ SUCCESS ORDER - NO REGISTER USER____________ -->
<div class="row success-order" *ngIf="showSuccessOrder && !userInfo">
  <div class="col-md-2"></div>
  <div class="col-md-8 d-flex justify-content-center">
    <div>
      <div class="text-primary max d-flex justify-content-center">
        <i class="fas fa-thumbs-up fa-5x"></i>
      </div>
      <div class="max d-flex justify-content-center text-center">
        <h1>Su pedido se ha procesado con éxito</h1>
      </div>
      <p>Gracias por realizar tu pedido.</p>

      <div class="max d-flex justify-content-center text-center">
        <h3><b>Resumen del pedido</b></h3>
      </div>

      <div class="border">
        <div class="row">
          <div class="col-md-6">
            <span><i><b>Número de pedido:</b></i> {{orderResult.orderNumber}}<br/></span>
            <span><i><b>Número de artículos</b></i> {{articles.length}}</span><br/>
            <i>
              <b>Precio Total:</b></i>
            <span [style]="'color: ' + companyConf.priceColor + ';'">
              {{(totalPrice | currency: 'EUR')}}
              </span><br/>
            <span *ngIf="totalTransport == 0 && selectedStorePickupDate == null">
             <div *ngIf="homeDelivery; else pickUpStore">
                Entrega <span class="text-danger">GRATUITA</span><br/>
              </div>
              <ng-template #pickUpStore>
                RECOGIDA EN TIENDA
              </ng-template>
            </span><br/>
          </div>

          <div class="col-md-6" *ngIf="selectedStorePickupDate">
            <span><i><b>Día de recogida:</b></i> {{orderResult.routeDate}} </span><br/>
            <span><i><b>Horario:</b></i> {{orderResult.routeHour}} </span><br/>
          </div>

          <div class="col-md-6" *ngIf="selectedDirection">
            <p><i><b>Dirección de entrega</b></i><br/>
              <span>
                {{selectedDirection.direction}}<br/>
                {{selectedDirection.provinceName}},  {{selectedDirection.cityName}}, {{selectedDirection.province}}{{selectedDirection.city}}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-2"></div>
</div>

<div class="container" *ngIf="tpvParameters != undefined">
  <div *ngIf="tpvParameters.paycomet_url != undefined; else CECA">
    <iframe title="titulo" sandbox="allow-top-navigation allow-scripts allow-same-origin allow-forms"
            [src]="tpvParameters.paycomet_url | safe"
            onload="this.style.height=(this.contentWindow.document.body.scrollHeight+20)+'px';"
            width="100%" height="2000"
            frameborder="0" marginheight="0" marginwidth="0" scrolling="no"
            style="border: 0px solid #000000; padding: 0px; margin: 0px"></iframe>
  </div>
</div>

<ng-template #CECA>
  <div *ngIf="tpvParameters.ceca; else REDSYS">
    <!-- ENCTYPE="application/x-www-form-urlencoded https://tpv.ceca.es/tpvweb/tpv/compra.action"-->
    <FORM id='redirectFormCeca' action="{{companyConf.tpvUrl}}" method="POST" name="from" ENCTYPE="application/x-www-form-urlencoded">
      <INPUT name="MerchantID" type="hidden" value="{{tpvParameters.merchantID}}">
      <INPUT name="AcquirerBIN" type="hidden" value="{{tpvParameters.acquirerBIN}}">
      <INPUT name="TerminalID" type="hidden" value="{{tpvParameters.terminalID}}"><!--{{tpvParameters.terminalID}}-->
      <INPUT name="Num_operacion" type="hidden" value="{{tpvParameters.num_operacion}}">
      <INPUT name="Importe" type="hidden" value="{{tpvParameters.importe}}">
      <INPUT name="TipoMoneda" type="hidden" value="978">
      <INPUT name="Exponente" type="hidden" value="2">
      <INPUT name="URL_OK" type="hidden" value="{{tpvParameters.url_ok}}">
      <INPUT name="URL_NOK" type="hidden" value="{{tpvParameters.url_nok}}">

      <INPUT name="Firma" type="hidden" value="{{tpvParameters.firma}}">
      <INPUT name="Cifrado" type="hidden" value="SHA2">
      <INPUT name="Pago_soportado" type="hidden" value="SSL">
      <INPUT name="Idioma" type="hidden" value="1">
    </FORM>
  </div>
</ng-template>

<ng-template #REDSYS>
  <!-- OCCULT FORM TPV -->
  <form id='redirectForm' name="from" action="{{companyConf.tpvUrl}}" method="POST">
    <input name="Ds_SignatureVersion"  type="hidden" value="{{tpvParameters.ds_SignatureVersion}}"/>
    <input name="Ds_MerchantParameters"  type="hidden" value="{{tpvParameters.ds_MerchantParameters}}"/>
    <input name="Ds_Signature"  type="hidden" value="{{tpvParameters.ds_Signature}}"/>
  </form>
</ng-template>

<!-- Info User -->
<ng-template #INFO_USER let-modal class="modal">
  <div class="modal-body">
    <div class="container">
      <div class="d-flex mb-2">
        <div class="font-weight-bold mr-4" style="width: 90%;">
          <h1 class="text-info">Tipos de acceso</h1>
        </div>
        <div class="d-flex justify-content-end"
             style="width: 10%">
          <a (click)="modal.close()" class="text-danger">
            <i class="fas fa-times-circle fa-2x"></i>
          </a>
        </div>
      </div>

      <div class="w-100" style="margin-bottom: 10px"
           *ngIf="companyConf.companyType !== 'E'">
        <h4 CLASS="font-weight-bold mb-0"><span>PARTICULARES</span></h4>
        <span>Los clientes particulares pueden acceder indicando su <b>teléfono</b>, <b>correo electrónico </b>
          o <b>código de cliente</b>.</span>
        <br/>
      </div>

      <div class="w-100" style="margin-bottom: 10px"
           *ngIf="companyConf.companyType !== 'P'">
        <h4 class="mb-0 font-weight-bold"><span>EMPRESAS</span></h4>
        <span>Las empresas pueden acceder indicando su <b>teléfono</b>, <b>correo electrónico</b>,
          <b> CIF</b> o <b>código de cliente</b>.</span>
      </div>

      <div class="d-flex justify-content-center">
        <button type="button" class="btn btn-success  btn-modal" (click)="modal.close()" >
          ACEPTAR
        </button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #AddingOrder let-modal class="modal">
  <div class="modal-body">
    <div class="container">
      <div class="w-100 font-weight-bold w-100 text-center">
        <h1 class="text-dark">Tramitando pedido</h1>
        <div class="w-100 d-flex justify-content-center mb-3">
          <img src="assets/loading/loading.gif" alt="Cargando icono" style="height: 150px !important; width: auto !important;"/>
        </div>
      </div>
    </div>
  </div>
</ng-template>
