import { Component, OnInit } from '@angular/core';
import {WebEstaticaService} from '../../../services/web-estatica/web-estatica.service';
import {Politica} from '../../../interfaces/web-estatica/politica';

@Component({
  selector: 'app-politica-de-devolucion',
  templateUrl: './politica-de-devolucion.component.html',
  styleUrls: ['./politica-de-devolucion.component.scss']
})
export class PoliticaDeDevolucionComponent implements OnInit {

  constructor(private webEstaticaService: WebEstaticaService) { }

  politicaJSON: Politica | undefined;
  ngOnInit(): void {
    this.webEstaticaService.getPoliticasJSON().subscribe(data => {
      this.politicaJSON = data;
    });
  }

}
