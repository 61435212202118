<section id="foto">
  <div class="container mt-4 mb-5">
    <img src="assets/web-estatica/inicio/club.webp" width="100%" height="auto" alt=""
        class="pointer" routerLink="/registrarse">

    <div class="mt-5 w-100">
      <h1 class="w-100 font-weight-bolder">
        Ventajas para tu bolsillo
      </h1>

      <ul class="mt-5" *ngIf="clubJSON != undefined">
        <li class="font-weight-bolder" *ngFor="let ventaja of clubJSON.ventajas">
          <h2 class="font-weight-bolder">{{ventaja.titulo}}</h2>
          <p>{{ventaja.texto}}</p>
        </li>
      </ul>
    </div>
  </div>
</section>
