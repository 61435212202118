<div class="row search">
  <div class="col-md-6">
    <form id="productsSortForm" class="form-horizontal pull-left hidden-xs">
      <div class="select">
        <select id="selectProductSort" class="selectProductSort form-control">
          <option value="position:desc" selected="selected">Ordenar por</option>
          <option value="price:asc">Precio: más baratos primero</option>
          <option value="price:desc">Precio: más caros primero</option>
          <option value="name:asc">Nombre: de A a Z</option>
          <option value="name:desc">Nombre: de Z a A</option>
        </select>
      </div>
    </form>
  </div>
</div>
