<div style="min-height: 100vh">
  <div class="container" *ngIf="politicaJSON != undefined">
    <div *ngIf="politicaJSON.textoDevolucion != ''">
      <h3 class="pt-4 mb-3"><a routerLink="/inicio"><i class="fas fa-arrow-left"></i> Volver</a></h3>
      <h1><b>Política de devolución y envío</b></h1>

      <h2 *ngIf="politicaJSON.tituloDevolucion"><i><u>{{politicaJSON.tituloDevolucion}}</u></i></h2>
      <h4 *ngIf="politicaJSON.parrafoNegritaDevolucion"><b>{{politicaJSON.parrafoNegritaDevolucion}}</b></h4>
      <p *ngFor="let texto of politicaJSON.textoDevolucion">
        {{texto}}
      </p>
    </div>

    <div *ngIf="politicaJSON.textoReparto != ''">
      <h2 *ngIf="politicaJSON.tituloReparto"><i><u>{{politicaJSON.tituloReparto}}</u></i></h2>
      <h4 *ngIf="politicaJSON.parrafoNegritaReparto"><b>{{politicaJSON.parrafoNegritaReparto}}</b></h4>
      <p *ngFor="let texto of politicaJSON.textoReparto">
        {{texto}}
      </p>
    </div>
  </div>
</div>


