import { Injectable } from '@angular/core';
import {CanActivate, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {ExceptionsService} from '../services/exceptions.service';
import {map} from 'rxjs/operators';
import {Exception} from '../interfaces/exception';
import {AuthStorageService} from '../services/auth/auth-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UseRoutesGuard implements CanActivate {
  constructor(private router: Router, private exceptionsService: ExceptionsService,
              private authStorageService: AuthStorageService) {}

  canActivate(): Observable<boolean> | boolean | Observable<UrlTree> | UrlTree {
    return this.exceptionsService.UseRoutes()
      .pipe(
        // Si la petición es exitosa se puede proceder
        map((data: Exception) => {
          {
            if (data.value === true) {
              return true;
            } else {
              this.router.navigate(['/']);
              return false;
            }
          }
        })
      );
  }
}
