import {PriceRoute} from "./price-route";

export interface UserInfo {
  name: string;
  country: string;
  countryName: string;
  province: string;
  provinceName: string;
  city: string;
  cityName: string;
  direction: string;
  phone: string;
  phone2: string;
  email: string;
  birthday: string;
  observations: string;
  paymentMethod: string;
  isInRoute: number;
  deliveryDays: string;
  priceRoute: PriceRoute;
  schedule: string;
  descuento: number;
  pendientePago: number;
}

export class Credentials {
  id: string;
  password: string;

  constructor(id: string, password: string) {
    this.id = id;
    this.password = password;
  }
}

export interface JwtToken {
  token: string;
  datetime: string;
}
