import { Component, OnInit } from '@angular/core';
import {WebEstaticaService} from '../../../services/web-estatica/web-estatica.service';
import {AcercaDe} from '../../../interfaces/web-estatica/acerca-de';

@Component({
  selector: 'app-acerca-de',
  templateUrl: './acerca-de.component.html',
  styleUrls: ['./acerca-de.component.scss']
})
export class AcercaDeComponent implements OnInit {

  constructor(private webEstaticaService: WebEstaticaService) { }

  acercaDeJSON: AcercaDe | undefined;
  ngOnInit(): void {
    this.webEstaticaService.getAcercaDeJSON().subscribe(data => {
      this.acercaDeJSON = data;
    });
  }

}
