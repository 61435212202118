import {Component, HostListener, OnInit} from '@angular/core';
import {WebEstaticaService} from '../../../services/web-estatica/web-estatica.service';
import {AcercaDe} from '../../../interfaces/web-estatica/acerca-de';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-encuesta',
  templateUrl: './encuesta.component.html',
  styleUrls: ['./encuesta.component.scss']
})
export class EncuestaComponent implements OnInit {

  constructor(private webEstaticaService: WebEstaticaService, protected _sanitizer: DomSanitizer) { }

  encuesta: any | undefined;
  width = 700;
  ngOnInit(): void {
    if (window.innerWidth < 700) {
      this.width = window.innerWidth - 20;
    }
    this.webEstaticaService.getEncuestaJSON().subscribe(data => {
      this.encuesta = data;
    });
  }

  @HostListener('window:resize')
  onResize() {
    if (window.innerWidth < 700) {
      this.width = window.innerWidth - 20;
    }
  }
}
