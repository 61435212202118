import { Component, OnInit } from '@angular/core';
import {WebEstaticaService} from '../../../services/web-estatica/web-estatica.service';
import {AcercaDe} from '../../../interfaces/web-estatica/acerca-de';
import {Club} from '../../../interfaces/web-estatica/club';

@Component({
  selector: 'app-club',
  templateUrl: './club.component.html',
  styleUrls: ['./club.component.scss']
})
export class ClubComponent implements OnInit {

  constructor(private webEstaticaService: WebEstaticaService) { }

  clubJSON: Club | undefined;
  ngOnInit(): void {
    this.webEstaticaService.getClubJSON().subscribe(data => {
      this.clubJSON = data;
    });
  }

}
