import { Component, OnInit } from '@angular/core';
import {WebEstaticaService} from '../../../services/web-estatica/web-estatica.service';
import {Contacto} from '../../../interfaces/web-estatica/contacto';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {EmailService} from '../../../services/web-estatica/email.service';
import {Email} from '../../../interfaces/email';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-atencion-al-cliente',
  templateUrl: './atencion-al-cliente.component.html',
  styleUrls: ['./atencion-al-cliente.component.scss']
})
export class AtencionAlClienteComponent implements OnInit {

  constructor(private webEstaticaService: WebEstaticaService, private emailService: EmailService, private route: ActivatedRoute) { }

  contactoJSON: undefined | Contacto;


  successMessage: String | undefined;
  errorMessage: String | undefined;
  contactForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    telephone: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{9}$')]),
    email: new FormControl('', [Validators.required, Validators.email]),
    message: new FormControl('', [Validators.required]),
    accept: new FormControl(false, [Validators.required]),
  });

  ngOnInit(): void {
    this.webEstaticaService.getContactoJSON().subscribe(data => {
      this.contactoJSON = data;
    });

    this.route.fragment.subscribe(f => {
      const element = document.querySelector("#" + f)
      if (element) element.scrollIntoView()
    })
  }

  sendMail() {
    this.emailService.sendMail(
      new Email(this.contactForm.value.name, this.contactForm.value.telephone, this.contactForm.value.email, this.contactForm.value.message)
    ).subscribe(
      () => {
        this.errorMessage = undefined;
        this.successMessage = 'Correo electrónico recibido con éxito. Nos pondremos en contacto lo antes posible.';
      },
      () => {
        this.successMessage = undefined;
        this.errorMessage = "Ha habido un problema. Su correo no ha podido ser enviado.";
      },
    )
  }

}
