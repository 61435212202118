<div *ngIf="showOrder && articles.length > 0">
  <h3 class="text-info font-weight-bold container-fluid"
      *ngIf="showReturn && processOrderNav"
      (click)="showOrder = false"><i class="fas fa-arrow-left"></i> VOLVER</h3>
  <div *ngIf="countries != undefined; else cargando">
    <app-order [userInfo]="userInfo" [articles]="articles" [totalPrice]="totalIvaPrice"
               [companyConf]="companyConf" [totalTransport]="totalTransport"
               (finishOrder)="finishOrder()" [countries]="countries" [minPrice]="minPrice"
               (returnShoppingCar)="reloadData()" (getUserData)="getUserDataFn()"
               (setHomeDelivery)="setHomeDelivery($event)"
               [superGroups]="superGroups"
               [selectedOrderSuperGroup]="selectedOrderSuperGroup"
               [usuMod]="usuMod" [newUser]="newUser" [homeDelivery]="homeDelivery"></app-order>
  </div>

  <ng-template #cargando>
    <div class="align-items-center d-flex justify-content-center container-load">
      <div>
        <div class="w-100 d-flex justify-content-center mb-3">
          <img src="assets/loading/loading.gif" alt="Cargando icono" style="height: 150px !important; width: auto !important;"/>
        </div>
        <div class="text-center">
          <h3>Estamos cargando todos los datos</h3>
          <p style="font-size: 14px">Espere un momento</p>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<div *ngIf="!showOrder || articles.length == 0" class="computer">
  <div class="row">
    <div class="col-md-10 col-12 mx-auto">
      <div class="w-100 text-center">
        <h1 class="text-dark title">Carrito</h1>
      </div>

      <div *ngIf="articles.length === 0 && someArticles"
           class="align-items-center d-flex justify-content-center container-load">
        <div>
          <div class="w-100 d-flex justify-content-center mb-3">
            <img src="assets/loading/loading.gif" alt="Cargando icono" style="height: 150px !important; width: auto !important;"/>
          </div>
          <div class="text-center">
            <h3>Estamos cargando todos los datos</h3>
            <p style="font-size: 14px">Espere un momento</p>
          </div>
        </div>
      </div>

      <div *ngIf="!someArticles">
        <div class="w-100 bg-info-light mb-3">
          <h5 class="ml-3">No hay articulos en este momento en su carrito.</h5>
        </div>
      </div>

      <div *ngFor="let article of articles | paginate: {itemsPerPage: numberArticlesPage, currentPage: pageArticles}; let i = index">
        <div class="container-article"> <!--(click)="getArticle(article)">-->
          <div class="row w-100">
            <div class="col-md-7">
              <div class="d-flex mid">
                <div class="container-img" *ngIf="(article.articleImgUrl || article.productImgUrl)">
                  <img *ngIf="article.articleImgUrl; else productImg"
                       src="./assets/fotos/articulos/{{article.articleImgUrl}}" alt="{{article.name}}"/>
                  <ng-template #productImg>
                    <img *ngIf="article.productImgUrl"
                         src="./assets/fotos/productos/{{article.productImgUrl}}" alt="{{article.name}}"/>
                  </ng-template>
                </div>

                <div id="name-info">
                  <h4 class="text-dark font-weight-bold" id="name">
                    <span *ngIf="article.nameClient != ''">{{article.nameClient}}</span>
                    <span class="text-danger ml-3" *ngIf="article.stock <= 0 && companyConf.showStock"
                          (click)="openStock($event, STOCK)">
                    Sin stock
                    <i class="fas fa-question-circle ml-1"></i>
                  </span>
                  </h4>

                  <label class="mr-4 mt-0" *ngIf="article.dropDownGauge && article.gaugeName">
                    Tamaño: <b>{{article.gaugeName}}</b>
                  </label>

                  <div>
                    <label class="mr-4 mt-0" *ngIf="article.cutName">
                      <mat-form-field class="w-100">
                        <mat-label>Tipo de corte o presentación</mat-label>
                        <mat-select class="w-100"
                                    (ngModelChange)="setCutType(article, $event)" [(ngModel)]="article.cutType">
                          <mat-option *ngFor="let cut of article.dropDownCuts"
                                      [disabled]="existSameArticle(article.id, cut.id, article.line)"
                                      [value]="cut.id">{{cut.name}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </label>
                  </div>

                  <div *ngIf="article.observations" class="break-line">
                    <button type="button" class="btn btn-info text-black  btn-observation"
                            (click)="openObservation($event, article.id, article.line, setObservation)">
                      <i class="fas fa-pen" ></i>
                    </button>
                    Observaciones:<b> {{article.observations}}</b>
                  </div>

                  <div *ngIf="!article.observations">
                    <button type="button" class="btn btn-info text-black  btn-observation"
                            (click)="openObservation($event, article.id, article.line, setObservation)">
                      <i class="fas fa-pen"></i> Observaciones
                    </button>
                  </div>

                  <ng-template #setObservation let-modal class="modal">
                    <div class="modal-body">
                      <div class="w-100 d-flex justify-content-end">
                        <div class="text-danger" (click)="modal.close()">
                          <i class="fas fa-times-circle fa-2x"></i>
                        </div>
                      </div>
                      <form [formGroup]="observationsForm" novalidate>
                        <div class="form-group">
                          <label class="font-weight-bold w-100">Observaciones<br/>
                            <span class="font-weight-light">***No todas las observaciones se podrán llevar a cabo**</span>
                            <textarea class="form-control" formControlName="observations"
                                      [placeholder]="companyConf.observationsText"></textarea>
                          </label>
                          <div *ngIf="observationsForm.controls.observations.touched &&
                                      !observationsForm.valid "
                               class="error">Tamaño máximo de 60 caracteres.
                          </div>
                        </div>
                      </form>

                      <div class="d-flex justify-content-center">
                        <button type="submit" class="btn btn-danger btn-modal cancel-modal"
                                (click)="modal.close()">CANCELAR</button>
                        <button type="submit" class="btn btn-success btn-modal" [disabled]="!observationsForm.valid"
                                (click)="setArticleObservations(modal, article.id, article.line)">ACEPTAR</button>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>

            <div class="col-md-5">
              <div class="d-flex justify-content-end">
                <div class="quantity-delete d-flex">
                  <label class="mr-4 d-flex align-items-end" *ngIf="article.showPriceQuantityInfo">
                    <div class="d-flex align-items-end">
                      <div class="mr-4">
                        <b> {{article.priceGauge | currency: 'EUR'}} €/Ud</b>
                      </div>

                      <input type="number" class="bg-light quantity-input mr-1"
                             [(ngModel)]="article.quantityGauge"
                             (click)="stopPropagation($event)"
                             (keyup)="setQuantity(article)"/> Ud
                    </div>
                  </label>

                  <label class="mr-4" *ngIf="!article.showPriceQuantityInfo">
                    <div class="d-flex align-items-center">
                      <div class="mr-4">
                        <b>
                          {{article.priceIva  | currency : 'EUR'}}
                          <span *ngIf="article.format === '1'" class="format-width">/Kg</span>
                          <span *ngIf="article.format !== '1'" class="format-width">/Ud</span>
                        </b>
                      </div>

                      <!-- Cantidad en unidades -->
                      <div *ngIf="article.format !== '1'" class="mt-4">
                        <div *ngIf="!companyConf.selectQuantity; else elseSelect">
                          <mat-form-field appearance="legacy" style="max-width: 100px !important;">
                            <mat-label>Cantidad</mat-label>
                            <input type="number" class="mr-1"
                                    matInput
                                   [(ngModel)]="article.quantity"
                                   (click)="stopPropagation($event)"
                                   (ngModelChange)="setQuantity(article)">
                          </mat-form-field>
                        </div>

                        <ng-template #elseSelect>
                          <select class="quantity-select browser-default custom-select p-0 mb-0 mr-1"
                                  [(ngModel)]="article.quantity"
                                  (click)="stopPropagation($event)"
                                  (change)="setQuantity(article)">
                            <option [value]="unit" *ngFor="let unit of selectUnits">
                              &nbsp;{{unit}}
                            </option>
                          </select>
                        </ng-template>
                      </div>

                      <!-- Cantidad en Kilos -->
                      <div *ngIf="article.format === '1'" class="mt-3">
                        <div *ngIf="!companyConf.selectQuantity; else elseSelect">
                          <mat-form-field appearance="legacy" style="max-width: 100px !important;">
                            <mat-label>Cantidad</mat-label>
                            <input type="number" class="mr-1"
                                   matInput
                                   [(ngModel)]="article.quantity"
                                   (click)="stopPropagation($event)"
                                   (ngModelChange)="setQuantity(article)">
                          </mat-form-field>
                        </div>

                        <ng-template #elseSelect>
                          <select class="quantity-select browser-default custom-select p-0 mb-0 mr-1"
                                  [(ngModel)]="article.quantity"
                                  (click)="stopPropagation($event)"
                                  (change)="setQuantity(article)">
                            <option [value]="kg" *ngFor="let kg of selectKgs">
                              &nbsp;{{kg | number : '1.2-2'}}
                            </option>
                          </select>
                        </ng-template>
                      </div>
                    </div>

                    <div *ngIf="article.lumps > 0 && companyConf.companyType === 'P'">
                      <div class="d-flex justify-content-end mt-2 mb-2">
                        <div class="btn btn-dark text-center" style="width: 2.5em; height: calc(1.5em + 0.75rem + 2px);"
                             (click)="stopPropagation($event); addValue(article)"> + </div>
                        <div class="form-group ml-2 mr-2" style="width: 3.75em;">
                          <span class="w-100 form-control text-center"> {{article.lumps}} </span>
                        </div>
                        <div class="btn btn-dark text-center" style="width: 2.5em; height: calc(1.5em + 0.75rem + 2px);"
                             (click)="stopPropagation($event); subtractValue(article)"> - </div>
                      </div>
                    </div>
                  </label>

                  <button type="button" class="btn btn-outline-danger  mt-3"
                          (click)="removeArticle(article.id, article.line, article.cutType)">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </div>
              </div>

              <div class="align-bottom mr-2">
                <b>Precio total: </b> {{article.totalPriceIva | currency: 'EUR'}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="articles" id="pagination-container">
        <b>
          <pagination-controls class="pagination justify-content-end"
                               (pageChange)="pageChanged($event)"></pagination-controls>
        </b>
      </div>

      <div class="d-flex justify-content-end" id="info-car" *ngIf="articles != undefined">
        <div class="item-info">
          <b>
            <span class="text-danger">{{articles.length}} </span>
            <span *ngIf="articles.length === 1">ARTÍCULO</span>
            <span *ngIf="articles.length != 1">ARTÍCULOS</span>
          </b>
        </div>

        <div  class="item-info">
          <div *ngIf="(totalIvaPrice >= minPrice && (totalTransport == 0 || totalTransport == undefined))">
            <b>
              <div *ngIf="homeDelivery; else pickUpStore">
                Entrega <span class="text-danger">GRATUITA</span><br/>
              </div>
              <ng-template #pickUpStore>
                RECOGIDA EN TIENDA
              </ng-template>
            </b>
          </div>

          <div class="item-info" *ngIf="totalIvaPrice < minPrice && (totalTransport == 0 || totalTransport == undefined)">
            <b *ngIf="missingPrice">
              Entrega no disponible:
              <span class="text-danger"> Faltan {{(missingPrice | currency: 'EUR')}}
            </span><br/>
            </b>
          </div>

          <div *ngIf="priceRoute">
            <div class="item-info" *ngIf="!(priceRoute.minKg == 0 && priceRoute.deliveryPrice == 0
                                      && priceRoute.deliveryKg == 0) && totalTransport > 0">
              <div class="font-weight-bold help" (click)="openModal(TRANSPORT)">
                TRANSPORTE <i class="far fa-question-circle text-info"></i>:
                <div class="font-weight-bold"
                      [style]="'color: ' + companyConf.priceColor + ';'">
                  {{totalTransport  | currency: 'EUR'}}
                </div>

                <div *ngIf="!validPriceRoute">
                  Peso mínimo: {{priceRoute.minKg}}Kg<br/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="item-info" *ngIf="userDiscount != 0">
          <b>
            Descuento
            <span class="text-danger"> {{userDiscount}} % </span>
          </b>
        </div>

        <div class="item-info" *ngIf="pendientePago < 0">
          <b>
            Descuento acumulado
            <span class="text-danger"> {{pendientePago  | currency: 'EUR'}}</span>
          </b>
        </div>

        <div>
          <b>
            TOTAL (impuestos inc.)
            <span [ngClass]="{'font-weight-bold': companyConf.boldPrice}"
                  [style]="'color: ' + companyConf.priceColor + ';'" *ngIf="totalIvaPrice">
              {{(totalIvaPrice | currency: 'EUR')}}
            </span>
          </b>
        </div>
      </div>

      <div class="row" id="buttons">
        <div class="col-md-6 d-flex justify-content-start">
          <a type="button" class="btn btn-dark " [routerLink]="defaultRoute">
            CONTINUAR COMPRANDO
          </a>
        </div>

        <div class="col-md-6 d-flex flex-row-reverse">
          <button type="button" class="btn btn-primary text-light"
                  *ngIf="priceRoute.minKg == 0 && priceRoute.deliveryPrice == 0 && priceRoute.deliveryKg == 0; else elseBtn"
                  [disabled]="articles == null || totalIvaPrice < minPrice || !anyArticleNull || articles.length == 0"
                  (click)="scrollTop(); showOrder = true; processOrderNav = true;">
            TRAMITAR PEDIDO
          </button>

          <ng-template #elseBtn>
            <button type="button" class="btn btn-primary text-light"
                    [disabled]="articles == null || !anyArticleNull || articles.length == 0 || !validPriceRoute"
                    (click)="scrollTop(); showOrder = true;  processOrderNav = true;">
              TRAMITAR PEDIDO
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- dispositivos moviles -->
<div class="mobil w-100" *ngIf="!showOrder || articles.length == 0">
  <div *ngIf="articles" class="pl-2 pr-2">
    <div>
      <span class="text-danger font-weight-bold ">{{articles.length}} </span>
      <span *ngIf="articles.length === 1">Artículo</span>
      <span *ngIf="articles.length != 1">Artículos</span>
    </div>
    <div class="item-info font-weight-bold" *ngIf="totalPrice >= minPrice && (totalTransport == 0 || totalTransport == undefined)">
      <div *ngIf="homeDelivery; else pickUpStore">
        Entrega <span class="text-danger">GRATUITA</span><br/>
      </div>
      <ng-template #pickUpStore>
        RECOGIDA EN TIENDA
      </ng-template>
    </div>

    <div class="item-info" *ngIf="totalIvaPrice < minPrice && (totalTransport == 0 || totalTransport == undefined)">
      <b>
        Entrega no disponible:
        <span class="text-danger font-weight-bold"> Faltan {{(missingPrice  | currency: 'EUR')}}
        </span><br/>
      </b>
    </div>

    <div class="item-info" *ngIf="!(priceRoute.minKg == 0 && priceRoute.deliveryPrice == 0
                                  && priceRoute.deliveryKg == 0) && totalTransport > 0">
      <span class="help" (click)="openModal(TRANSPORT)">
        TRANSPORTE <i class="far fa-question-circle text-info"></i>:
        <span class="font-weight-bold"
              [style]="'color: ' + companyConf.priceColor + ';'">
          {{(totalTransport  | currency: 'EUR')}}
        </span><br/>

        <span *ngIf="!validPriceRoute">
          Peso mínimo: {{priceRoute.minKg}}Kg<br/>
        </span>
      </span>
    </div>

    <div class="item-info" *ngIf="userDiscount != 0">
      Descuento
      <span class="text-danger font-weight-bold"> {{userDiscount}} % </span>
    </div>

    <div class="item-info" *ngIf="pendientePago < 0">
      Descuento acumulado
      <span class="text-danger font-weight-bold"> {{pendientePago | currency: 'EUR'}} </span>
    </div>

    <div>
      TOTAL (impuestos inc.)
      <span class="font-weight-bold"
            [style]="'color: ' + companyConf.priceColor + ';'" *ngIf="totalIvaPrice">
        {{(totalIvaPrice | currency: 'EUR')}}
      </span>
    </div>
  </div>

  <div class="w-100 sticky-top mt-3">
    <div class="w-100 d-flex justify-content-center">
      <button type="button" class="btn btn-dark btn-mobil-prin" [routerLink]="defaultRoute">
        CONTINUAR COMPRANDO
      </button>
    </div>
  </div>

  <div class="w-100 mobil-principal sticky-top">
    <div class="w-100 d-flex justify-content-center">
      <button type="button" class="btn btn-primary text-light btn-mobil-prin mt-2 mb-2"
              *ngIf="priceRoute.minKg == 0 && priceRoute.deliveryPrice == 0 && priceRoute.deliveryKg == 0; else elseBtn4"
              [disabled]="articles == null || totalIvaPrice < minPrice || !anyArticleNull || articles.length == 0"
              (click)="scrollTop(); showOrder = true; processOrderNav = true;">
        TRAMITAR PEDIDO
      </button>

      <ng-template #elseBtn4>
        <button type="button" class="btn btn-primary text-light btn-mobil-prin mt-2 mb-2"
                [disabled]="articles == null || !anyArticleNull || articles.length == 0 || !validPriceRoute"
                (click)="scrollTop(); showOrder = true; processOrderNav = true;">
          TRAMITAR PEDIDO
        </button>
      </ng-template>
    </div>
  </div>

  <div *ngFor="let article of articles  | paginate: {itemsPerPage: numberArticlesPage, currentPage: pageArticles}">
    <div class="container-article">
      <div class="w-100 d-flex justify-content-center">
        <div class="d-flex" *ngIf="article.articleImgUrl || article.productImgUrl">
          <div class="container-img">
            <img *ngIf="article.articleImgUrl; else productImg"
                 src="./assets/fotos/articulos/{{article.articleImgUrl}}"  alt="{{article.name}}"/>
            <ng-template #productImg>
              <img *ngIf="article.productImgUrl"
                   src="./assets/fotos/productos/{{article.productImgUrl}}"  alt="{{article.name}}"/>
            </ng-template>
          </div>
        </div>

        <div class="mt-2">
          <div class="row">
            <div class="col-md-6">
              <div class="text-dark font-weight-bold mobil-title">
                <span *ngIf="article.nameClient != ''">{{article.nameClient}}</span>
                 </div>
              <div>
                <span class="text-black-50" *ngIf="article.stock <= 0 && companyConf.showStock"
                      (click)="openStock($event, STOCK)">
                  Sin stock
                  <i class="fas fa-question-circle ml-1"></i>
                </span>
              </div>
              <span class="margin-right"
                    [style]="'color: ' + companyConf.priceColor + ';'"
                    *ngIf="!article.showPriceQuantityInfo">
                  {{article.priceIva | currency: 'EUR'}}
                  <span *ngIf="article.format === '1'" class="format-width">/Kg</span>
                  <span *ngIf="article.format !== '1'" class="format-width">/Ud</span>
              </span>

              <div>
                <b>Precio total: </b> {{article.totalPriceIva | currency: 'EUR'}}
              </div>

              <span class="margin-right"
                    [ngClass]="{'font-weight-bold': companyConf.boldPrice}"
                    [style]="'color: ' + companyConf.priceColor + ';'"
                    *ngIf="article.showPriceQuantityInfo">
                <b>
                  <span>{{article.priceGauge  | currency: 'EUR'}} €/Ud
                  </span>
                </b>
              </span><br/>
            </div>

            <div class="col-md-6">
              <label *ngIf="!(article.productImgUrl || article.articleImgUrl) && (article.showPriceQuantityInfo)">
                <span  style="font-size: 0.875em;" *ngIf="article.dropDownGauge && article.gaugeName">Tamaño: <b>{{article.gaugeName}}</b></span>
                <div>
                <span style="font-size: 0.875em;" *ngIf="article.cutName">
                  <mat-form-field class="w-100">
                    <mat-label>Tipo de corte o presentación</mat-label>
                    <mat-select class="w-100"
                                (ngModelChange)="setCutType(article, $event)" [(ngModel)]="article.cutType">
                      <mat-option *ngFor="let cut of article.dropDownCuts"
                                  [disabled]="existSameArticle(article.id, cut.id, article.line)"
                                  [value]="cut.id">{{cut.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </span>
                </div>

                <div class="d-flex align-items-end">
                  <input type="number" class="bg-light quantity-input mr-1"
                         [(ngModel)]="article.quantityGauge"
                         (click)="stopPropagation($event)"
                         (keyup)="setQuantity(article)"/> Ud
                </div>
              </label>
              <label class="w-100 " *ngIf="!(article.productImgUrl || article.articleImgUrl) && !(article.showPriceQuantityInfo)">
                <div>
                <span style="font-size: 0.875em;" *ngIf="article.cutName">
                  <mat-form-field class="w-100">
                    <mat-label>Tipo de corte o presentación</mat-label>
                    <mat-select class="w-100"
                                (ngModelChange)="setCutType(article, $event)" [(ngModel)]="article.cutType">
                      <mat-option *ngFor="let cut of article.dropDownCuts"
                                  [disabled]="existSameArticle(article.id, cut.id, article.line)"
                                  [value]="cut.id">{{cut.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </span>
                </div>

                <div class="w-100 row">
                  <!-- Cantidad en unidades -->
                  <div class="col-12" *ngIf="article.format !== '1'">
                    <div *ngIf="!companyConf.selectQuantity; else elseSelect">
                      <mat-form-field appearance="legacy" class="w-100">
                        <mat-label>Cantidad</mat-label>
                        <input type="number" class="mr-1"
                               matInput
                               [(ngModel)]="article.quantity"
                               (click)="stopPropagation($event)"
                               (ngModelChange)="setQuantity(article)">
                      </mat-form-field>
                    </div>

                    <ng-template #elseSelect>
                      <select class="quantity-select browser-default custom-select p-0 mb-0 mr-1"
                              [(ngModel)]="article.quantity"
                              (click)="stopPropagation($event)"
                              (change)="setQuantity(article)">
                        <option [value]="unit" *ngFor="let unit of selectUnits">
                          &nbsp;{{unit}}
                        </option>
                      </select>
                    </ng-template>
                  </div>

                  <!-- Cantidad en Kilos -->
                  <div *ngIf="article.format === '1'"  class="col-12">
                    <div *ngIf="!companyConf.selectQuantity; else elseSelect">
                      <mat-form-field appearance="legacy" class="w-100">
                        <mat-label>Cantidad</mat-label>
                        <input type="number" class="mr-1"
                               matInput
                               [(ngModel)]="article.quantity"
                               (click)="stopPropagation($event)"
                               (ngModelChange)="setQuantity(article)">
                      </mat-form-field>
                    </div>

                    <ng-template #elseSelect>
                      <select class="quantity-select browser-default custom-select p-0 mb-0 mr-1"
                              [(ngModel)]="article.quantity"
                              (click)="stopPropagation($event)"
                              (change)="setQuantity(article)">
                        <option [value]="kg" *ngFor="let kg of selectKgs">
                          <span class="ml-1">&nbsp;{{kg | number : '1.2-2'}}</span>
                        </option>
                      </select>
                    </ng-template>
                  </div>
                </div>

                <div *ngIf="article.lumps > 0 && companyConf.companyType === 'P'">
                  <div class="d-flex mt-2">
                    <div class="btn btn-dark text-center" style="width: 2.5em; height: calc(1.5em + 0.75rem + 2px);"
                         (click)="stopPropagation($event); addValue(article)"> + </div>
                    <div class="form-group ml-2 mr-2" style="width: 3.75em;">
                      <span class="w-100 form-control text-center"> {{article.lumps}} </span>
                    </div>
                    <div class="btn btn-dark text-center" style="width: 2.5em; height: calc(1.5em + 0.75rem + 2px);"
                         (click)="stopPropagation($event); subtractValue(article)"> - </div>
                  </div>
                </div>
              </label>
            </div>
          </div>

          <label class="mr-4" *ngIf="(article.productImgUrl || article.articleImgUrl) && (article.showPriceQuantityInfo)">
            <span  style="font-size: 0.875em;" *ngIf="article.dropDownGauge && article.gaugeName">Tamaño: <b>{{article.gaugeName}}</b></span>
            <div>
              <span style="font-size: 0.875em;" *ngIf="article.cutName">
                 <mat-form-field class="w-100">
                    <mat-label>Tipo de corte o presentación</mat-label>
                    <mat-select class="w-100"
                                (ngModelChange)="setCutType(article, $event)" [(ngModel)]="article.cutType">
                      <mat-option *ngFor="let cut of article.dropDownCuts"
                                  [disabled]="existSameArticle(article.id, cut.id, article.line)"
                                  [value]="cut.id">{{cut.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
              </span>
            </div>

            <div class="d-flex align-items-end">
              <input type="number" class="bg-light quantity-input mr-1"
                     [(ngModel)]="article.quantityGauge"
                     (click)="stopPropagation($event)"
                     (keyup)="setQuantity(article)"/> Ud
            </div>
          </label>
          <label class="mr-4" *ngIf="(article.productImgUrl || article.articleImgUrl) && !article.showPriceQuantityInfo">
            <span  style="font-size: 0.875em;" *ngIf="article.dropDownGauge && article.gaugeName">Tamaño: <b>{{article.gaugeName}}</b></span>
            <div>
                <span style="font-size: 0.875em;" *ngIf="article.cutName">
                  <mat-form-field class="w-100">
                    <mat-label>Tipo de corte o presentación</mat-label>
                    <mat-select class="w-100"
                                (ngModelChange)="setCutType(article, $event)" [(ngModel)]="article.cutType">
                      <mat-option *ngFor="let cut of article.dropDownCuts" [value]="cut.id"
                                  [disabled]="existSameArticle(article.id, cut.id, article.line)">{{cut.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </span>
            </div>
            <div class="d-flex align-items-end">
              <!-- Cantidad en unidades -->
              <div *ngIf="article.format !== '1'" class="mt-3">
                <div *ngIf="companyConf.selectQuantity; else elseSelect">
                  <mat-form-field appearance="legacy">
                    <mat-label>Cantidad</mat-label>
                    <input type="number" class="mr-1"
                           matInput
                           [(ngModel)]="article.quantity"
                           (click)="stopPropagation($event)"
                           (ngModelChange)="setQuantity(article)">
                  </mat-form-field>
                </div>

                <ng-template #elseSelect>
                  <select class="quantity-select browser-default custom-select p-0 mb-0 mr-1"
                          [(ngModel)]="article.quantity"
                          (click)="stopPropagation($event)"
                          (change)="setQuantity(article)">
                    <option [value]="unit" *ngFor="let unit of selectUnits">
                      &nbsp;{{unit}}
                    </option>
                  </select>
                </ng-template>
              </div>

              <!-- Cantidad en Kilos -->
              <div *ngIf="article.format === '1'" class="mt-3">
                <div *ngIf="!companyConf.selectQuantity; else elseSelect">
                  <mat-form-field appearance="legacy">
                    <mat-label>Cantidad</mat-label>
                    <input type="number" class="mr-1"
                           matInput
                           [(ngModel)]="article.quantity"
                           (click)="stopPropagation($event)"
                           (ngModelChange)="setQuantity(article)">
                  </mat-form-field>
                </div>

                <ng-template #elseSelect>
                  <select class="quantity-select browser-default custom-select p-0 mb-0 mr-1"
                          [(ngModel)]="article.quantity"
                          (click)="stopPropagation($event)"
                          (change)="setQuantity(article)">
                    <option [value]="kg" *ngFor="let kg of selectKgs">
                      <span class="ml-1">&nbsp;{{kg | number : '1.2-2'}}</span>
                    </option>
                  </select>
                </ng-template>
              </div>
            </div>
            <div *ngIf="article.lumps > 0 && companyConf.companyType === 'P'">
              <div class="d-flex mt-2">
                <div class="btn btn-dark text-center" style="width: 2.5em; height: calc(1.5em + 0.75rem + 2px);"
                     (click)="stopPropagation($event); addValue(article)"> + </div>
                <div class="form-group ml-2 mr-2" style="width: 3.75em;">
                  <span class="w-100 form-control text-center"> {{article.lumps}} </span>
                </div>
                <div class="btn btn-dark text-center" style="width: 2.5em; height: calc(1.5em + 0.75rem + 2px);"
                     (click)="stopPropagation($event); subtractValue(article)"> - </div>
              </div>
            </div>
          </label>

          <div class="w-100 d-flex justify-content-center mb-3">
            <div *ngIf="article.observations">
              <button type="button" class="btn btn-info  btn-observaciones"
                      (click)="openObservation($event, article.id, article.line, setObservation)">
                Observaciones
              </button>
            </div>

            <div *ngIf="!article.observations">
              <button type="button" class="btn btn-primary text-light btn-observaciones"
                      (click)="openObservation($event, article.id, article.line, setObservation)">
                Observaciones
              </button>
            </div>

            <ng-template #setObservation let-modal class="modal">
              <div class="modal-body">
                <div class="w-100 d-flex justify-content-end">
                  <div class="text-danger" (click)="modal.close()">
                    <i class="fas fa-times-circle fa-2x"></i>
                  </div>
                </div>
                <form [formGroup]="observationsForm" novalidate>
                  <div class="form-group">
                    <label class="font-weight-bold w-100">Observaciones*
                      <textarea class="form-control" formControlName="observations"
                                [placeholder]="companyConf.observationsText"></textarea>
                    </label>
                    <div *ngIf="observationsForm.controls.observations.touched &&
                      !observationsForm.valid "
                         class="error">Tamaño máximo de 50 caracteres.
                    </div>
                  </div>
                </form>
                <div class="d-flex justify-content-center">
                  <button type="submit" class="btn btn-danger btn-modal cancel-modal"
                          (click)="modal.close()">CANCELAR</button>
                  <button type="submit" class="btn btn-success btn-modal" [disabled]="!observationsForm.valid"
                          (click)="setArticleObservations(modal, article.id, article.line)">ACEPTAR</button>
                </div>
              </div>
            </ng-template>


            <div class="ml-3">
              <button type="button" class="btn btn-outline-danger  btn-mobil"
                      (click)="removeArticle(article.id, article.line, article.cutType)">
                <i class="fa fa-trash" aria-hidden="true"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Warning company -->
<ng-template #STOCK let-modal class="modal">
  <div class="modal-body">
    <div class="container">
      <div class="d-flex mb-2">
        <div class="font-weight-bold mr-4" style="width: 90%;">
          <h1 class="text-info">INFORMACIÓN</h1>
        </div>
        <div class="d-flex justify-content-end"
             style="width: 10%">
          <a class="text-danger" (click)="modal.close()">
            <i class="fas fa-times-circle fa-2x"></i>
          </a>
        </div>
      </div>

      <div class="w-100 font-weight-bold" style="margin-bottom: 10px">
        <h3 class="text-dark font-weight-bold">
          Cuando un pedido tiene articulos sin stock, signifa que este se enviará a
          segunda hora.</h3>
      </div>

      <div class="d-flex justify-content-center">
        <button type="button" class="btn btn-info  btn-modal" (click)="modal.close()" >
          ACEPTAR
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- Info transport -->
<ng-template #TRANSPORT let-modal class="modal">
  <div class="modal-body">
    <div class="container">
      <div class="d-flex mb-2">
        <div class="font-weight-bold mr-4" style="width: 90%;">
          <h1 class="text-info">INFORMACIÓN</h1>
        </div>
        <div class="d-flex justify-content-end"
             style="width: 10%">
          <a class="text-danger" (click)="modal.close()">
            <i class="fas fa-times-circle fa-2x"></i>
          </a>
        </div>
      </div>

      <div class="w-100 font-weight-bold text-center mb-2">
        <h3 class="text-dark font-weight-bold" *ngIf="priceRoute.deliveryPrice != 0 &&
                                                      priceRoute.deliveryKg !=  0">
          El precio transporte es de
          {{(priceRoute.deliveryPrice  | currency: 'EUR')}}
          cada {{priceRoute.deliveryKg}}Kg.<br/>
          <span *ngIf="priceRoute.fixedPrice">Además el envío tiene unos cargos fijos de {{priceRoute.fixedPrice}}€</span>
        </h3>

        <h3 class="text-dark font-weight-bold" *ngIf="priceRoute.deliveryPrice != 0 &&
                                                      priceRoute.minKg !=  0">
          Por compras inferiores a {{priceRoute.minKg}}Kg no se realizan envíos. <br/>
          El precio de transporte es de
          {{(priceRoute.deliveryPrice  | currency: 'EUR')}}.<br/>
          <span *ngIf="priceRoute.fixedPrice">Además tiene unos cargos fijos de {{priceRoute.fixedPrice}}€</span>
        </h3>

        <h3 class="text-dark font-weight-bold" *ngIf="priceRoute.deliveryPrice != 0 ">
          El precio de transporte es de
          {{(priceRoute.deliveryPrice  | currency: 'EUR')}}.<br/>

          Si supera los {{this.minPrice | currency: 'EUR'}} ¡La entrega es gratuita!
        </h3>
      </div>

      <div class="d-flex justify-content-center">
        <button type="button" class="btn btn-info  btn-modal" (click)="modal.close()" >
          ACEPTAR
        </button>
      </div>
    </div>
  </div>
</ng-template>
