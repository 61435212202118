<h1 class="text-dark">Productos relacionados</h1>
<div class="scrolling-wrapper" #CONTENT>
  <div class="pointer h-100" style="float:left; margin-right: 15px;" *ngFor="let article of articles">
    <div [style]="'min-height:' + contentHeight + 'px !important;'" (click)="getNewArticle(article)">
      <div class="card mb-2 h-100" style="width: 135px" [style]="'min-height:' + contentHeight + 'px !important;'">
        <div id="container-img" *ngIf="article.articleImgUrl || article.productImgUrl">
          <img *ngIf="article.articleImgUrl; else productImg"
               class="card-img-top"
               src="./assets/fotos/articulos/{{article.articleImgUrl}}"  alt="{{article.name}}"/>
          <ng-template #productImg>
            <img *ngIf="article.productImgUrl"
                 class="card-img-top"
                 src="./assets/fotos/productos/{{article.productImgUrl}}"  alt="{{article.name}}"/>
          </ng-template>
        </div>

        <div class="card-body">
          <h4 class="card-title text-dark article-name">{{article.name}}</h4>
          <p class="card-text font-weight-bold" [style]="'color: ' + companyConf.priceColor + ';'"
             *ngIf="(article.priceIva > 0); else NO_PRICE">
            {{article.priceIvaStr | currency: 'EUR'}}
            <span *ngIf="article.format === '1'">/Kg</span>
            <span *ngIf="article.format !== '1'">/ud.</span>
          </p>

          <ng-template #NO_PRICE><p class="card-text text-white">a</p></ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
