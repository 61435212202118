  import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultContainerComponent } from './container/default-container/default-container.component';

// Import Containers
import {LoginComponent} from './@core/components/auth/login/login.component';
import {RegisterComponent} from './@core/components/auth/register/register.component';
import { E404Component } from './@core/components/errors/e404.component';
import { E500Component } from './@core/components/errors/e500.component';
import { ArticlesComponent } from './@core/components/index/articles/articles.component';
import { ArticleWindowComponent } from './@core/components/index/article-window/article-window.component';
import { SeeArticlesComponent } from './@core/components/shoppingCar/see-articles/see-articles.component';
import {EditProfileComponent} from './@core/components/auth/edit-profile/edit-profile.component';
import {DirectionsComponent} from './@core/components/auth/directions/directions.component';
import {NewDirectionComponent} from './@core/components/auth/new-direction/new-direction.component';
import { OrdersComponent } from './@core/components/auth/orders/orders.component';

import {UserGuard} from './@core/guards/user.guard';
import {NotUserGuard} from './@core/guards/not-user.guard';
import {SetPasswordComponent} from './@core/components/auth/set-password/set-password.component';
import {UseRoutesGuard} from './@core/guards/use-routes.guard';
import {ValidTpvGuard} from './@core/guards/valid-tpv.guard';
import {PrivacyPolicyComponent} from './@core/components/footer/privacy-policy/privacy-policy.component';
import {LegalNoticeComponent} from './@core/components/footer/legal-notice/legal-notice.component';
import {CookiesComponent} from './@core/components/footer/cookies/cookies.component';
import {ResetPasswordComponent} from './@core/components/auth/reset-password/reset-password.component';
import {ResetPasswordGuard} from './@core/guards/reset-password.guard';
import {TpvResultComponent} from './@core/components/tpv-result/tpv-result.component';
import {TpvBadResultComponent} from './@core/components/tpv-bad-result/tpv-bad-result.component';
import {InicioComponent} from './@core/components/pagina-estatica/inicio/inicio.component';
import {AcercaDeComponent} from './@core/components/pagina-estatica/acerca-de/acerca-de.component';
import {AtencionAlClienteComponent} from './@core/components/pagina-estatica/atencion-al-cliente/atencion-al-cliente.component';
  import {PoliticaDeDevolucionComponent} from './@core/components/footer/politica-de-devolucion/politica-de-devolucion.component';
  import {ClubComponent} from './@core/components/pagina-estatica/club/club.component';
  import {EncuestaComponent} from './@core/components/pagina-estatica/encuesta/encuesta.component';

  export const routes: Routes = [

  { path: 'iniciarSesion', component: LoginComponent, canActivate: [NotUserGuard], data: { title: 'Iniciar Sesión' } },
  { path: 'registrarse', component: RegisterComponent, data: { title: 'Registrarse' } },
  { path: 'nuevaClave/:randomUrl', component: ResetPasswordComponent, canActivate: [ResetPasswordGuard] },
  { path: '404', component: E404Component, data: { title: 'Page 404' } },
  { path: '500', component: E500Component, data: { title: 'Page 500' } },
  { path: 'politica-privacidad', component: PrivacyPolicyComponent },
  { path: 'aviso-legal', component: LegalNoticeComponent },
  { path: 'aviso-cookies', component: CookiesComponent},
  {
    path: '',
    component: DefaultContainerComponent,
    data: {
      title: 'Principal'
    },
      children: [
        {
          path: 'inicio',
          component: InicioComponent
        },
        {
          path: 'política-devolucion-y-envio',
          component: PoliticaDeDevolucionComponent
        },
        {
          path: 'acerca-de',
          component: AcercaDeComponent
        },
        {
          path: 'atencion-al-cliente',
          component: AtencionAlClienteComponent
        },
        {
          path: 'nuestro-club',
          component: ClubComponent
        },
        {
          path: 'compra',
          component: ArticlesComponent
        },
        {
          path: 'compra/ofertas',
          component: ArticlesComponent
        },
        {
          path: 'compra/buscar',
          component: ArticlesComponent
        },
        {
          path: 'compra/:group/:family',
          component: ArticlesComponent
        },
        {
          path: 'compra/:nombre/:group/:family/:article',
          component: ArticleWindowComponent
        },
        {
          path: 'carrito',
          component: SeeArticlesComponent
        },
        {
          path: 'encuesta',
          component: EncuestaComponent
        },

        // PROFILE SETTINGS
        {
          path: 'miPerfil',
          component: EditProfileComponent,
          canActivate: [UserGuard]
        },
        {
          path: 'miPerfil/nuevaContrasena',
          component: SetPasswordComponent,
          canActivate: [UserGuard]
        },
        {
          path: 'miPerfil/direcciones',
          component: DirectionsComponent,
          canActivate: [UserGuard, UseRoutesGuard]
        },
        {
          path: 'miPerfil/direcciones/nuevaDireccion',
          component: NewDirectionComponent,
          canActivate: [UserGuard]
        },
        {
          path: 'miPerfil/misPedidos',
          component: OrdersComponent,
          canActivate: [UserGuard]
        },
        {
          path: 'pagoRealizado/:id',
          component: TpvResultComponent,
          canActivate: [ValidTpvGuard],
          data: { title: 'Pago Aceptado'}
        },
        {
          path: 'pagoCorrecto',
          component: TpvResultComponent,
          data: { title: 'Pago Aceptado'}
        },
        {
          path: 'errorPago',
          component: TpvBadResultComponent,
          data: { title: 'Pago cancelado'}
        },
        {
          path: 'errorPago/:id',
          component: TpvBadResultComponent,
          canActivate: [ValidTpvGuard],
          data: { title: 'Pago Aceptado'}
        }
      ]
  },
  { path: '**', component: E404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
