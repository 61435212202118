<div class="center mb-3 mt-4 w-100" *ngIf="companyConf">
  <img [src]="companyConf.logo" class="img"/>
</div>

<div class="app-body mh-100">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div class="card p-2">
            <div class="card-body">
              <h3 class="card-title mb-4 mt-1 max mb-0 text-dark">
                <b>Crear una nueva contraseña</b>
              </h3>
              <form (ngSubmit)="setPasswordFn()" [formGroup]="passwordForm" novalidate>
                <div class="row">
                  <div class="col-sm-12">
                    <mat-form-field appearance="legacy" class="w-100">
                      <mat-label>Nueva Contraseña</mat-label>
                      <input [type]="hide ? 'password' : 'text'" class="w-100"
                             formControlName="password" matInput (keyup)="notSame = false">
                      <mat-icon matSuffix (click)="hide = !hide">
                        <span *ngIf="!hide; else elseHide"><i class="fas fa-eye-slash fa-xs"></i></span>
                        <ng-template #elseHide><i class="fas fa-eye fa-xs"></i></ng-template>
                      </mat-icon>
                      <div *ngIf="passwordForm.controls.password.touched && passwordForm.controls.password.errors?.required"
                           class="error">Campo obligatorio.
                      </div>
                      <div *ngIf="passwordForm.controls.password.touched && !passwordForm.controls.password.errors?.required
                        && (passwordForm.controls.password.errors?.pattern)"
                           class="error">La contraseña debe tener mínimo 6 caracteres, una minúscula y una mayúscula.
                      </div>
                      <div *ngIf="passwordForm.controls.password.touched &&
                                      !passwordForm.controls.password.errors?.required
                                      && passwordForm.controls.password.errors?.validLettersIncorrect"
                           class="error">La contraseña no puede contener el carácter '
                      </div>
                      <div *ngIf="passwordForm.controls.password.touched &&
                                        passwordForm.controls.password.errors?.notSamePasswordIncorrect &&
                                        !passwordForm.controls.password.errors?.required"
                           class="error">Su nueva contraseña no puede coincidir con su contraseña actual.
                      </div>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <mat-form-field appearance="legacy" class="w-100">
                      <mat-label>Repita la contraseña</mat-label>
                      <input class="w-100" [type]="hide2 ? 'password' : 'text'"
                             formControlName="password_confirmation" matInput (keyup)="notSame = false">
                      <mat-icon matSuffix (click)="hide2 = !hide2">
                        <span *ngIf="!hide2; else elseHide"><i class="fas fa-eye-slash fa-xs"></i></span>
                        <ng-template #elseHide><i class="fas fa-eye fa-xs"></i></ng-template>
                      </mat-icon>
                      <div *ngIf="passwordForm.controls.password_confirmation.touched &&
                                        passwordForm.controls.password_confirmation.errors?.required"
                           class="error">Campo obligatorio.
                      </div>
                      <div *ngIf="passwordForm.controls.password_confirmation.touched &&
                                        passwordForm.controls.password_confirmation.errors?.samePasswordIncorrect &&
                                        !passwordForm.controls.password_confirmation.errors?.required"
                           class="error">Las contraseñas no coinciden.
                      </div>
                    </mat-form-field>
                  </div>
                </div>

                <!-- Submit button -->
                <div class="div d-flex justify-content-center">
                  <button type="submit" class="btn btn-success"
                          [disabled]="!passwordForm.valid">Cambiar Contraseña</button>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>

<!-- Footer -->
<div *ngIf="companyConf">
  <app-footer-auth
                    [companyException]="companyConf.companyException"
                    [phone]="companyConf.phone" [mail]="companyConf.email" [alternativePhone]="companyConf.alternativePhone"></app-footer-auth>
</div>
