import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import {HashLocationStrategy, registerLocaleData} from '@angular/common';
import { DatePipe } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
// Import routing module
import {AppRoutingModule, routes} from './app.routing';

import { AppComponent } from './app.component';
import { LoginComponent } from './@core/components/auth/login/login.component';
import { RegisterComponent } from './@core/components/auth/register/register.component';
import { E404Component } from './@core/components/errors/e404.component';
import { E500Component } from './@core/components/errors/e500.component';
import { FooterAuthComponent } from './@core/components/auth/footer-auth/footer-auth.component';


// Data picker
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {RouterModule} from '@angular/router';
import { APP_INITIALIZER } from '@angular/core';
import {MatTableModule} from '@angular/material/table';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSortModule} from '@angular/material/sort';


import {NgxPaginationModule} from 'ngx-pagination'; // <-- import the module

import { DefaultContainerComponent } from './container/default-container/default-container.component';
import { MDBBootstrapModule} from 'angular-bootstrap-md';
import { SearchBarComponent } from './@core/components/index/search-bar/search-bar.component';
import { ArticlesComponent } from './@core/components/index/articles/articles.component';
import { GroupsComponent } from './@core/components/index/groups/groups.component';
import { CarouselGroupsComponent } from './@core/components/index/carousel-groups/carousel-groups.component';
import { ArticleComponent } from './@core/components/index/article/article.component';
import { CarouselArticleComponent } from './@core/components/index/carousel-article/carousel-article.component';
import { ArticleWindowComponent } from './@core/components/index/article-window/article-window.component';
import {AuthInterceptorService} from './@core/services/auth/auth-interceptor.service';
import { SeeArticlesComponent } from './@core/components/shoppingCar/see-articles/see-articles.component';
import { OrderComponent } from './@core/components/shoppingCar/order/order.component';
import { EditProfileComponent } from './@core/components/auth/edit-profile/edit-profile.component';
import { DirectionsComponent } from './@core/components/auth/directions/directions.component';
import { NewDirectionComponent } from './@core/components/auth/new-direction/new-direction.component';
import { OrdersComponent } from './@core/components/auth/orders/orders.component';
import { TpvResultComponent } from './@core/components/tpv-result/tpv-result.component';
import { TpvBadResultComponent } from './@core/components/tpv-bad-result/tpv-bad-result.component';
import { SetPasswordComponent } from './@core/components/auth/set-password/set-password.component';
import { PrivacyPolicyComponent } from './@core/components/footer/privacy-policy/privacy-policy.component';
import { LegalNoticeComponent } from './@core/components/footer/legal-notice/legal-notice.component';
import { CookiesComponent } from './@core/components/footer/cookies/cookies.component';

import { AppConfig } from './app.config';
import { ResetPasswordComponent } from './@core/components/auth/reset-password/reset-password.component';
import {MyDateAdapter} from './custom.date.adapter';
import {NgxSpinnerModule} from 'ngx-spinner';
import { TableModule } from 'angular-bootstrap-md';
import { InicioComponent } from './@core/components/pagina-estatica/inicio/inicio.component';
import { AcercaDeComponent } from './@core/components/pagina-estatica/acerca-de/acerca-de.component';
import { AtencionAlClienteComponent } from './@core/components/pagina-estatica/atencion-al-cliente/atencion-al-cliente.component';
import {_MatCheckboxRequiredValidatorModule, MatCheckboxModule} from '@angular/material/checkbox';
import {DataTablesModule} from 'angular-datatables';
import {ModalModule} from "ngb-modal";
import { PoliticaDeDevolucionComponent } from './@core/components/footer/politica-de-devolucion/politica-de-devolucion.component';

import { NgChartsModule } from 'ng2-charts';
import { ClubComponent } from './@core/components/pagina-estatica/club/club.component';
import {NgxPayPalModule} from 'ngx-paypal';
import {SafePipe} from './@core/Pipes/safePipe';
import { EncuestaComponent } from './@core/components/pagina-estatica/encuesta/encuesta.component';

import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs);
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    E404Component,
    E500Component,
    FooterAuthComponent,
    DefaultContainerComponent,
    SearchBarComponent,
    ArticlesComponent,
    GroupsComponent,
    CarouselGroupsComponent,
    ArticleComponent,
    CarouselArticleComponent,
    ArticleWindowComponent,
    SeeArticlesComponent,
    OrderComponent,
    EditProfileComponent,
    DirectionsComponent,
    NewDirectionComponent,
    OrdersComponent,
    TpvResultComponent,
    TpvBadResultComponent,
    SetPasswordComponent,
    PrivacyPolicyComponent,
    LegalNoticeComponent,
    CookiesComponent,
    ResetPasswordComponent,
    InicioComponent,
    AcercaDeComponent,
    AtencionAlClienteComponent,
    PoliticaDeDevolucionComponent,
    ClubComponent,
    SafePipe,
    EncuestaComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
    HttpClientModule,
    FormsModule,
    NgbModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgSelectModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MDBBootstrapModule.forRoot(),
    NgxPaginationModule,
    RouterModule.forRoot(routes, {useHash: true}),
    MatTabsModule,
    MatTableModule,
    NgxSpinnerModule,
    MatSelectModule,
    MatIconModule,
    MatSlideToggleModule,
    MatSortModule,
    TableModule,
    _MatCheckboxRequiredValidatorModule,
    MatCheckboxModule,
    DataTablesModule,
    ModalModule,
    NgChartsModule,
    NgxPayPalModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent],
  providers: [
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    { provide: HashLocationStrategy, useClass: HashLocationStrategy},
    AppConfig,
    { provide: APP_INITIALIZER, useFactory: (config: AppConfig) => () => config.load(), deps: [AppConfig], multi: true },
    { provide: DateAdapter, useClass: MyDateAdapter},
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: LOCALE_ID, useValue: 'es-ES'},
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  ]
})

export class AppModule { }
