<!-- Footer -->
<hr style="margin-bottom:0px"/>
<footer class="page-footer font-small pt-2" style="background: rgb(244,244,247);
                    background: linear-gradient(180deg, rgba(244,244,247,1) 0%, rgba(255,255,255,0.053658963585434205) 100%, rgba(0,212,255,1) 100%);
                    margin-top:0px;">
  <div class="container-fluid text-center pt-4">
    <!-- Content -->
    <div class="pb-4">
      <h3><b>Contacto</b></h3>
      <a href="mailto:{{mail}}" style="color:black"><i class="far fa-envelope"></i> {{mail}}</a><br />
      <span class=""><i class="fas fa-phone-alt"></i> {{phone}}<span class="ml-3">{{alternativePhone}}</span></span>
    </div>

    <div >
      <!-- Links -->
      <div class="pb-4">
        <h3><b>Archivos de Interés</b></h3>
        <ul class="list-unstyled">
          <li>
            <a routerLink="/aviso-legal" style="color:black">Aviso Legal</a>
          </li>
          <li>
            <a routerLink="/politica-privacidad" style="color:black">Política de privacidad</a>
          </li>
          <li>
            <a routerLink="/aviso-cookies" style="color:black">Cookies</a>
          </li>
          <li>
            <a style="color:black" (click)="openExternalLink('https://www.soft-control.net')">
              Desarrollado por Soft Control Informática
            </a>
          </li>
          <li *ngIf="companyException === 'PEMA'" class="mt-2 w-100 d-flex justify-content-center">
            <div>
              <img src="assets/logos-especiales/UnionEuropea.png" alt="Fondo Europeo de Desarrollo Regional"
                   style="width: 100px !important; height: auto !important;" class="mr-2">
              <img src="assets/logos-especiales/JuntaAndalucia.png" alt="Junta de Andalucía"
                   style="width: 100px !important; height: auto !important;" class="mr-2">
            </div>
          </li>
          <li *ngIf="companyException === 'PEMA'" class="mt-2 w-100 d-flex justify-content-center">
            <div>
              <img src="assets/logos-especiales/andaluciaSeMueve.png" alt="Andalucía se mueve con Europa"
                   style="width: 100px !important; height: auto !important;">
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
<!-- Footer -->
