import { Injectable } from '@angular/core';
import {CanActivate, UrlTree, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {TpvService} from '../services/tpv.service';
import {LocalStorageService} from '../services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ValidTpvGuard implements CanActivate {
  constructor(private router: Router, private tpvService: TpvService, private localStorage: LocalStorageService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean | Observable<UrlTree> | UrlTree {
    state.url = state.url.replace('/errorPago/', '');
    // @ts-ignore
    return this.tpvService.validTpv(state.url.replace('/pagoRealizado/', '') + '=')
      .pipe(
        // Si la petición es exitosa se puede proceder
        map(() => {
          localStorage.removeItem('ARTICLES');
          // this.localStorage.removeAllArticles();
          return true;
        }),
        catchError(() => {
          this.router.navigate(['/']);
          return Observable.throw(false);
        })
      );
  }
}
