import {Component} from '@angular/core';

@Component({
  selector: 'app-tpv-bad-result',
  templateUrl: './tpv-bad-result.component.html',
  styleUrls: ['./tpv-bad-result.component.scss']
})
export class TpvBadResultComponent {
  constructor() { }
}
