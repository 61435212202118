<div *ngIf="companyConf  == null || datosLegalesEmpresa == null || ((families== null || superGroups == null));
            else elseContent1"
     class="align-items-center d-flex justify-content-center container-load">
  <div class="w-100">
    <div class="w-100 d-flex justify-content-center mb-3">
      <img src="assets/loading/loading.gif" alt="Cargando icono" style="height: 150px !important; width: auto !important;"/>
    </div>
    <div class="text-center">
      <h3>Estamos cargando todos los datos</h3>
      <p style="font-size: 14px">Espere un momento</p>
    </div>
  </div>
</div>

<ng-template #elseContent1>
  <div [hidden]="showMenu">

    <!-- NAVBAR PARTICULARES -->
    <div *ngIf="companyConf.tipoMenu != 2">
      <div class="navbar-computer">
        <nav class="bg-secondary text-secondary d-flex" >
          <div class="col-md-4">
            <div class="mt-2 pl-0">
              <ul class="list-unstyled d-flex" *ngIf="companyConf.paginaEstatica">
                <li>
                  <a class="nav-link text-menu-secondary pb-0" routerLink="/inicio" style="font-size: 0.875em !important;">Inicio</a>
                </li>
                <li>
                  <a class="nav-link text-menu-secondary pb-0" routerLink="/acerca-de" style="font-size: 0.875em !important;">Acerca de</a>
                </li>
                <li>
                  <a class="nav-link text-menu-secondary pb-0" routerLink="/atencion-al-cliente" style="font-size: 0.875em !important;">Atención al cliente</a>
                </li>
              </ul>

              <ul class="list-unstyled d-flex" *ngIf="!companyConf.paginaEstatica">
                <li>
                  <a *ngIf="companyConf.inicio != undefined && companyConf.inicio != ''" class="nav-link text-menu-secondary pb-0" [href]="companyConf.inicio" style="font-size: 0.875em !important;">Inicio</a>
                </li>
                <li>
                  <a *ngIf="companyConf.acercaDe != undefined && companyConf.acercaDe != ''" class="nav-link text-menu-secondary pb-0" [href]="companyConf.acercaDe" style="font-size: 0.875em !important;">Acerca de</a>
                </li>
                <li>
                  <a *ngIf="companyConf.atencionCliente != undefined && companyConf.atencionCliente != ''" class="nav-link text-menu-secondary pb-0" [href]="companyConf.atencionCliente" style="font-size: 0.875em !important;">Atención al cliente</a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-md-4 mt-2">
            <div [@animSlider]="counter" class="w-100 text-center mt-1">
              <ng-container *ngFor="let texto of textos; let i=index">
                <div *ngIf="i==counter" name="{{i}}" class="text-menu-secondary" style="font-size: 0.875em !important;">
                  {{texto}}
                </div>
              </ng-container>
            </div>
          </div>

          <div class="col-md-4 mt-2">
            <div class="w-100 d-flex justify-content-end">
              <a class="nav-link text-menu-secondary pb-0" routerLink="/iniciarSesion" style="font-size: 0.875em !important;" *ngIf="!isUser; else elseIsUser">
                <div class="d-flex align-items-center">
                  <i class="fa-solid fa-circle-user fa-2x mr-2"></i>Entrar
                </div>
              </a>
              <ng-template #elseIsUser>
                <a class="nav-link text-menu-secondary pb-0"  style="font-size: 0.875em !important;" title="Cerrar sesión">
                  <div class="d-flex align-items-center" (click)="logOut()">
                    <i class="fa-solid fa-sign-out-alt fa-2x mr-2"></i>Cerrar sesión
                  </div>
                </a>
              </ng-template>
            </div>
          </div>
        </nav>
        <nav class="bg-primary text-light  pl-4 pr-4 pt-4 pb-3 d-flex">
          <div class="col-md-4">
            <div class="text-light">
              <h1 style="font-family: Georgia, serif; font-size: 33px !important;">{{companyConf.webName}}</h1>
            </div>
          </div>

          <div class="col-md-4">
            <div class="text-light">
              <form class="w-100 border border-light pl-3 pr-3 pb-1 pt-1 form-search text-light">
                <input type="text" placeholder="Busca un producto" class="w-100 search-input bg-primary text-light no-outline"
                       [(ngModel)]="searchArticlesText" (ngModelChange)="setSearchArticles()">
              </form>
            </div>

            <div class="position-absolute w-100 index-10" *ngIf="searchArticles">
              <div class="ml-3 mr-5 search-container">
                <div class="bg-white w-100 text-dark">
                  <div *ngFor="let article of searchArticles" class="border-bottom p-3 pointer"
                       routerLink="/compra/{{article.name}}/{{article.group}}/{{article.family}}/{{article.id}}"
                       (click)="searchArticles = undefined; searchArticlesText = undefined;">
                    <div class="d-flex">
                      <img *ngIf="article.articleImgUrl; else productImg" src="./assets/fotos/articulos/{{article.articleImgUrl}}" height="auto" width="100px"/>
                      <ng-template #productImg>
                        <img *ngIf="article.productImgUrl" src="./assets/fotos/productos/{{article.productImgUrl}}" height="auto" width="100px"/>
                      </ng-template>

                      <div class="ml-3">
                        <div class="mb-2">
                          {{article.name}}
                        </div>
                        <div class="font-weight-bolder">
                          {{article.priceIvaStr}} €
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="w-100 d-flex justify-content-end">
              <a *ngIf="companyConf.paginaEstatica && companyConf.mostrarUbicaciones"
                 class="nav-link text-dark pb-0" routerLink="/atencion-al-cliente"
                 fragment="ubicaciones"
                 style="font-size: 15px !important;" title="Ubicaciones">
                <div class="d-flex align-items-center">
                  <i class="fa-solid fa-map-location-dot fa-1-5x mr-1 text-light"></i>
                </div>
              </a>

              <a class="nav-link text-light pb-0" style="font-size: 15px !important;" routerLink="/miPerfil"
                 *ngIf="isUser" title="Mi perfil">
                <div class="d-flex align-items-center" >
                  <i class="fa-solid fa-user fa-1-5x mr-1"></i>
                </div>
              </a>

              <a class="nav-link mr-3 pointer" *ngIf="companyConf.storePickup && !companyConf.soloRecogidaTienda">
                <i class="fas fa-store mr-2 text-light fa-1-5x" title="Recogida en tienda" style="color: #607d8b;"></i>
                <mat-slide-toggle [(ngModel)]="homeDelivery" (change)="setInfoRoute()"></mat-slide-toggle>
                <span>
                  <i class="fas fa-truck ml-2 text-light fa-1-5x" title="Envío a domicilio"></i>  <br/>
                </span>
              </a>

              <a class="nav-link text-light pb-0" style="font-size: 15px !important;" (click)="goShoppingCar()" title="Carrito">
                <div class="d-flex align-items-center" >
                  <i class="fas fa-shopping-cart fa-1-5x"></i><span class='badge badge-warning lblCartCount'> {{numberArticles}} </span>
                </div>
              </a>
            </div>
          </div>
        </nav>
      </div>

      <div class="groups">
        <app-groups [superGroups]="superGroups" [initRoute]="companyConf.initRoute"
                    [useQuickOrder]="companyConf.useQuickOrder"
                    [repeatOrder]="companyConf.showRepeatOrderWindow"
                    [families]="families"
                    [companyException]="companyConf.companyException"
                    [showAllMenu]="companyConf.showAllMenu"
                    [portalDeClientes]="companyConf.portalDeClientes"></app-groups>
      </div>

      <div class="navbar-mobil">
        <nav class="border-bottom navbar-light d-flex">
          <div class="d-flex ml-3 mb-2 mt-2 w-25">
            <div>
              <img *ngIf="companyConf" [src]="companyConf.logo" alt="Logo empresa" style="width: auto; height: 3.75em;"/>
            </div>
          </div>
          <div class="w-50 d-flex justify-content-center align-items-center"
               *ngIf="companyConf.storePickup && !companyConf.soloRecogidaTienda&& !companyConf.soloRecogidaTienda">
            <div>
              <div class="d-flex justify-content-center align-items-center">
                <i class="fas fa-store mr-2" style="color: #607d8b;"></i>
                <mat-slide-toggle [(ngModel)]="homeDelivery" (change)="setInfoRoute()"></mat-slide-toggle>
                <span>
                <i class="fas fa-truck ml-2 text-primary text-dark"></i>  <br/>
              </span>
              </div>

              <div class="w-100">
                <span class="w-100 text-center" style="font-size: 15px" *ngIf="homeDelivery">Envío a domicilio</span>
                <span class="w-100 text-center" style="font-size: 15px" *ngIf="!homeDelivery">Recogida en tienda</span>
              </div>
            </div>
          </div>
          <div class="w-50" *ngIf="!(companyConf.storePickup)"></div>

          <div class="d-flex justify-content-end align-items-center w-25">
            <button class="mr-3 navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                    aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon text-danger"></span>
            </button>
          </div>
        </nav>
        <nav class="navbar navbar-expand-lg navbar-light bg-white collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav mr-auto w-100">
            <li class="nav-item active border-bottom" *ngIf="languages">
              <div *ngIf="languages.length > 1" class="nav-link pt-2 pb-2" data-toggle="collapse" data-target=".navbar-collapse.show">
                <div class="mt-0">
                  <div class="mt-0 nav-style w-100 d-flex" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                       *ngIf="selectedLanguage != undefined">
                    <div class="w-75"><i class="fas fa-globe-europe mr-3"></i> {{selectedLanguage.toUpperCase()}}</div>
                    <div class="w-25 d-flex justify-content-end"><i class="ml-1 fas fa-caret-down"></i></div>
                  </div>
                  <div class="dropdown-menu">
                    <div *ngFor="let language of languages" >
                      <div class="btn btn-default group text-left" (click)="setLanguage(language.id)"
                           data-toggle="collapse" data-target=".navbar-collapse.show">
                        {{language.value}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li class="nav-item active border-bottom" routerLink="/inicio">
              <div class="nav-link pt-2 pb-2" data-toggle="collapse" data-target=".navbar-collapse.show">
                <div class="nav-style">
                  INICIO
                </div>
              </div>
            </li>

            <li class="nav-item active border-bottom" routerLink="/acerca-de">
              <div class="nav-link pt-2 pb-2" data-toggle="collapse" data-target=".navbar-collapse.show">
                <div class="nav-style">
                  ACERCA DE
                </div>
              </div>
            </li>

            <li class="nav-item active border-bottom" routerLink="/atencion-al-cliente">
              <div class="nav-link pt-2 pb-2" data-toggle="collapse" data-target=".navbar-collapse.show">
                <div class="nav-style">
                  CONTACTO
                </div>
              </div>
            </li>

            <li class="nav-item active border-bottom" routerLink="/compra/ofertas" *ngIf="showOffers">
              <div class="nav-link pt-2 pb-2" data-toggle="collapse" data-target=".navbar-collapse.show">
                <div class="nav-style">
                  <i class="fas fa-percent mr-3"></i>NUESTRAS PROMOCIONES
                </div>
              </div>
            </li>
            <li *ngIf="isUser" class="nav-item active border-bottom" routerLink="/miPerfil/misPedidos">
              <div class="nav-link pt-2 pb-2" data-toggle="collapse" data-target=".navbar-collapse.show">
                <div class="nav-style">
                  <i class="fas fa-list-ol mr-3"></i>MIS PEDIDOS
                </div>
              </div>
            </li>

            <li class="nav-item active border-bottom" routerLink="/compra/buscar">
              <div class="nav-link pt-2 pb-2" data-toggle="collapse" data-target=".navbar-collapse.show">
                <div class="nav-style">
                  <i class="fas fa-search mr-3"></i>BUSCAR ARTÍCULOS
                </div>
              </div>
            </li>

            <div>
              <div *ngFor="let superGroup of superGroups" >
                <div *ngIf="superGroup.id !== '' && superGroup.id != 'none'; else elseGroup"
                     id="{{superGroup.id}}" class="nav-link pt-2 pb-2"
                     (click)="getArticlesSuperGroup(superGroup)" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <div *ngIf="superGroup.groups.length !== 1; else elseNameGroup" class="mt-0 border-bottom">
                    <div class="nav-style w-100 d-flex p-3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <div class="w-75 ">{{superGroup.name}}</div>
                      <div class="w-25 d-flex justify-content-end"><i class="ml-1 fas fa-caret-down"></i></div>
                    </div>


                    <div class="dropdown-menu">
                      <div *ngFor="let group of superGroup.groups" >
                        <div class="btn btn-default group text-left" id="{{group.id}}"
                             (click)="getArticlesSuperGroupGroup(superGroup, group)"
                             data-toggle="collapse" data-target=".navbar-collapse.show">
                          {{group.name}}
                        </div>
                      </div>
                    </div>
                  </div>

                  <ng-template #elseNameGroup>
                    <li class="nav-item active border-bottom">
                      <div class="nav-link pt-2 pb-2" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <div class="nav-style">
                          {{superGroup.name}}
                        </div>
                      </div>
                    </li>
                  </ng-template>
                </div>

                <ng-template #elseGroup>
                  <div *ngFor="let group of superGroup.groups">
                    <li class="nav-item active border-bottom" *ngIf="group.id !== ''">
                      <div class="nav-link pt-2 pb-2" data-toggle="collapse" data-target=".navbar-collapse.show">
                        <div class="nav-style"
                             (click)="getArticles(group)">
                          {{group.name}}
                        </div>
                      </div>
                    </li>
                  </div>
                </ng-template>
              </div>
            </div>

            <li *ngIf="isUser && companyConf.onlyRegisterCompanies" class="nav-item active border-bottom" routerLink="/miPerfil/misPedidos"
                routerLink="/miPerfil/nuevaContrasena" (click)="topPage()">
              <div class="nav-link pt-2 pb-2" data-toggle="collapse" data-target=".navbar-collapse.show">
                <div class="nav-style">
                  <i class="fas fa-key mr-3"></i>CAMBIAR CONTRASEÑA
                </div>
              </div>
            </li>

            <li class="nav-item active border-bottom" (click)="topPage(); logOut();" >
              <div class="nav-link pt-2 pb-2" data-toggle="collapse" data-target=".navbar-collapse.show">
                <div class="nav-style">
                  <i class="fas fa-sign-out-alt mr-3"></i>CERRAR SESIÓN
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div *ngIf="companyConf.tipoMenu == 2">
      <nav class="navbar-computer ">
        <div class="bg-primary">
          <div class="row pt-3 pb-1 ml-1 mr-1">
            <div class="col-10 bg-primary">
              <ul class="list-unstyled d-flex" *ngIf="companyConf.paginaEstatica">
                <li class="mr-5">
                  <a class="nav-link a-primary pb-0" routerLink="/inicio"
                     (click)="topPage()"
                     style="font-size: 15px !important;">Inicio</a>
                </li>

                <li class="mr-5">
                  <div class="text-light mt-1" style="width: 375px;">
                    <form class="w-100 border border-light pl-3 pr-3 pb-1 pt-1 form-search text-light">
                      <input type="text" placeholder="Busca un producto" class="w-100 search-input bg-primary text-light no-outline"
                             [(ngModel)]="searchArticlesText" (ngModelChange)="setSearchArticles()">
                    </form>
                  </div>

                  <div class="position-absolute index-10" style="width: 415px;" *ngIf="searchArticles">
                    <div class="ml-3 mr-5 search-container">
                      <div class="bg-white w-100 text-dark">
                        <div *ngFor="let article of searchArticles" class="border-bottom p-3 pointer"
                             (click)="topPage()"
                             routerLink="/compra/{{article.name}}/{{article.group}}/{{article.family}}/{{article.id}}"
                             (click)="searchArticles = undefined; searchArticlesText = undefined;">
                          <div class="d-flex">
                            <img *ngIf="article.articleImgUrl; else productImg" src="./assets/fotos/articulos/{{article.articleImgUrl}}" height="auto" width="100px"/>
                            <ng-template #productImg>
                              <img *ngIf="article.productImgUrl" src="./assets/fotos/productos/{{article.productImgUrl}}" height="auto" width="100px"/>
                            </ng-template>

                            <div class="ml-3">
                              <div class="mb-2">
                                {{article.name}}
                              </div>
                              <div class="font-weight-bolder">
                                {{article.priceIvaStr}} €
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li class="pr-2 border-right border-light mr-2">
                  <a class="nav-link a-primary pb-0" routerLink="/atencion-al-cliente"
                     fragment="ubicaciones" style="font-size: 15px !important;">
                    <i class="fa-solid fa-map-location-dot mr-1 text-light"></i> <span class="computer-lg">Puntos de recogida</span>
                  </a>
                </li>

                <div (click)="goShoppingCar()" title="Cesta de la compra">
                  <div class="nav-link a-primary pb-0"  style="font-size: 15px !important;">
                    <i class="fa-solid fa-basket-shopping mr-1"></i><span class='badge badge-warning lblCartCount'> {{numberArticles}}</span>
                    <span class="computer-lg">Cesta</span>
                  </div>
                </div>
              </ul>
            </div>

            <div class="col-2 ">
              <div class="float-right">
                <a class="nav-link a-primary pb-0" routerLink="/iniciarSesion"
                   (click)="topPage()"
                   style="font-size: 15px !important;" *ngIf="!isUser; else elseIsUser">
                  <i class="fa-solid fa-right-to-bracket mr-1 text-light"></i>
                  <span class="computer-lg">Iniciar sesión</span>
                </a>

                <ng-template #elseIsUser>
                  <a class="nav-link a-primary pb-0" routerLink="/miPerfil"
                     (click)="topPage()" style="font-size: 15px !important;">
                    <i class="fa-solid fa-user mr-1"></i>
                    <span class="computer-lg">Mi perfil</span>
                  </a>
                </ng-template>

              </div>
            </div>
          </div>
        </div>
      </nav>

      <nav class="navbar-computer mt-4">
        <div class="container">
          <div class="row" *ngIf="menu2 != undefined">
            <div class="col-2 mt-3"
                 (mouseover)="menu2[0].mostrarDesplegable = menu2[0].desplegable.length > 0"
                 (mouseleave)="menu2[0].mostrarDesplegable = false">
              <h3 class="w-100 text-center pointer" (click)="irARuta(menu2[0].ruta)">
                {{menu2[0].texto}}
                <i class="ml-2 fa-solid fa-caret-down" *ngIf="menu2[0].desplegable.length > 0"></i>
              </h3>

              <div *ngIf="menu2[0].mostrarDesplegable" class="position-absolute border border-secondary w-100 bg-white"
                   style="border-radius: 10px; z-index: 10 !important">
                <div class="pt-3">
                  <div *ngFor="let opcion of menu2[0].desplegable; let i = index">
                    <a class="text-decoration-none text-center text-dark pointer" (click)="irARuta(opcion.ruta)">
                      <h3 [ngClass]="{'pb-3 border-bottom border-secondary': i != menu2[2].desplegable.length -1}">
                        {{opcion.texto}}
                      </h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-2 mt-3"
                 (mouseover)="menu2[1].mostrarDesplegable = menu2[1].desplegable.length > 0"
                 (mouseleave)="menu2[1].mostrarDesplegable = false">
              <h3 class="w-100 text-center pointer" (click)="irARuta(menu2[1].ruta)">
                {{menu2[1].texto}}
                <i class="ml-2 fa-solid fa-caret-down" *ngIf="menu2[1].desplegable.length > 0"></i>
              </h3>

              <div *ngIf="menu2[1].mostrarDesplegable" class="position-absolute border border-secondary w-100 bg-white"
                   style="border-radius: 10px; z-index: 10 !important">
                <div class="pt-3">
                  <div *ngFor="let opcion of menu2[1].desplegable; let i = index">
                    <a class="text-decoration-none text-center text-dark pointer" (click)="irARuta(opcion.ruta)">
                      <h3 [ngClass]="{'pb-3 border-bottom border-secondary': i != menu2[2].desplegable.length -1}">
                        {{opcion.texto}}
                      </h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-4 h-100">
              <img src="assets/logo-alargado.png" width="auto" height="100%" alt="Logo de la empresa"/>
            </div>

            <div class="col-2 mt-3"
                 (mouseover)="menu2[2].mostrarDesplegable = menu2[2].desplegable.length > 0"
                 (mouseleave)="menu2[2].mostrarDesplegable = false">
              <h3 class="w-100 text-center pointer" (click)="irARuta(menu2[2].ruta)">
                {{menu2[2].texto}} <i class="ml-2 fa-solid fa-caret-down" *ngIf="menu2[2].desplegable.length > 0"></i>
              </h3>

              <div *ngIf="menu2[2].mostrarDesplegable" class="position-absolute border border-secondary w-100 bg-white"
                   style="border-radius: 10px; z-index: 10 !important">
                <div class="pt-3">
                  <div *ngFor="let opcion of menu2[2].desplegable; let i = index">
                    <a class="text-decoration-none text-center text-dark pointer" (click)="irARuta(opcion.ruta)">
                      <h3 [ngClass]="{'pb-3 border-bottom border-secondary': i != menu2[2].desplegable.length -1}">
                        {{opcion.texto}}
                      </h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-2 mt-3"
                 (mouseover)="menu2[3].mostrarDesplegable = menu2[3].desplegable.length > 0"
                 (mouseleave)="menu2[3].mostrarDesplegable = false">
              <h3 class="w-100 text-center pointer" (click)="irARuta(menu2[3].ruta)">
                {{menu2[3].texto}}
                <i class="ml-2 fa-solid fa-caret-down" *ngIf="menu2[3].desplegable.length > 0"></i>
              </h3>

              <div *ngIf="menu2[3].mostrarDesplegable" class="position-absolute border border-secondary w-100 bg-white"
                   style="border-radius: 10px; z-index: 10 !important">
                <div class="pt-3">
                  <div *ngFor="let opcion of menu2[3].desplegable; let i = index">
                    <a class="text-decoration-none text-center text-dark pointer" (click)="irARuta(opcion.ruta)">
                      <h3 [ngClass]="{'pb-3 border-bottom border-secondary': i != menu2[2].desplegable.length -1}">
                        {{opcion.texto}}
                      </h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <nav class="navbar navbar-expand-lg navbar-light bg-white navbar-mobil">
        <a class="navbar-brand bg-white">
          <img *ngIf="companyConf" src="assets/logo-alargado.png" class="bg-white" alt="Logo empresa" style="width: auto; height: 3.75em;"/>
        </a>

        <button class="mr-3 navbar-toggler" type="button" (click)="showMenu = true">
          <i class="fa-solid fa-bars fa-2x text-primary"></i>
        </button>
      </nav>
    </div>
    <!-- -------- FIN NAVBAR PARTICULARES --------- -->


    <!--<div *ngIf="companyConf.msgWeb" class="max border-bottom border-top border-primary text-light text-center">
      {{companyConf.msgWeb}}
    </div>
    -->

    <div id="border-bottom-nav"></div>

    <div class="computer w-100">
      <div class="container-fluid mt-2">
        <ngb-carousel *ngIf="publicityPhotosComputer" [showNavigationArrows]="false" [showNavigationIndicators]="false"
                      [pauseOnHover]="true" [interval]="7000" class="w-100">
          <ng-template ngbSlide *ngFor="let image of publicityPhotosComputer">
            <div class="picsum-img-wrapper">
              <img [src]="image" alt="Imagen de publicidad" class="w-100">
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
    </div>

    <div class="mobil">
      <ngb-carousel *ngIf="publicityPhotosMobile" [showNavigationArrows]="false" [showNavigationIndicators]="false"
                    [pauseOnHover]="true" [interval]="7000">
        <ng-template ngbSlide *ngFor="let image of publicityPhotosMobile">
          <div class="picsum-img-wrapper">
            <img [src]="image" alt="Imagen de publicidad" class="w-100">
          </div>
        </ng-template>
      </ngb-carousel>
    </div>

    <div class="navbar-mobil bottom-menu" [hidden]="!this.showBottomMenu">
      <div class="bg-white d-flex" style="height: 80px !important;">
        <a (click)="redirectToHome()" style="width: 33.33% !important;"  #init>
          <div>
            <i class="fas fa-home"></i><br/>
            Inicio
          </div>

          <div *ngIf="activateMobil != null">
            {{selectMenuActive(init, car, profile)}}
          </div>
        </a>

        <a routerLink="/carrito" style="width: 33.33% !important;" #car (click)="activateMobil = 1; processOrderNav=false;" >
          <i class="fas fa-shopping-cart"></i><span class='badge badge-warning lblCartCount'> {{numberArticles}} </span><br/>
          Carrito
        </a>

        <a style="width: 33.33% !important;" #profile>
          <ng-container *ngIf="userInfo; else elseSession">
            <div routerLink="/miPerfil" class="w-100 h-100">
              <i class="fas fa-user"></i><br/>
              Mi perfil
            </div>
          </ng-container>

          <ng-template #elseSession>
            <span routerLink="/iniciarSesion">
              <i class="fas fa-sign-in-alt"></i><br/>
              Acceder
            </span>
          </ng-template>
        </a>
      </div>
    </div>


    <!-- Main content -->
    <main class="main" style="min-height: 70vh !important;">
      <div class="mt-2 " *ngIf="companyConf != undefined ">
        <router-outlet (activate)="onChildLoaded($event)" ></router-outlet>
      </div>
    </main>

    <!-- Footer -->
    <footer class="page-footer font-small pt-2 "
            [ngClass]="{'bg-dark': companyConf.companyException != 'PEMA',
                          'bg-darkslategrey': companyConf.companyException == 'PEMA'}">
      <div class="pt-4 pl-2 pr-2" style="width: 90%; margin-left: 5%; margin-right: 5%;">
        <!-- Grid -->
        <div *ngIf="companyConf" class="row">
          <!-- Content -->
          <div class="col-md-6 pb-5 menu-text">
            <div>
              <h3 [ngClass]="{'text-white': companyConf.companyException != 'PEMA',
                                'text-black': companyConf.companyException == 'PEMA'}">{{datosLegalesEmpresa.name.replace('ESPAÑA, ', '')}}</h3>
              <div [ngClass]="{'text-white': companyConf.companyException != 'PEMA',
                                'text-dark': companyConf.companyException == 'PEMA'}">
                  <span>
                    {{datosLegalesEmpresa.direction}}<br>
                    Telf: {{companyConf.phone}} <span class="ml-3">{{companyConf.alternativePhone}}<br>
                  </span>
                  Correo electrónico:<br>
                  <a href="mailto:{{datosLegalesEmpresa.email}}"
                     [ngClass]="{'text-white': companyConf.companyException != 'PEMA',
                                'text-dark': companyConf.companyException == 'PEMA'}">{{datosLegalesEmpresa.email}}</a>
                  </span>
              </div>
            </div>
          </div>

          <!-- Links -->
          <div class="col-md-6 pb-5 menu-text">
            <div>
              <h3 [ngClass]="{'text-white': companyConf.companyException != 'PEMA',
                                'text-black': companyConf.companyException == 'PEMA'}">Archivos de Interés</h3>
              <ul class="list-unstyled"
                  [ngClass]="{'text-white': companyConf.companyException != 'PEMA',
                                'text-dark': companyConf.companyException == 'PEMA'}">
                <li>
                  <a routerLink="/aviso-legal" [ngClass]="{'text-white': companyConf.companyException != 'PEMA',
                                'text-dark': companyConf.companyException == 'PEMA'}">Aviso Legal</a>
                </li>
                <li>
                  <a routerLink="/politica-privacidad" [ngClass]="{'text-white': companyConf.companyException != 'PEMA',
                                'text-dark': companyConf.companyException == 'PEMA'}">Política de privacidad</a>
                </li>
                <li>
                  <a routerLink="/política-devolucion-y-envio"
                     [ngClass]="{'text-white': companyConf.companyException != 'PEMA', 'text-dark': companyConf.companyException == 'PEMA'}">
                    Política de devolución y envío
                  </a>
                </li>
                <li>
                  <a routerLink="/aviso-cookies" [ngClass]="{'text-white': companyConf.companyException != 'PEMA',
                                'text-dark': companyConf.companyException == 'PEMA'}">Cookies</a>
                </li>
                <li>
                  <a [ngClass]="{'text-white': companyConf.companyException != 'PEMA',
                                'text-dark': companyConf.companyException == 'PEMA'}" (click)="openExternalLink('https://www.soft-control.net')">
                    Desarrollado por Soft Control Informática
                  </a>
                </li>

                <li *ngIf="companyConf.companyException == 'PEMA'" class="mt-2 w-100 d-flex justify-content-center">
                  <div>
                    <img src="assets/logos-especiales/UnionEuropea.png" alt="Fondo Europeo de Desarrollo Regional"
                         style="width: 100px !important; height: auto !important;" class="mr-2">
                    <img src="assets/logos-especiales/JuntaAndalucia.png" alt="Junta de Andalucía"
                         style="width: 100px !important; height: auto !important;" class="mr-2">
                  </div>
                </li>
                <li *ngIf="companyConf.companyException == 'PEMA'" class="mt-2 w-100 d-flex justify-content-center">
                  <div>
                    <img src="assets/logos-especiales/andaluciaSeMueve.png" alt="Andalucía se mueve con Europa"
                         style="width: 100px !important; height: auto !important;">
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-12 mt-3 mb-3 " *ngIf="companyConf.subvencionadoKitDigital">
            <div class="d-flex justify-content-center w-100">
              <img src="assets/logos-especiales/LOGOPOSITIVO.png" class="bg-white" alt="Plan de recuperación, transformación y resilencia" height="100px" width="auto">
              <img src="assets/logos-especiales/ES%20Financiado%20por%20la%20Unión%20Europea_BLACK.png" class="bg-white" alt="Financiado por la UE"  height="100px" width="auto">
            </div>
          </div>
        </div>
      </div>
      <!-- Grid-->
    </footer>
    <!-- Footer -->
  </div>

  <div *ngIf="companyConf != undefined">
    <div class="position-sticky vh-100 w-100 bg-primary text-light" [hidden]="!showMenu" *ngIf="companyConf.tipoMenu == 2">
      <div class="d-flex align-items-center p-4">
        <div class="w-100">
          <div class="mb-2 border-bottom border-light w-100 text-right"  (click)="showMenu = false">
            <i class="fa-solid fa-xmark fa-2x pb-3"></i>
          </div>

          <div>
            <h2 class="nav-item nav-link text-light" routerLink="/inicio" (click)="showMenu = false">Inicio</h2>
            <h2 class="nav-item nav-link text-light" [routerLink]="menu2[0].ruta" (click)="showMenu = false">{{menu2[0].texto}}</h2>
            <h2 class="nav-item nav-link text-light" [routerLink]="menu2[1].ruta" (click)="showMenu = false">{{menu2[1].texto}}</h2>
            <h2 class="nav-item nav-link text-light" [routerLink]="menu2[2].ruta" (click)="showMenu = false">{{menu2[2].texto}}</h2>
            <h2 class="nav-item nav-link text-light" [routerLink]="menu2[3].ruta" (click)="showMenu = false">{{menu2[3].texto}}</h2>
            <h2 class="nav-item nav-link text-light" routerLink="/atencion-al-cliente" fragment="ubicaciones" (click)="showMenu = false">Puntos de recogida</h2>
            <h2 class="nav-item nav-link text-light" routerLink="/atencion-al-cliente" (click)="showMenu = false">Contacto</h2>
            <h2 class="nav-item nav-link text-light" routerLink="/iniciarSesion" (click)="showMenu = false"
                *ngIf="!isUser; else USUARIO">Entrar</h2>
            <ng-template #USUARIO>
              <h2 class="nav-item nav-link text-light" routerLink="/miPerfil" (click)="showMenu = false">Mi Perfil</h2>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<!-- SelectClient -->
<modal #ErrorCodigoPostal>
  <modal-header><h2>Información</h2></modal-header>

  <modal-content>
    <div class="max text-center font-weight-bold" style="margin-bottom: 10px">
      <h1 class="text-dark">Para este código postal no servimos pedidos a domicilio.</h1>
    </div>
    <div class="max text-center font-weight-bold" style="margin-bottom: 10px">
      <h3 class="text-dark">Quedate cerca, continuaremos expandiéndonos.</h3>
    </div>

    <div class="max d-flex justify-content-center">
      <a type="button" class="btn btn-dark btn-xs btn-modal" routerLink="/iniciarSesion" (click)="cerrarModal()">
        ACEPTAR
      </a>
    </div>
  </modal-content>
</modal>
