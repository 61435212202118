import {Component, Input} from '@angular/core';

declare var device: { platform: any; };
declare let cordova: any;
declare let window: any;
@Component({
  selector: 'app-footer-auth',
  templateUrl: './footer-auth.component.html',
  styleUrls: ['./footer-auth.component.scss']
})
export class FooterAuthComponent {

  @Input() phone;
  @Input() alternativePhone;
  @Input() mail;
  @Input() companyException;

  constructor() { }

  openExternalLink(link: string) {
    if (window.cordova && cordova.platformId !== "browser") {
      cordova.InAppBrowser.open(link, '_system');
    } else {
      window.open(link, '_blank', 'location=yes');
    }
  }
}
