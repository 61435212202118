<div class="w-100 p-3 text-center" style="background-color: rgba(101,221,99,0.48)" *ngIf="showEmailDiv">
  Correo electrónico enviado con éxito.
</div>


<div class="center mb-3 pt-4 100%">
  <img src="assets/logo.png" alt="Logo de la empresa" class="img"/>
</div>

<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div class="card p-2">
            <div class="card-body">
              <h1 class="card-title mb-4 mt-1 max mb-0 text-dark">
                <b>Iniciar sesión</b>
              </h1>
              <form (ngSubmit)="onSubmit()" [formGroup]="userForm" novalidate>
                <div *ngIf="isLoginFailed && !lockUser" class="error">Usuario o contraseña incorrectos</div>
                <div *ngIf="isLoginFailed && lockUser" class="error">En estos momentos usted no tiene acceso
                  a nuestra plataforma.</div>
                <div class="row">
                  <div class="col-sm-12">
                    <mat-form-field appearance="legacy" class="w-100">
                      <mat-label  (click)="openModal(INFO_USER)">
                        <div class="d-flex help">
                          Usuario
                          <div class="ml-2 text-info">
                            <i class="help fas fa-question-circle"></i>
                          </div>
                        </div>
                      </mat-label>
                      <input type="text" class="w-100" autocomplete="user"
                             formControlName="user" matInput>
                      <div *ngIf="userForm.controls.user.touched && userForm.controls.user.errors?.required"
                           class="error">Campo obligatorio
                      </div>
                      <div *ngIf="userForm.controls.user.touched && !userForm.controls.user.errors?.required
                        && (userForm.controls.user.errors?.userIncorrect)"
                           class="error">Formato incorrecto.
                      </div>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <mat-form-field appearance="legacy" class="w-100">
                      <mat-label>Contraseña</mat-label>
                      <input [type]="hide ? 'password' : 'text'" class="w-100" autocomplete="password"
                             formControlName="password" matInput>
                      <mat-icon matSuffix (click)="hide = !hide">
                        <span *ngIf="!hide; else elseHide"><i class="fas fa-eye-slash fa-xs"></i></span>
                        <ng-template #elseHide><i class="fas fa-eye fa-xs"></i></ng-template>
                      </mat-icon>
                      <div *ngIf="userForm.controls.password.touched && userForm.controls.password.errors?.required"
                           class="error">Campo obligatorio
                      </div>
                      <div *ngIf="userForm.controls.password.touched &&
                                      !userForm.controls.password.errors?.required
                                      && userForm.controls.password.errors?.validLettersIncorrect"
                           class="error">La contraseña no puede contener el carácter '
                      </div>
                    </mat-form-field>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary text-light btn-block"
                        [disabled]="!userForm.valid">Iniciar Sesión</button>
              </form>
            </div>
          </div>
          <div *ngIf="companyConf">
            <div style="margin: 2.5em 10px 10px 10px;">
              <h5><span>¿Eres un nuevo cliente?</span></h5>
              <a class="btn btn-outline-info btn-block"
                 routerLink="/registrarse">
                <i class="far fa-user fa-1x"></i>
                Registrarse como cliente
              </a>
            </div>
            <div style="margin: 1.25em 10px 10px 10px;">
              <h5><span>¿Deseas continuar sin registrate?</span></h5>
              <a class="btn btn-outline-info btn-block mb-4" (click)="localStorageService.navigateToLogin()">
                Continuar sin registrarme
              </a>
            </div>
            <div class="reset_password" style="margin: 1.25em 10px 10px 10px;" *ngIf="companyConf.createPasswordAutomatically">
              <h5><span>¿Ya estas registrado con nosotros?</span></h5>
              <button class="btn btn-outline-info btn-block mb-4"
                      (click)="showEmailDiv=false;openModal(RESET_PASSWORD)">Obtener una nueva contraseña</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>

<!-- Footer -->
<div *ngIf="companyConf">
  <app-footer-auth  [companyException]="companyConf.companyException"
                    [phone]="companyConf.phone" [mail]="companyConf.email"
                    [alternativePhone]="companyConf.alternativePhone"></app-footer-auth>
</div>

<!-- Info User -->
<ng-template #INFO_USER let-modal class="modal">
  <div class="modal-body">
    <div class="container">
      <div class="d-flex mb-2">
        <div class="font-weight-bold mr-4" style="width: 90%;">
          <h1 class="text-info">Tipos de acceso</h1>
        </div>
        <div class="d-flex justify-content-end"
             style="width: 10%">
          <a (click)="modal.close()" class="text-danger">
            <i class="fas fa-times-circle fa-2x"></i>
          </a>
        </div>
      </div>

      <div class="w-100" style="margin-bottom: 10px">
        <h4 CLASS="font-weight-bold mb-0"><span>PARTICULARES</span></h4>
        <span>Los clientes particulares pueden acceder indicando su <b>teléfono</b>, <b>correo electrónico </b>
          o <b>código de cliente</b>.</span>
        <br/>
      </div>

      <div class="d-flex justify-content-center">
        <button type="button" class="btn btn-success  btn-modal" (click)="modal.close()" >
          ACEPTAR
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- Código Postal -->
<ng-template #RESET_PASSWORD let-modal class="modal">
  <div class="modal-body">
    <div class="container">
      <div class="d-flex mb-2">
        <div class="font-weight-bold mr-4" style="width: 90%;">
          <h1 class="text-dark">Conseguir una nueva contraseña</h1>
        </div>
        <div class="d-flex justify-content-end" style="width: 10%">
          <a  type="button" class="text-danger" (click)="modal.close()">
            <i class="fas fa-times-circle fa-2x"></i>
          </a>
        </div>
      </div>

      <div class="w-100" style="margin-bottom: 10px">
        <h3 class="text-dark font-weight-bold mb-0">Introduce tu correo electrónico.</h3>
        <span>Si estas registrado en nuestra aplicación te
              llegará un correo electrónico para obtener una nueva contraseña</span>
      </div>

      <div>
        <div class="row select-container">
          <div class="col-sm-12">
            <form [formGroup]="emailForm" novalidate>
              <div class="form-group">
                <input type="text" class="form-control" formControlName="email">
                <div *ngIf="emailForm.controls.email.touched && emailForm.controls.email.errors?.required"
                     class="error">Campo obligatorio.
                </div>
                <div *ngIf="emailForm.controls.email.touched && emailForm.controls.email.errors?.email"
                     class="error">Formato incorrecto.
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button type="button" class="btn btn-success  btn-modal" [disabled]="!emailForm.valid"
                (click)="sendEmailPassword(modal, EMAIL_ERROR)" >
          VERIFICAR
        </button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #EMAIL_ERROR let-modal class="modal">
  <div class="modal-body">
    <div class="w-100 text-center font-weight-bold" style="margin-bottom: 10px">
      <h1 class="text-dark">No tenemos registrado su correo electrónico.</h1>
    </div>

    <div class="w-100 d-flex justify-content-center">
      <button (click)="modal.close()" class="btn btn-dark  btn-modal">
        ACEPTAR
      </button>
    </div>
  </div>
</ng-template>

<ng-template #NEW_PASSWORD let-modal class="modal">
  <div class="modal-body">
    <div class="w-100 text-center font-weight-bold" style="margin-bottom: 10px">
      <h1 class="text-dark">Su contraseña ha sido actualizada.</h1>
    </div>
    <div class="w-100 text-center font-weight-bold" style="margin-bottom: 10px">
      <h3 class="text-dark">Ya puede acceder a nuestra aplicación introduciendo su correo electrónico y su nueva contraseña.</h3>
    </div>

    <div class="w-100 d-flex justify-content-center">
      <a type="button" class="btn btn-primary text-light  btn-modal" (click)="modal.close()">
        ACEPTAR
      </a>
    </div>
  </div>
</ng-template>
