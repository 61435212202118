import {Component, Input, OnInit} from '@angular/core';
import {DefaultService} from '../../../services/default.service';
import {Router} from '@angular/router';
import {Group, SuperGroup} from '../../../interfaces/group';
import {Family} from '../../../interfaces/family';
import {FatalLinkerError} from '@angular/compiler-cli/linker';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {
  @Input() families: Array<Family>;
  @Input() initRoute: string;
  @Input() companyException: string;
  @Input() repeatOrder: boolean;
  @Input() showAllMenu: boolean;
  @Input() superGroups: Array<SuperGroup>;
  @Input() useQuickOrder: boolean;
  @Input() portalDeClientes: boolean = false;

  showOffers: boolean;

  constructor(private defaultService: DefaultService, private router: Router) { }

  ngOnInit(): void {
    this.showOffers = this.superGroups.find(el => el.groups.find(g => g.id === 'of') != null) != null;
  }

  getArticles(group: Group) {
    this.redirectTo('/compra/none/' + group.id );
  }

  getArticlesSuperGroupGroup(superGroup: SuperGroup, group: Group) {
    this.redirectTo('/compra/' + superGroup.id + '/' + group.id );
  }

  getArticlesSuperGroupAll(superGroup: SuperGroup) {
    this.redirectTo('/compra/' + superGroup.id + '/*' );
  }

  getArticlesSuperGroup(superGroup: SuperGroup) {
    if (superGroup.groups.length === 1) {
      this.redirectTo('/compra/' + superGroup.id + '/' + superGroup.groups[0].id );
    }
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
        this.router.navigate([uri]));
  }
}
