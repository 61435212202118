<div class="center mb-3 pt-4 w-100" *ngIf="!registerFromOrder">
  <img src="assets/logo.png" alt="Logo de la empresa" class="img"/>
</div>

<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-7 mx-auto">
          <div class="card p-2">
            <div class="card-body">
              <h1 class="card-title mb-4 mt-1 max" style="color:black"><b>Registrarse</b></h1>
              <div id="info-direction"><span class="text-info">Quedate cerca si tu dirección no esta disponible...</span></div>

              <form (ngSubmit)="registerClient()" [formGroup]="userForm" novalidate>

                <div class="row">
                  <div class="col-sm-12">
                    <mat-form-field class="w-100">
                      <mat-label>País</mat-label>
                      <mat-select formControlName="country" class="w-100"
                                 (change)="getProvinces()">
                        <mat-option *ngFor="let country of countries" [value]="country.id">{{country.name}}</mat-option>
                      </mat-select>
                      <div *ngIf="userForm.controls.country.touched && userForm.controls.country.errors?.required"
                           class="error">Campo obligatorio.
                      </div>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <ng-select bindLabel="name"
                                 formControlName="province"
                                 placeholder="Provincia*"
                                 (change)="getCities()">
                        <ng-option *ngFor="let province of provinces" [value]="province.id">{{province.name}}</ng-option>
                      </ng-select>
                      <div *ngIf="userForm.controls.province.touched && userForm.controls.province.errors?.required"
                           class="error">Campo obligatorio.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <ng-select bindLabel="name"
                                 formControlName="city"
                                 placeholder="Población*"
                                 (change)="setMsg()">
                        <ng-option value="default">Seleccione una ciudad</ng-option>
                        <ng-option *ngFor="let city of cities" [value]="city.id">{{city.name}},
                          {{userForm.controls.province.value}}{{city.id}}
                        </ng-option>
                      </ng-select>
                      <div *ngIf="userForm.controls.city.touched &&
                            (userForm.controls.city.errors?.required || userForm.controls.city.errors?.defaultOption)"
                           class="error">Campo obligatorio.
                      </div>
                      <div *ngIf="msgInfoDays" class="text-info">
                        <div [innerHTML]="msgInfoDays"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <mat-form-field appearance="legacy" class="w-100">
                      <mat-label>Dirección</mat-label>
                      <input type="text" class="w-100" autocomplete="direction" placeholder="Ej: Calle Alcalá, 48, 1ºA"
                             formControlName="direction" matInput>
                      <div *ngIf="userForm.controls.direction.touched && userForm.controls.direction.errors?.required"
                           class="error">Campo obligatorio.
                      </div>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <mat-form-field appearance="legacy" class="w-100">
                      <mat-label>Nombre</mat-label>
                      <input type="text" class="w-100" autocomplete="name"
                             formControlName="name" matInput>
                      <div *ngIf="userForm.controls.name.touched && userForm.controls.name.errors?.required"
                           class="error">Campo obligatorio.
                      </div>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-6">
                    <mat-form-field appearance="legacy" class="w-100">
                      <mat-label>Apellidos</mat-label>
                      <input type="text" class="w-100" autocomplete="surnames"
                             formControlName="surnames" matInput>
                      <div *ngIf="userForm.controls.surnames.touched && userForm.controls.surnames.errors?.required"
                           class="error">Campo obligatorio.
                      </div>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <mat-form-field style="width: 100%">
                      <mat-label>Fecha de nacimiento</mat-label>
                      <input placeholder="dd/mm/AAAA" matInput [matDatepicker]="myPicker"
                             formControlName="birthday">
                      <mat-datepicker-toggle  matSuffix [for]="myPicker"></mat-datepicker-toggle>
                      <mat-datepicker #myPicker></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <mat-form-field appearance="legacy" class="w-100">
                      <mat-label>Teléfono</mat-label>
                      <input type="text" class="w-100" autocomplete="phone"
                             formControlName="phoneNumber" matInput (keyup)="errorPhone = null">
                      <div *ngIf="userForm.controls.phoneNumber.touched && userForm.controls.phoneNumber.errors?.required"
                           class="error">Campo obligatorio.
                      </div>
                      <div *ngIf="userForm.controls.phoneNumber.touched && userForm.controls.phoneNumber.errors?.pattern"
                           class="error">Formato incorrecto.
                      </div>
                      <div *ngIf="errorPhone" class="error">{{errorPhone}}</div>
                    </mat-form-field>
                  </div>

                  <div class="col-sm-6">
                    <mat-form-field appearance="legacy" class="w-100">
                      <mat-label>Teléfono secundario</mat-label>
                      <input type="text" class="w-100" autocomplete="phone"
                             formControlName="phoneNumber2" matInput>
                      <div *ngIf="userForm.controls.phoneNumber2.touched && userForm.controls.phoneNumber2.errors?.pattern"
                           class="error">Formato incorrecto.
                      </div>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <mat-form-field appearance="legacy" class="w-100">
                      <mat-label>Correo electrónico</mat-label>
                      <input type="text" class="w-100" autocomplete="email"
                             formControlName="email" matInput (keyup)="errorMail = null">
                      <div *ngIf="userForm.controls.email.touched && userForm.controls.email.errors?.required"
                           class="error">Campo obligatorio.
                      </div>
                      <div *ngIf="userForm.controls.email.touched && userForm.controls.email.errors?.email"
                           class="error">Formato incorrecto.
                      </div>
                      <div *ngIf="errorMail" class="error">{{errorMail}}</div>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <mat-form-field appearance="legacy" class="w-100">
                      <mat-label>Contraseña</mat-label>
                      <input [type]="hide ? 'password' : 'text'" class="w-100"
                             formControlName="password" matInput>
                      <mat-icon matSuffix (click)="hide = !hide">
                        <span *ngIf="!hide; else elseHide"><i class="fas fa-eye-slash fa-xs"></i></span>
                        <ng-template #elseHide><i class="fas fa-eye fa-xs"></i></ng-template>
                      </mat-icon>
                      <div *ngIf="userForm.controls.password.touched && userForm.controls.password.errors?.required"
                           class="error">Campo obligatorio.
                      </div>
                      <div *ngIf="userForm.controls.password.touched &&
                                    !userForm.controls.password.errors?.required
                                    && userForm.controls.password.hasError('minlength')"
                           class="error">La contraseña debe tener mínimo cuatro caracteres.
                      </div>
                      <div *ngIf="userForm.controls.password.touched &&
                                    !userForm.controls.password.errors?.required
                                    && userForm.controls.password.hasError('maxlength')"
                           class="error">La contraseña debe tener máximo diez caracteres.
                      </div>

                      <div *ngIf="userForm.controls.password.touched &&
                                    !userForm.controls.password.errors?.required
                                    && userForm.controls.password.errors?.validLettersIncorrect"
                           class="error">La contraseña no puede contener el carácter '
                      </div>
                    </mat-form-field>
                  </div>

                  <div class="col-sm-6">
                    <mat-form-field appearance="legacy" class="w-100">
                      <mat-label>Repite la contraseña</mat-label>
                      <input class="w-100" [type]="hide2 ? 'password' : 'text'"
                             formControlName="password_confirmation" matInput>
                      <mat-icon matSuffix (click)="hide2 = !hide2">
                        <span *ngIf="!hide2; else elseHide"><i class="fas fa-eye-slash fa-xs"></i></span>
                        <ng-template #elseHide><i class="fas fa-eye fa-xs"></i></ng-template>
                      </mat-icon>
                      <div *ngIf="userForm.controls.password_confirmation.touched &&
                                        userForm.controls.password_confirmation.errors?.required"
                           class="error">Campo obligatorio.
                      </div>
                      <div *ngIf="userForm.controls.password_confirmation.touched &&
                                        userForm.controls.password_confirmation.errors?.samePasswordIncorrect &&
                                        !userForm.controls.password_confirmation.errors?.required"
                           class="error">Las contraseñas no coinciden.
                      </div>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row" style="margin-bottom: 1.25em">
                  <div class="col-sm-12">
                    <mat-form-field appearance="legacy" class="w-100">
                      <mat-label>Observaciones</mat-label>
                      <textarea type="text" class="w-100" placeholder="Me gustaría que mi pedido..."
                             formControlName="observations" matInput></textarea>
                    </mat-form-field>
                  </div>
                </div>

                <!-- Submit button -->
                <button type="submit" class="btn btn-primary btn-block"
                        [disabled]="!userForm.valid">Registrarse</button>
              </form>
            </div>
          </div>


          <div style="margin: 2.5em 10px 10px 10px;" *ngIf="!registerFromOrder">
            <h5><span>¿Ya estas registrado?</span></h5>
            <a class="btn btn-outline-info btn-block" routerLink="/iniciarSesion">
              <i class="far fa-user fa-1x"></i>
              Iniciar sesión
            </a>
          </div>

          <div *ngIf="companyConf && !registerFromOrder" >
            <div style="margin: 1.25em 10px 10px 10px;">
              <h5><span>¿Deseas continuar sin registrate?</span></h5>
              <a class="btn btn-outline-info btn-block mb-4" (click)="localStorageService.navigateToLogin()">
                Continuar sin registrarme
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>

<!-- Footer -->
<div *ngIf="companyConf && !registerFromOrder">
  <app-footer-auth  [companyException]="companyConf.companyException"
                    [phone]="companyConf.phone" [mail]="companyConf.email" [alternativePhone]="companyConf.alternativePhone"></app-footer-auth>
</div>
