<section id="acerca-de" class="mb-5" *ngIf="acercaDeJSON">
  <div class="container-fluid">
    <div class="w-100 text-center">
      <h1 style="font-size: 3.75em !important; line-height: 70px;" class="font-weight-bold mt-5 mb-5">Acerca de</h1>
    </div>
    <div class="row">
      <div class="col-md-6 border"
           [ngStyle]="{'background-image': 'url(assets/web-estatica/inicio/acerca-de.webp)'}"
           style="background-size: cover;"></div>
      <div class="col-md-6 border">
        <div class="computer" style="margin: 10% 12% 10% 12%; ">
          <h2 style="font-size: 2.5em !important;" class="font-weight-bold">
            Nosotros
          </h2>
          <p class="text-justify" *ngFor="let t of acercaDeJSON.texto">{{t}}</p>
          </div>

        <div class="mobile">
          <h2 style="font-size: 2.5em !important;" class="font-weight-bold mt-4">
            Nosotros
          </h2>
          <p class="text-justify" *ngFor="let t of acercaDeJSON.texto">{{t}}</p>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-4 border p-5" *ngFor="let servicio of acercaDeJSON.servicios">
        <div class="w-100 text-center mb-3">
          <i class="fa-solid {{servicio.icono}} fa-3x text-primary"></i>
        </div>
        <h2 class="font-weight-bold">{{servicio.titulo}}</h2>
        <p class="text-justify" *ngFor="let t of servicio.texto">{{t}}</p>
      </div>
    </div>
  </div>
</section>

<section id="contacto">
  <div [ngStyle]="{'background-image': 'url(assets/web-estatica/inicio/contacto.webp)'}"
       style="min-height: 50vh !important; background-size: cover;"
       class="w-100 d-flex justify-content-center mt-5 align-items-center p-2">
    <div>
      <h3 style="font-size: 1.875em !important;" class="text-white">Estamos encantados de poder atenderle</h3>
      <h1 style="font-size: 3.75em !important; line-height: 70px;" class="font-weight-bold text-white mb-0">Contacta con</h1>
      <h1 style="font-size: 3.75em !important; line-height: 70px;" class="font-weight-bold text-white">nosotros</h1>
      <button class="btn btn-primary text-light font-weight-bold mt-2 p-2 rounded-button" routerLink="/atencion-al-cliente"
              style="width: 200px !important;">
        Contactar
      </button>
    </div>

  </div>
</section>
